import React from 'react';
import { WidgetContainer, DataTable } from '@frontend/app/refresh-components';
import { ColumnDef } from '@tanstack/react-table';
import { Avatar, AvatarFallback, AvatarImage } from '@frontend/shadcn/components/ui/avatar';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';

import { ToggleGroup, ToggleGroupItem } from '@frontend/shadcn/components/ui/toggle-group';
import { Tooltip, TooltipContent, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import { Link } from 'react-router-dom';
import { ExportToCsv } from 'export-to-csv';
import {
  BuildingColumnsIcon,
  ArrowUpRightFromSquareIcon,
  ArrowRightIcon,
} from '@revfluence/fresh-icons/solid/esm';
import {
  TriangleExclamationIcon,
  FileArrowDownIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { useGetBudgetDashboardBudgetTabBudgetSplitQuery } from '../hooks';
import { cn } from '@/shadcn/lib/utils';

const { useState, useMemo } = React;
type RowItem = {
  id: number;
  project?: string;
  projectImage?: string;
  budgetName?: string;
  budgetImage?: string;
  splits: string[];
  sources: string[];
  budget: number;
  allocated: number;
  remaining: number;
  spend?: number;
  paidOut?: number;
  duePayments?: number;
};

interface BudgetAllocatedWidgetProps {
  budgetId?: number;
  fiscalYear?: string;
  className?: string;
}
type Source = 'Budget' | 'Project';
export default function BudgetAllocatedWidget(props: BudgetAllocatedWidgetProps) {
  const { budgetId, fiscalYear, className } = props;
  const isParemetersAbsent = !budgetId || !fiscalYear;

  const [source, setSource] = useState<Source>('Budget');

  const { data: budgetSplitData, loading } = useGetBudgetDashboardBudgetTabBudgetSplitQuery({
    variables: {
      budgetId,
      fiscalYear,
    },
    skip: isParemetersAbsent,
  });
  const data: RowItem[] = useMemo(() => {
    if (!loading && (budgetSplitData?.budgetSplit?.budgetList || budgetSplitData?.budgetSplit?.projectBudgetList)) {
      const dataArray = [];
      if (source === 'Budget' && budgetSplitData.budgetSplit?.budgetList) {
        budgetSplitData.budgetSplit?.budgetList.forEach((budget) => {
          dataArray.push({
            id: budget.budgetAccountId,
            project: '',
            projectImage: '',
            budgetName: budget?.budgetName || '',
            budgetImage: '',
            splits: budget?.childBudgetsList?.map((childBudget) => childBudget.budgetName) || [],
            sources: [],
            budget: budget.totalAmount,
            allocated: budget.allocatedAmount,
            paidOut: 0,
            duePayments: 0,
            remaining: budget.totalAmount - (budget.spentAmount + budget.allocatedAmount),
            spend: budget.spentAmount,
          });
        });
      } else if (source === 'Project' && budgetSplitData.budgetSplit?.projectBudgetList) {
        budgetSplitData.budgetSplit.projectBudgetList.forEach((budget) => {
          dataArray.push({
            id: budget.programId,
            project: budget?.programName || '',
            projectImage: budget?.programSplashImageUrl || '',
            budgetName: '',
            budgetImage: '',
            splits: [],
            sources: budget?.sources || [],
            budget: budget.totalAmount,
            allocated: budget.allocatedAmount,
            paidOut: 0,
            duePayments: 0,
            remaining: budget.totalAmount - (budget.spentAmount + budget.allocatedAmount),
            spend: budget.spentAmount,
          });
        });
      }
      return dataArray;
    } else {
      return [];
    }
  }, [loading, budgetSplitData, source]);

  const exportBudgetData = () => {
    const options = {
      headers: ['Name', source === 'Budget' ? 'Split To' : 'Sources', 'Total Budget', 'Allocated', 'Spent'],
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      title: 'Budget Data',
    };

    const csvExporter = new ExportToCsv(options);
    const dataToExport = data.map((budgetData) => ({
      name: source === 'Budget' ? budgetData.budgetName : budgetData.project,
      sources: source === 'Budget' ? budgetData.splits.join(', ') : budgetData.sources.join(', '),
      totalAmount: budgetData.budget,
      allocatedAmount: budgetData.allocated,
      spent: budgetData.spend,
    }));

    csvExporter.generateCsv(dataToExport);
  };

  const projectColumns: ColumnDef<RowItem>[] = [
    {
      accessorKey: 'project',
      header: () => <div className="font-semibold text-[#1F1F21]">Project</div>,
      cell: ({ row }) => {
        const projectName = row.getValue('project') as string;
        const projectImage = row.original.projectImage as string;
        return (
          <div className="flex gap-1 items-center">
            <Avatar className="h-[24px] w-[24px] rounded-none">
              {projectImage ? (
                <AvatarImage src={projectImage} className="flex h-full w-full items-center justify-center rounded-lg" />
              ) : (
                <AvatarFallback className="flex h-full w-full items-center justify-center rounded-lg bg-primary text-sm text-secondary">
                  <BuildingColumnsIcon />
                </AvatarFallback>
              )}
            </Avatar>
            <span>{projectName}</span>
          </div>
        );
      },
      size: 250,
    },
    {
      accessorKey: 'sources',
      header: () => <div className="font-semibold text-[#1F1F21]">Sources</div>,
      cell: ({ row }) => {
        const sources = row.getValue('sources') as string[];
        const extraSources = sources.length > 2 ? sources.length - 2 : 0;

        return (
          <div className="flex gap-1">
            {sources.slice(0, 2).map((source, index) => (
              <Badge className="rounded-md font-normal" key={index} variant="secondary">
                {source}
              </Badge>
            ))}
            {extraSources > 0 && (
              <Badge className="rounded-md font-normal" variant="secondary">
                {`+${extraSources}`}
              </Badge>
            )}
          </div>
        );
      },
      size: 300,
    },
    {
      accessorKey: 'budget',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Budget</div>,
      cell: ({ row }) => {
        const budget = parseFloat(row.getValue('budget'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(budget);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
    {
      accessorKey: 'spend',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Spend</div>,
      cell: ({ row }) => {
        const spend = parseFloat(row.getValue('spend'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(spend);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
    // Will enable later with auto assign brief
    // {
    //   accessorKey: 'paidOut',
    //   header: () => <div className="text-right font-semibold text-[#1F1F21]">Paid Out</div>,
    //   cell: ({ row }) => {
    //     const paidOut = parseFloat(row.getValue('paidOut'));
    //     const formatted = new Intl.NumberFormat('en-US', {
    //       style: 'currency',
    //       currency: 'USD',
    //     }).format(paidOut);

    //     return <div className="text-right font-medium">{formatted}</div>;
    //   },
    // },
    // {
    //   accessorKey: 'duePayments',
    //   header: () => <div className="text-right font-semibold text-[#1F1F21]">Due Payments</div>,
    //   cell: ({ row }) => {
    //     const duePayments = parseFloat(row.getValue('duePayments'));
    //     const formatted = new Intl.NumberFormat('en-US', {
    //       style: 'currency',
    //       currency: 'USD',
    //     }).format(duePayments);

    //     return <div className="text-right font-medium">{formatted}</div>;
    //   },
    // },
    {
      accessorKey: 'remaining',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Remaining</div>,
      cell: ({ row }) => {
        const remaining = parseFloat(row.getValue('remaining'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(remaining);

        return (
          <div className={`flex gap-1 justify-end items-center ${remaining < 0 ? 'text-[#D48806]' : 'text-right'}`}>
            {formatted}
            {remaining < 0 && <TriangleExclamationIcon fontSize={16} />}
          </div>
        );
      },
    },
    {
      id: 'actions',
      header: () => null,
      cell: ({ row }) => {
        const budget = row.original;
        return (
          <div className="text-center">
            <Link
              to={
                source === 'Budget'
                  ? `/settings/budget/editBudget/${budget.id}`
                  : `/projects/${budget.id}/settings/budget`
              }
            >
              <ArrowUpRightFromSquareIcon className="h-4 w-4" />
            </Link>
          </div>
        );
      },
    },
  ];

  const budgetColumns: ColumnDef<RowItem>[] = [
    {
      accessorKey: 'budgetName',
      header: () => <div className="font-semibold text-[#1F1F21]">Budget</div>,
      cell: ({ row }) => {
        const budgetName = row.getValue('budgetName') as string;
        const budgetImage = row.original.budgetImage as string;
        return (
          <div className="flex gap-1 items-center">
            <Avatar className="h-[24px] w-[24px] rounded-none">
              {budgetImage ? (
                <AvatarImage src={budgetImage} className="flex h-full w-full items-center justify-center rounded-lg" />
              ) : (
                <AvatarFallback className="flex h-full w-full items-center justify-center rounded-lg bg-primary text-sm text-secondary">
                  <BuildingColumnsIcon />
                </AvatarFallback>
              )}
            </Avatar>
            <span>{budgetName}</span>
          </div>
        );
      },
      size: 250,
    },
    {
      accessorKey: 'splits',
      header: () => <div className="font-semibold text-[#1F1F21]">Splits to</div>,
      cell: ({ row }) => {
        const splits = row.getValue('splits') as string[];
        const extraSplits = splits.length > 2 ? splits.length - 2 : 0;

        return (
          <div className="flex gap-1">
            {splits.length === 0 ? (
              <Badge className="rounded-md font-normal" variant="secondary">
                -
              </Badge>
            ) : (
              <>
                {splits.slice(0, 2).map((split, index) => (
                  <Badge className="rounded-md font-normal" key={index} variant="secondary">
                    {split}
                  </Badge>
                ))}
                {extraSplits > 0 && (
                  <Badge className="rounded-md font-normal" variant="secondary">
                    {`+${extraSplits}`}
                  </Badge>
                )}
              </>
            )}
          </div>
        );
      },
      size: 300,
    },
    {
      accessorKey: 'budget',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Budget</div>,
      cell: ({ row }) => {
        const budget = parseFloat(row.getValue('budget'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(budget);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
    {
      accessorKey: 'allocated',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Allocated</div>,
      cell: ({ row }) => {
        const allocated = parseFloat(row.getValue('allocated'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(allocated);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
    {
      accessorKey: 'spend',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Spend</div>,
      cell: ({ row }) => {
        const spend = parseFloat(row.getValue('spend'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(spend);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
    {
      accessorKey: 'remaining',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Remaining</div>,
      cell: ({ row }) => {
        const remaining = parseFloat(row.getValue('remaining'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(remaining);

        return (
          <div className={`flex gap-1 justify-end items-center ${remaining < 0 ? 'text-[#D48806]' : 'text-right'}`}>
            {formatted}
            {remaining < 0 && <TriangleExclamationIcon fontSize={16} />}
          </div>
        );
      },
    },
    {
      id: 'actions',
      header: () => null,
      cell: ({ row }) => {
        const budget = row.original;
        return (
          <div className="text-center">
            <Link
              to={
                source === 'Budget'
                  ? `/settings/budget/editBudget/${budget.id}`
                  : `/projects/${budget.id}/settings/budget`
              }
            >
              <ArrowUpRightFromSquareIcon className="h-4 w-4" />
            </Link>
          </div>
        );
      },
    },
  ];

  const columns = source === 'Project' ? projectColumns : budgetColumns;

  const widgetActions = (
    <div className="flex items-center gap-2">
      <Button size="icon" variant="outline" onClick={exportBudgetData}>
        <FileArrowDownIcon className="h-4 w-4" />
      </Button>
      <ToggleGroup
        className="border border-gray-300 rounded-lg overflow-hidden inline-flex"
        type="single"
        value={source}
        onValueChange={(value) => value && setSource(value as Source)}
      >
        <ToggleGroupItem value="Project">Projects</ToggleGroupItem>
        <ToggleGroupItem value="Budget">Budgets</ToggleGroupItem>
      </ToggleGroup>
    </div>
  );

  const missingParametersContent = (
    <div className="w-full h-full flex justify-center items-center">
      <p>No data found</p>
    </div>
  );
  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const mainContent = (
    <>
      <DataTable columns={columns} data={data} />
      <div className="flex justify-end mt-1">
        <Tooltip>
          <TooltipTrigger asChild>
            <Link to="/budget/dashboard?tab=DetailedView">
              <span className="flex gap-1 items-center">
                Detailed View
                <ArrowRightIcon fontSize={16} />
              </span>
            </Link>
          </TooltipTrigger>
          <TooltipContent>Detailed View</TooltipContent>
        </Tooltip>
      </div>
    </>
  );

  return (
    <WidgetContainer
      widgetTitle="Allocated To"
      className={cn(className, '')}
      bgColor="bg-white"
      textColor="text-gray-700"
      widgetActions={widgetActions}
    >
      {isParemetersAbsent ? missingParametersContent : loading ? loadingContent : mainContent}
    </WidgetContainer>
  );
}
