import gql from 'graphql-tag';

export const START_KLAVIYO_SYNC_MUTATION = gql`
  mutation StartKlaviyoSyncMutation {
    syncJobStatus: startKlaviyoSync {
      id
      name
      completeItems
      totalItems
      failedItems
      status
      createdDate
    }
  }
`;
