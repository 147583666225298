import gql from 'graphql-tag';

const GET_PAYMENTS_HISTORY_WITH_BUDGET_LOG = gql`
    query GetPaymenstHistoryWithBudgetLog ($memberId: Int,$pagination: PaginationInput) {
        paymentsLog: getPaymentHistoryWithBudgetAccounts (memberId: $memberId,pagination: $pagination) {
            paymentHistory{
                amount
                budgetAccountNames
                memberName
                transferedDate
                paidDate
                paymentId
                projectName
                paypal
                status
            }
            totalCount
        }
    }
`;

export default GET_PAYMENTS_HISTORY_WITH_BUDGET_LOG;
