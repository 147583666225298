import * as React from 'react';

import { AppDashboardNav } from '@frontend/applications/Shared/components/AppDashboardNav';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import RefreshSocialAnalyticsButton from '@frontend/components/common/RefreshSocialAnalyticsButton';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { useState } from 'react';
import { AppHeader, HeaderTabItem } from '@frontend/app/refresh-components/AppHeader';
import SocialAppReports from './SocialAppReports';
import SocialAppDashboardPosts from './SocialAppDashboardPosts';
import SocialPostAppArchivedReports from './SocialPostAppArchivedReports';
import { useFetchClientData } from '../useFetchClientData';

const { useMemo } = React;

interface IProp {
  refreshUi: boolean;
}

const SocialPostAppDashboard: React.FunctionComponent<IProp> = ({ refreshUi }) => {
  const { clientId, backendServerApiEndpoint } = useApplication();
  const onDemandSocialAnalyticsRefreshEnabled = useClientFeatureEnabled(ClientFeature.ON_DEMAND_SOCIAL_ANALYTICS_REFRESH);
  const [activeKey, setActiveKey] = useState('Reports');

  const {
    data: createClientData,
  } = useFetchClientData({
    url: `${backendServerApiEndpoint}/client`,
    clientId,
  });

  const navSettings = useMemo(() => {
    const baseNavSettings = [
      {
        route: 'reports',
        displayName: 'Reports',
        label: 'Reports',
        name: 'Reports',
        component: SocialAppReports,
      },
      {
        route: 'posts',
        displayName: 'Assign Posts',
        label: 'Assign Posts',
        name: 'Assign Posts',
        component: SocialAppDashboardPosts,
      },
    ];

    if (createClientData && createClientData.migrating_from_create) {
      baseNavSettings.push({
        route: 'archive',
        displayName: 'Archived Reports',
        label: 'Archived Reports',
        name: 'Archived Reports',
        component: SocialPostAppArchivedReports,
      });
    }

    return baseNavSettings;
  }, [createClientData]);

  const navHeaderSettings: HeaderTabItem[] = useMemo(() => {
    const baseNavSettings = [
      {
        name: 'Reports',
        label: 'Reports',
        content: SocialAppReports,
      },
      {
        name: 'Assign Posts',
        label: 'Assign Posts',
        content: SocialAppDashboardPosts,
      },
    ];

    if (createClientData && createClientData.migrating_from_create) {
      baseNavSettings.push({
        name: 'Archived Reports',
        label: 'Archived Reports',
        content: SocialPostAppArchivedReports,
      });
    }

    return baseNavSettings;
  }, [createClientData]);

  const application = {
    displayName: 'Social Posts',
    iconUrl: 'https://storage.googleapis.com/aspirex-static-files/social-listening.svg',
  };

  const sidebarComponents = [];
  if (onDemandSocialAnalyticsRefreshEnabled) {
    sidebarComponents.push(<RefreshSocialAnalyticsButton refreshUi={refreshUi} />);
  }
  const handleClick = (tabName) => {
    setActiveKey(tabName);
  };
  const ActiveComponent = navSettings.find((tab) => tab.name === activeKey)?.component;
  return (
    refreshUi ? (
      <>
        <AppHeader
          title="Social Analytics"
          tabs={navHeaderSettings}
          actions={sidebarComponents}
          defaultTab="Reports"
          handleTabClick={handleClick}
        />
        <div className="px-6" style={{ padding: 24 }}>
          {ActiveComponent && <ActiveComponent />}
        </div>
      </>
    ) : (
      <AppDashboardNav
        application={application}
        navLinks={navSettings}
        defaultRoute="reports"
        fixToScreenHeight
        sidebarComponents={sidebarComponents}
      />
    )
  );
};

export default SocialPostAppDashboard;
