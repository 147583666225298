import React from 'react';
import { WidgetContainer, MainMetric } from '@frontend/app/refresh-components';
import { Bar, BarChart, XAxis } from 'recharts';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartLegendContent,
} from '@/shadcn/components/ui/chart';
import { useGetBudgetDashboardSpendSummaryQuery } from '../hooks';

const chartConfig = {
  salesTracking: {
    label: 'Sales Tracking',
    color: 'hsl(var(--chart-1))',
  },
  terms: {
    label: 'Terms',
    color: 'hsl(var(--chart-2))',
  },
  others: {
    label: 'Others',
    color: 'hsl(var(--chart-3))',
  },
} as ChartConfig;

interface SourceLevelSpendWidgetProps {
  budgetId?: number;
  fiscalYear?: string;
  className?: string;
}

export default function SourceLevelSpendWidget(props: SourceLevelSpendWidgetProps) {
  const { budgetId, fiscalYear, className } = props;
  const isParemetersAbsent = !budgetId || !fiscalYear;
  const { data: budgetSummary, loading } = useGetBudgetDashboardSpendSummaryQuery({
    variables: {
      budgetId,
      fiscalYear,
    },
    skip: isParemetersAbsent,
  });

  const chartData = [
    { source: 'Sales Tracking', percent: budgetSummary?.summary?.totalSpendData?.spentOnCommission || 0, fill: 'var(--color-salesTracking)' },
    { source: 'Terms', percent: budgetSummary?.summary?.totalSpendData?.spentInBriefs || 0, fill: 'var(--color-terms)' },
    { source: 'Others', percent: budgetSummary?.summary?.totalSpendData?.spentOnOthers || 0, fill: 'var(--color-others)' },
  ];
  const missingParametersContent = (
    <div className="w-full h-full flex justify-center items-center">
      <p>No data found</p>
    </div>
  );
  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const mainContent = (
    <div className="flex flex-col">
      <MainMetric
        className="flex-1"
        value={budgetSummary?.summary?.totalSpendData?.spentAmount || 0}
        size="medium"
        metricType="currency"
        subHeading="of Total Budget"
        icon={(
          <Badge variant="outline" className="text-green-700">
            {`${budgetSummary?.summary?.totalSpendData?.spentPercent || 0}%`}
          </Badge>
        )}
      />
      <ChartContainer config={chartConfig} className="mx-auto h-[300px] w-[fullx]">
        <BarChart data={chartData}>
          <XAxis dataKey="source" tickLine={false} tickMargin={10} axisLine={false} />
          <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
          <Bar dataKey="percent" strokeWidth={3} radius={8} />
          <ChartLegend content={<ChartLegendContent nameKey="source" />} className="flex-wrap gap-2" />
        </BarChart>
      </ChartContainer>
    </div>
  );

  return (
    <WidgetContainer
      widgetTitle="Total Spend"
      className={`${className}`}
      bgColor="bg-white"
      textColor="text-gray-700"
    >
      {isParemetersAbsent ? missingParametersContent : loading ? loadingContent : mainContent}
    </WidgetContainer>
  );
}
