import { useEffect, useMemo, useState } from 'react';
import {
  filter, find, first, includes, isEmpty, isUndefined, map, reduce, trim,
} from 'lodash';

import { memberIsPending } from '@affiliates/components';
import {
  IAffiliateLinkMember,
  MemberStatus,
  OfferMemberStatus,
  TMemberTableCSVRow,
  TOfferMember,
  TSelectedAffliate,
} from '@affiliates/components/MemberTable/types';
import { TOfferStats } from '@affiliates/components/OfferDetails/types';
import { useSTAPaymentAppContext } from '@affiliates/contexts';
import {
  IHeader,
  useAdvertiserShopifyScopeCheck,
  useExportToCsv,
  useGetOfferByIdQuery,
} from '@affiliates/hooks';
import {
  GetOfferById,
  GetOfferById_offer_links_affiliateStats,
  GetOfferById_offer_promos_affiliates,
} from '@affiliates/queries/types/GetOfferById';
import { TMember, TMode } from '@affiliates/components/MembersWizard/types';
import { roundToNearestCent } from '@affiliates/utils';
import { EventName, logger } from '@common';
import { IDateRangeSettings } from '@frontend/app/components';
import { useEventContext } from '@frontend/app/context';
import { useMapDateRangeToVariables } from '@frontend/app/hooks';
import { GetMultipleShopifyStats, GetMultipleShopifyStats_storeStats } from '@affiliates/queries/types/GetMultipleShopifyStats';
import { useApolloClient } from '@apollo/client';
import { useOfferDetailsContext } from '@frontend/context/OfferDetailsContext';
import { OFFER_PROMO_CODE_STATUS, OFFER_SOURCE, AFFILIATE_STATUS } from '../types/globalTypes';
import { AFFILIATE_OFFER_LINK_STATUS } from '../constants/offers';
import { GET_MULTIPLE_SHOPIFY_STATS_QUERY, GET_OFFER_AFFILIATES_STATS_MULTIPLE_SHOPIFY } from '../queries';
import { GetOfferAffliatesStatsMultipleShopify, GetOfferAffliatesStatsMultipleShopify_affiliatesStats } from '../queries/types/GetOfferAffliatesStatsMultipleShopify';

export enum OfferDetailsQueriesStatus {
  Failed,
  Loading,
  Ready,
}

interface IOfferDetailsQueriesLoading {
  status: OfferDetailsQueriesStatus.Loading;
}

interface IOfferDetailsQueriesFailed {
  error: Error;
  status: OfferDetailsQueriesStatus.Failed;
}

interface IOfferDetailsQueriesReady {
  failedPromoCodeErrors: OFFER_PROMO_CODE_STATUS[];
  failedMembers: {
    members: readonly TMember[];
    dismissFailedMembers: () => void;
    showFailedMembers: () => void;
    visible: boolean;
  };
  missingShopifyCredentials: boolean | null;
  offerDetails: GetOfferById['offer'];
  offerMembers: readonly TOfferMember[];
  offerStats: TOfferStats;
  multipleShopifyOfferStats: GetMultipleShopifyStats_storeStats[],
  memberTable: {
    buttonActions: {
      addMembers: () => void;
      export: (columns: IHeader[], data: readonly TMemberTableCSVRow[]) => void;
      pause: () => void;
      resume: (refreshDates: boolean) => void;
    };
    disableCompose: boolean;
    onSelectMembers: (memberIds: number[]) => void;
    selectedAffiliateIds: number[];
  };
  refresh: () => void;
  status: OfferDetailsQueriesStatus.Ready;
  updateMembers: {
    mode: TMode;
    onClose: () => void;
    refreshDatesOnly: boolean;
    selectedMembers: readonly TMember[];
    visible: boolean;
  };
  memberConversion: {
    visible: boolean;
    selectedMemberConversion: TSelectedAffliate,
    onClose: () => void;
  },
  updateManageOffer?: {
    mode: TMode;
    onClose: () => void;
    visible: boolean;
    selectedMembers: readonly TMember[];
    setIsVisibleSelectOffer: (isVisibleManageOffer: boolean) => void;
  };
  multipleShopifyAffiliateStats: {
    visible: boolean;
    affiliatesStats: GetOfferAffliatesStatsMultipleShopify_affiliatesStats[],
    toggle: () => void;
  }
  shopifyStoreName: string;
}

type TOfferDetailsQueries = Readonly<
  | IOfferDetailsQueriesFailed
  | IOfferDetailsQueriesLoading
  | IOfferDetailsQueriesReady
>;
const NULL_DATA = {
  offerStats: {
    avgSale: 0,
    clicks: 0,
    conversionRatio: 0,
    conversions: 0,
    payout: 0,
    payoutEarned: 0,
    payoutMade: 0,
    sales: 0,
  },
  offerMembers: [],
};

const getStatusForAffiliateLinkMember = (
  member: GetOfferById_offer_links_affiliateStats,
): OfferMemberStatus => {
  const status = trim(member.linkStatus).toUpperCase();
  switch (status) {
    case AFFILIATE_OFFER_LINK_STATUS.ACTIVE:
      return OfferMemberStatus.ACTIVE;
    case AFFILIATE_OFFER_LINK_STATUS.DISABLED:
      return OfferMemberStatus.PAUSED;
    case AFFILIATE_OFFER_LINK_STATUS.PENDING:
      return OfferMemberStatus.PENDING;
    case AFFILIATE_OFFER_LINK_STATUS.PENDING_UNPAUSE:
      return OfferMemberStatus.PENDING_UPDATE;
    case AFFILIATE_OFFER_LINK_STATUS.PENDING_PAUSE:
      return OfferMemberStatus.PENDING_DELETION;
    case AFFILIATE_OFFER_LINK_STATUS.FAILED_UPDATE:
      return OfferMemberStatus.FAILED_UPDATE;
  }
  return OfferMemberStatus.UNKNOWN;
};

const getStatusForPromoCodeMember = (
  member: GetOfferById_offer_promos_affiliates,
): OfferMemberStatus => {
  switch (member.status) {
    case OFFER_PROMO_CODE_STATUS.PENDING:
      return OfferMemberStatus.PENDING;
    case OFFER_PROMO_CODE_STATUS.PENDING_DELETION:
      return OfferMemberStatus.PENDING_DELETION;
    case OFFER_PROMO_CODE_STATUS.PENDING_UPDATE:
      return OfferMemberStatus.PENDING_UPDATE;
    case OFFER_PROMO_CODE_STATUS.DELETED:
      return OfferMemberStatus.DEACTIVATED;
    case OFFER_PROMO_CODE_STATUS.FAILED_DUPLICATE:
    case OFFER_PROMO_CODE_STATUS.FAILED_OTHER:
    case OFFER_PROMO_CODE_STATUS.FAILED_TOO_LONG:
    case OFFER_PROMO_CODE_STATUS.FAILED_DELETION:
      return OfferMemberStatus.FAILED;
    case OFFER_PROMO_CODE_STATUS.EXPIRED:
      return OfferMemberStatus.EXPIRED;
    case OFFER_PROMO_CODE_STATUS.SUCCESS:
      return OfferMemberStatus.ACTIVE;
  }
  // should not reach here but just in case we receive something else from the
  // server, we should return *something*
  return OfferMemberStatus.UNKNOWN;
};

const getMemberStatus = (
  memberStatus: AFFILIATE_STATUS,
): MemberStatus => {
  switch (memberStatus) {
    case AFFILIATE_STATUS.ACTIVE:
      return MemberStatus.ACTIVE;
    case AFFILIATE_STATUS.DELETED:
      return MemberStatus.DELETED;
  }
  // should not reach here but just in case we receive something else from the
  // server, we should return *something*
  return MemberStatus.ACTIVE;
};

export const useOfferDetailsQueries = (
  offerId: number,
  dateRangeSettings: IDateRangeSettings,
  isEnabledMultipleShopify: boolean,
): TOfferDetailsQueries => {
  const [multipleOfferStats, setMultipleOfferStats] = useState<GetMultipleShopifyStats_storeStats[]>(null);
  const [affiliatesStats, setAffiliatesStats] = useState<GetOfferAffliatesStatsMultipleShopify_affiliatesStats[]>(null);
  const {
    dateFilter,
    setOffers,
    setPaymentsDue,
    setRefreshCurrentDashboard,
    setRefreshCurrentTable,
  } = useSTAPaymentAppContext();
  const paymentsDateRangeVariables = useMapDateRangeToVariables(dateFilter.dateRange);
  const dateRangeVariables = useMapDateRangeToVariables(dateRangeSettings.dateRange);
  const offerQuery = useGetOfferByIdQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: offerId,
      ...dateRangeVariables,
    },
    skip: !offerId,
  });
  const client = useApolloClient();
  const {
    setDisableOfferEdit,
  } = useOfferDetailsContext();
  useEffect(() => {
    if (isEnabledMultipleShopify && offerQuery.data?.offer?.promos?.[0]?.connectedClientMetadata?.length) {
      client.query<GetMultipleShopifyStats>({
        query: GET_MULTIPLE_SHOPIFY_STATS_QUERY,
        variables: {
          offerId,
          ...dateRangeVariables,
        },
        fetchPolicy: 'network-only',
      }).then((multipleShopifyStatsData) => {
        setMultipleOfferStats(multipleShopifyStatsData?.data?.storeStats);
      }).catch((e) => {
        console.log(e);
      });

      client.query<GetOfferAffliatesStatsMultipleShopify>({
        query: GET_OFFER_AFFILIATES_STATS_MULTIPLE_SHOPIFY,
        variables: {
          offerId,
          ...dateRangeVariables,
        },
        fetchPolicy: 'network-only',
      }).then((multipleShopifyStatsData) => {
        setAffiliatesStats(multipleShopifyStatsData?.data?.affiliatesStats);
      }).catch((e) => {
        logger.error(e);
      });
    }
  }, [client, dateRangeVariables, offerId, isEnabledMultipleShopify, offerQuery]);
  const paymentStatsQuery = useGetOfferByIdQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: offerId,
      ...paymentsDateRangeVariables,
    },
  });
  const advertiserShopifyScopeCheck = useAdvertiserShopifyScopeCheck({
    skip: (
      offerQuery.loading
      || !!offerQuery.error
      || isEmpty(offerQuery.data?.offer?.promos)
    ),
  });
  const missingShopifyCredentials = useMemo(() => {
    if (!advertiserShopifyScopeCheck.loading && !advertiserShopifyScopeCheck.error) {
      return !(advertiserShopifyScopeCheck.data?.advertiser?.hasShopifyScopes);
    }
    return null;
  }, [advertiserShopifyScopeCheck]);

  useEffect(() => {
    const newRefreshCurrentDashboard = () => {
      offerQuery.refetch({
        id: offerId,
        ...dateRangeVariables,
      });
      paymentStatsQuery.refetch({
        id: offerId,
        ...paymentsDateRangeVariables,
      });
    };
    setRefreshCurrentDashboard(() => newRefreshCurrentDashboard);
    setRefreshCurrentTable(() => newRefreshCurrentDashboard);
  }, [
    dateRangeVariables,
    offerId,
    offerQuery,
    paymentsDateRangeVariables,
    paymentStatsQuery,
    setRefreshCurrentDashboard,
    setRefreshCurrentTable,
  ]);
  useEffect(() => {
    if (paymentStatsQuery.loading || isEmpty(paymentStatsQuery.data)) {
      setPaymentsDue({ state: 'loading' });
      return;
    }
    if (paymentStatsQuery.error) {
      setPaymentsDue({
        errorMessage: paymentStatsQuery.error.message,
        state: 'failed',
      });
      return;
    }
    const { offer: { links, promos } } = paymentStatsQuery.data;
    if (!isEmpty(links)) {
      const { stats } = first(links);
      setPaymentsDue({
        paymentsDue: Math.max(0, roundToNearestCent(
          stats.payoutEarned - stats.payoutMade,
        )),
        state: 'ready',
      });
    } else if (!isEmpty(promos)) {
      const { stats } = first(promos);
      setPaymentsDue({
        paymentsDue: Math.max(0, roundToNearestCent(
          stats.payoutEarned - stats.payoutMade,
        )),
        state: 'ready',
      });
    }
  }, [paymentStatsQuery, offerId, setPaymentsDue]);
  useEffect(() => {
    setDisableOfferEdit(true);
    const offer = offerQuery.data?.offer;
    if (!isEmpty(offer)) {
      const contextOffer = {
        id: offer.id,
        name: offer.name,
        source: isEmpty(offer.links) ? OFFER_SOURCE.SHOPIFY : OFFER_SOURCE.TUNE,
      };
      setOffers([contextOffer]);
    }
  }, [offerQuery.data, setOffers, setDisableOfferEdit]);

  let source = OFFER_SOURCE.TUNE;
  if (!offerQuery.loading && !offerQuery.error && offerQuery.data.offer) {
    if (isEmpty(offerQuery.data.offer.links) && !isEmpty(offerQuery.data.offer.promos)) {
      source = OFFER_SOURCE.SHOPIFY;
    }
  }

  const [selectedMemberIds, updatedSelectedMemberIds] = useState<number[]>([]);
  const exportToCsv = useExportToCsv();
  // update members wizard settings
  const [updateWizardMode, setUpdateWizardMode] = useState<TMode>('add');
  const [updateWizardVisible, setUpdateWizardVisible] = useState(false);
  const [isVisibleManageOffer, setIsVisibleSelectOffer] = useState(false);
  const [isVisibleMemberConversion, setIsVisibleMemberConversion] = useState(false);
  const [selectedMemberConversion, setSelectedMemberConversion] = useState<TSelectedAffliate | null>(null);
  const [refreshDatesOnly, setRefreshDatesOnly] = useState(false);
  const [showStoreLevelAffiliateStats, setShowStoreLevelAffiliateStats] = useState(false);

  const addEvent = useEventContext();
  const buttonActions = useMemo(() => ({
    addMembers: () => {
      switch (source) {
        case OFFER_SOURCE.TUNE:
          setUpdateWizardMode('add');
          setUpdateWizardVisible(true);
          break;
        case OFFER_SOURCE.SHOPIFY:
          setRefreshDatesOnly(false);
          setUpdateWizardMode('add');
          setUpdateWizardVisible(true);
          break;
      }
    },
    export: (columns, data) => {
      addEvent(EventName.SalesTrackingTableExport, {
        exportDate: new Date().toISOString(),
        exportedTable: 'members',
      });
      exportToCsv(`affiliates-${Date.now()}`, columns, data);
    },
    pause: () => {
      switch (source) {
        case OFFER_SOURCE.TUNE:
          setUpdateWizardMode('deactivate');
          setUpdateWizardVisible(true);
          break;
        case OFFER_SOURCE.SHOPIFY:
          setRefreshDatesOnly(false);
          setUpdateWizardMode('deactivate');
          setUpdateWizardVisible(true);
          break;
      }
    },
    resume: (refreshDates: boolean) => {
      switch (source) {
        case OFFER_SOURCE.TUNE:
          setUpdateWizardMode('refresh');
          setUpdateWizardVisible(true);
          break;
        case OFFER_SOURCE.SHOPIFY:
          setRefreshDatesOnly(refreshDates);
          setUpdateWizardMode('refresh');
          setUpdateWizardVisible(true);
          break;
      }
    },
    refresh: () => {
      setIsVisibleSelectOffer(true);
    },
    openMemberConversionDrawer: (selectedAffliate: TSelectedAffliate) => {
      setIsVisibleMemberConversion(true);
      setSelectedMemberConversion(selectedAffliate);
    },
    storeLevelAffiliateStats: () => {
      setShowStoreLevelAffiliateStats(!showStoreLevelAffiliateStats);
    },
  }), [
    showStoreLevelAffiliateStats,
    addEvent,
    exportToCsv,
    source,
  ]);

  const { offerMembers, offerStats } = useMemo((): {
    offerMembers: IOfferDetailsQueriesReady['offerMembers'];
    offerStats: IOfferDetailsQueriesReady['offerStats'];
  } => {
    if (offerQuery.loading || offerQuery.error || !offerQuery.data?.offer) {
      return NULL_DATA;
    }
    switch (source) {
      case OFFER_SOURCE.SHOPIFY:
        if (isEmpty(offerQuery.data.offer.promos)) {
          return NULL_DATA;
        }
        const promo = first(offerQuery.data.offer.promos);
        return {
          offerMembers: map(promo.affiliateStats, (member) => {
            const promoMember = find(promo.affiliates, (affiliate) => affiliate.affiliateId === member.affiliateId);
            if (!isUndefined(promoMember) && promoMember.deletedDate === null) {
              return {
                affiliateId: member.affiliateId,
                affiliateOfferId: promoMember.id,
                avgSale: member.avgSale,
                code: member.affiliateCode,
                payoutId: promoMember.offerPayoutId,
                codeEndDate: promoMember.endDate,
                codeStartDate: promoMember.startDate,
                conversions: member.conversions,
                email: member.affiliateEmail,
                imageUrl: member.affiliateImageUrl,
                memberId: member.aspirexMemberId,
                name: member.affiliateName,
                payoutEarned: member.payoutEarned,
                payoutMade: member.payoutMade,
                sales: member.sales,
                salesBase: member.salesBase,
                avgSaleBase: member.avgSaleBase,
                payoutEarnedBase: member.payoutEarnedBase,
                currencies: promo.stats.baseCurrencies,
                source: OFFER_SOURCE.SHOPIFY,
                status: promoMember ? getStatusForPromoCodeMember(promoMember) : OfferMemberStatus.UNKNOWN,
                memberStatus: promoMember ? getMemberStatus(promoMember.affiliate.status) : MemberStatus.ACTIVE, // Default to ACTIVE
                promoCodeStatus: promoMember.status,
                externalDiscountCodeGid: promoMember.externalDiscountCodeGId,
                providerMetadata: promoMember.providerMetadata,
              };
            }
          }),
          offerStats: {
            avgSale: promo.stats.avgSale,
            clicks: 0,
            conversions: promo.stats.conversions,
            payoutEarned: promo.stats.payoutEarned,
            payoutMade: promo.stats.payoutMade,
            sales: promo.stats.sales,
            avgSaleBase: promo.stats.avgSaleBase,
            salesBase: promo.stats.salesBase,
            payoutEarnedBase: promo.stats.payoutEarnedBase,
            currencies: promo.stats.baseCurrencies,
          },
        };
      case OFFER_SOURCE.TUNE:
        if (isEmpty(offerQuery.data.offer.links)) {
          return NULL_DATA;
        }
        const link = first(offerQuery.data.offer.links);
        return {
          offerMembers: map(link.affiliateStats, (member): IAffiliateLinkMember => {
            const linkMember = find(link.affiliates, (affiliate) => affiliate.affiliateId === member.affiliateId);
            if (!isUndefined(linkMember) && linkMember.deletedDate === null) {
              return {
                affiliateId: linkMember.affiliateId,
                affiliateOfferId: linkMember.id,
                memberAffiliateOfferId: member.affiliateOfferId,
                affiliateLink: member.affiliateLink,
                affiliateShortLink: member.affiliateShortLink,
                avgSale: member.avgSale,
                payoutId: linkMember.offerPayoutId,
                clicks: member.clicks,
                conversions: member.conversions,
                email: member.affiliateEmail,
                imageUrl: member.affiliateImageUrl,
                linkCreationDate: member.linkCreationDate,
                memberId: member.aspirexMemberId,
                name: member.affiliateName,
                payoutEarned: member.payoutEarned,
                payoutMade: member.payoutMade,
                sales: member.sales,
                source: OFFER_SOURCE.TUNE,
                status: getStatusForAffiliateLinkMember(member),
                memberStatus: linkMember ? getMemberStatus(linkMember.affiliate.status) : MemberStatus.ACTIVE, // Default to ACTIVE
                linkStatusError: linkMember.updateLog,
              };
            }
          }),
          offerStats: {
            ...first(offerQuery.data.offer.links).stats,
          },
        };
    }
  }, [
    offerQuery.data,
    offerQuery.error,
    offerQuery.loading,
    source,
  ]);
  const filteredOfferMembers = offerMembers.filter((element) => element !== undefined);
  const pendingMemberCount = useMemo((): number => filteredOfferMembers.filter(memberIsPending).length, [filteredOfferMembers]);
  useEffect(() => {
    if (pendingMemberCount > 0) {
      offerQuery.startPolling(2000);
    } else {
      setDisableOfferEdit(false);
      offerQuery.stopPolling();
    }
  }, [offerQuery, pendingMemberCount, setDisableOfferEdit]);

  // get the list of selected members for the wizard
  const selectedMembers = useMemo(() => {
    if (isEmpty(selectedMemberIds)) {
      return map(filteredOfferMembers, (m): TMember => ({
        affiliateOfferId: m.affiliateOfferId,
        affiliateId: m.affiliateId,
        firstName: '',
        id: m.memberId,
        inOffer: true,
        payoutId: m.payoutId,
        instagramUsername: '',
        lastName: '',
        name: m.name,
        previousCode: {
          code: m.source === OFFER_SOURCE.SHOPIFY ? m.code : '',
          end: m.source === OFFER_SOURCE.SHOPIFY ? m.codeEndDate : null,
          start: m.source === OFFER_SOURCE.SHOPIFY ? m.codeStartDate : null,
        },
        forceCheckIn: false,
        programIds: [],
      }));
    }
    return reduce(filteredOfferMembers, (prev, next) => {
      if (includes(selectedMemberIds, next.memberId)) {
        prev.push({
          affiliateOfferId: next.affiliateOfferId,
          affiliateId: next.affiliateId,
          firstName: '',
          id: next.memberId,
          inOffer: true,
          payoutId: next.payoutId,
          instagramUsername: '',
          lastName: '',
          name: next.name,
          programIds: [],
          previousCode: {
            end: next.source === OFFER_SOURCE.SHOPIFY ? next.codeEndDate : null,
            code: next.source === OFFER_SOURCE.SHOPIFY ? next.code : '',
            start: next.source === OFFER_SOURCE.SHOPIFY ? next.codeStartDate : null,
          },
          forceCheckIn: false,
        });
      }
      return prev;
    }, [] as TMember[]);
  }, [filteredOfferMembers, selectedMemberIds]);

  const failedMembers = useMemo((): readonly TMember[] => map(
    filter(filteredOfferMembers, (member: TOfferMember) => member.status === OfferMemberStatus.FAILED),
    (member): TMember => ({
      affiliateOfferId: member.affiliateOfferId,
      firstName: '',
      affiliateId: member.affiliateId,
      id: member.memberId,
      payoutId: member.payoutId,
      inOffer: true,
      instagramUsername: '',
      lastName: '',
      name: member.name,
      programIds: [],
      previousCode: {
        end: member.source === OFFER_SOURCE.SHOPIFY ? member.codeEndDate : null,
        code: member.source === OFFER_SOURCE.SHOPIFY ? member.code : '',
        start: member.source === OFFER_SOURCE.SHOPIFY ? member.codeStartDate : null,
      },
      forceCheckIn: false,
      promoCodeStatus: member.promoCodeStatus,
    }),
  ), [filteredOfferMembers]);

  const failedPromoCodeErrors = useMemo(() => {
    if (!offerQuery.data?.offer) {
      return [];
    }
    switch (source) {
      case OFFER_SOURCE.SHOPIFY:
        const promo = first(offerQuery.data.offer.promos);
        if (!promo) {
          logger.warn('Offer source is Shopify but missing promo.');
          return [];
        }
        return promo.affiliates
          .filter((affiliate) => getStatusForPromoCodeMember(affiliate) === OfferMemberStatus.FAILED)
          .map(({ status }) => status);
      default:
        return [];
    }
  }, [offerQuery, source]);
  const [fixingFailedMembers, setFixingFailedMembers] = useState(false);

  if (offerQuery.loading) {
    return {
      status: OfferDetailsQueriesStatus.Loading,
    };
  }
  if (offerQuery.error) {
    logger.error(offerQuery.error);
    return {
      error: new Error('Oops! Something went wrong. Please refresh or try again later.'),
      status: OfferDetailsQueriesStatus.Failed,
    };
  }

  return {
    failedPromoCodeErrors,
    failedMembers: {
      members: failedMembers,
      dismissFailedMembers: () => {
        setFixingFailedMembers(false);
      },
      showFailedMembers: () => {
        if (isEmpty(failedMembers)) {
          return;
        }
        setFixingFailedMembers(true);
      },
      visible: fixingFailedMembers,
    },
    missingShopifyCredentials,
    memberTable: {
      buttonActions,
      disableCompose: isEmpty(filteredOfferMembers) || pendingMemberCount > 0,
      onSelectMembers: updatedSelectedMemberIds,
      selectedAffiliateIds: selectedMemberIds,
    },
    offerDetails: offerQuery.data.offer,
    offerMembers: filteredOfferMembers,
    offerStats,
    multipleShopifyOfferStats: isEnabledMultipleShopify && multipleOfferStats ? multipleOfferStats : [],
    refresh: offerQuery.refetch,
    status: OfferDetailsQueriesStatus.Ready,
    updateMembers: {
      mode: updateWizardMode,
      onClose: () => {
        setUpdateWizardVisible(false);
      },
      refreshDatesOnly,
      selectedMembers,
      visible: updateWizardVisible,
    },
    updateManageOffer: {
      mode: updateWizardMode,
      onClose: () => {
        setIsVisibleSelectOffer(false);
      },
      selectedMembers,
      visible: isVisibleManageOffer,
      setIsVisibleSelectOffer,
    },
    memberConversion: {
      visible: isVisibleMemberConversion,
      selectedMemberConversion,
      onClose: () => {
        setIsVisibleMemberConversion(false);
      },
    },
    multipleShopifyAffiliateStats: {
      visible: showStoreLevelAffiliateStats,
      affiliatesStats,
      toggle: () => {
        setShowStoreLevelAffiliateStats(!showStoreLevelAffiliateStats);
      },
    },
    shopifyStoreName: advertiserShopifyScopeCheck.data?.advertiser.shopifyResources[0]?.identifier ?? null,
  };
};
