import React from 'react';
import {
  TotalBudgetWidget,
  TotalAllocatedWidget,
  TotalSpentWidget,
  BudgetAllocatedWidget,
  SourceLevelSpendWidget,
  AverageSpendOnCreatorWidget,
  SpendByCreatorSegmentWidget,
  TopSpendWidget,
  SpendActivityWidget,
  TotalBudgetProjectWiseWidget,
  TotalAllocatedToBriefProjectWiseWidget,
  TotalSpentProjectWiseWidget,
  BudgetsAssignedToProjectWidget,
  BudgetMonthlySpendWidgetByProject,
  AverageSpendOnCreatorWidgetByProject,
  TopSpendWidgetByProject,
  SpendByCreatorSegmentWidgetByProject,
} from '../widgets';

interface WidgetConfig {
  type: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  props?: any;
}

const widgetMapper = (widgetConfig: WidgetConfig): React.ReactNode => {
  const { type, props } = widgetConfig;

  switch (type) {
    case 'budget_total_widget':
      return <TotalBudgetWidget {...props} />;
    case 'budget_total_allocated':
      return <TotalAllocatedWidget {...props} />;
    case 'budget_total_spent':
      return <TotalSpentWidget {...props} />;
    case 'budget_allocated_to':
      return <BudgetAllocatedWidget {...props} />;
    case 'budget_total_spend':
      return <SourceLevelSpendWidget {...props} />;
    case 'budget_average_spend_on_creators':
      return <AverageSpendOnCreatorWidget {...props} />;
    case 'budget_spend_by_creator_segment':
      return <SpendByCreatorSegmentWidget {...props} />;
    case 'budget_total_spend_on':
      return <TopSpendWidget {...props} />;
    case 'budget_spend_activity':
      return <SpendActivityWidget {...props} />;
    case 'project_total_widget':
      return <TotalBudgetProjectWiseWidget {...props} />;
    case 'project_total_allocated_brief_widget':
      return <TotalAllocatedToBriefProjectWiseWidget {...props} />;
    case 'project_total_spent':
      return <TotalSpentProjectWiseWidget {...props} />;
    case 'project_budgets_assigned':
      return <BudgetsAssignedToProjectWidget {...props} />;
    case 'project_monthly_spend':
      return <BudgetMonthlySpendWidgetByProject {...props} />;
    case 'project_average_spend_on_creators':
      return <AverageSpendOnCreatorWidgetByProject {...props} />;
    case 'project_spend_by_creator_segment':
      return <SpendByCreatorSegmentWidgetByProject {...props} />;
    case 'project_total_spend_on':
      return <TopSpendWidgetByProject {...props} />;
    default:
      return null;
  }
};

export default widgetMapper;
