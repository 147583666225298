import React, {
 useCallback, useEffect, useState,
} from 'react';
import {
 Button, Card, Col, Input, message, Row, Skeleton, Space, Switch, Typography,
} from '@revfluence/fresh';
import {
 ArrowLeftIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon,
 XmarkIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { useHistory, useParams } from 'react-router-dom';
import { RichTextEditor } from '@frontend/app/components';
import { CatalogSettingInput } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { logger } from '@common';
import { isNil } from 'lodash';
import { pfaV2Routes } from '../../routes';
import styles from './Customization.scss';
import { UploadCollectionItemImage } from './UploadCollectionItemImage';
import { useUpdateCatalog } from '../hooks/useUpdateCatalog';
import { useGetCatalogDetails } from '../hooks/useGetCatalogDetails';

const { Title, Text } = Typography;

interface SettingsCollapseProps {
  title: string;
  children: React.ReactNode;
  enabled: boolean;
  onChange: (value: boolean) => void;
  isUpdating: boolean;
}
const SettingsCollapse = ({
 title, children, enabled, onChange, isUpdating,
}: SettingsCollapseProps) => {
  const [open, setIsOpen] = useState(false);

  return (
    <Card className={styles.collectionCard}>
      <Row
        className={styles.cardHeader}
        align="middle"
        justify="space-between"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Col>
          <Text weight="semibold">{title}</Text>
        </Col>
        <Col>
          <Space align="center" size="middle">
            <div onClick={(e) => e.stopPropagation()}>
              <Switch size="default" checked={enabled} onChange={onChange} loading={isUpdating} />
            </div>
            <Button icon={open ? <ChevronUpIcon /> : <ChevronDownIcon />} type="text" />
          </Space>
        </Col>
      </Row>
      {open && <div className={styles.cardBody}>{children}</div>}
    </Card>
  );
};

export const Customization = () => {
  const { catalogId } = useParams<{ catalogId: string }>();

  const { catalog, loading: isCatalogLoading } = useGetCatalogDetails({
    variables: {
      id: Number(catalogId),
    },
  });

  const [heading, setHeading] = useState('');
  const [richText, setRichText] = useState('');
  const [resetKey, setResetKey] = useState(0);
  const [isUpdating, setIsUpdating] = useState({
    hasBannerImage: false,
    hasHeading: false,
    hasRichText: false,
    hidePriceFromCreators: false,
    hideTagsFromCreators: false,
  });

  useEffect(() => {
    setHeading(catalog?.settings?.heading || '');
  }, [catalog?.settings?.heading]);

  useEffect(() => {
    setRichText(catalog?.settings?.richText || '');
  }, [catalog?.settings?.richText]);

  const history = useHistory();

  const handleBack = () => {
    history.push(pfaV2Routes.settings.brandCatalogsDetails.replace(':catalogId', catalogId));
  };

  const { updateCatalog } = useUpdateCatalog({
    onCompleted: () => {
      message.success({
        key: 'updateCatalog',
        content: 'Catalog settings updated!',
      });
      setIsUpdating({
        hasBannerImage: false,
        hasHeading: false,
        hasRichText: false,
        hidePriceFromCreators: false,
        hideTagsFromCreators: false,
      });
    },
    onError: (error) => {
      logger.error('Error occurred while updating catalog settings in Customization', error);
      message.error({
        key: 'updateCatalog',
        content: error.message || 'An error occurred while updating catalog settings',
      });
      setIsUpdating({
        hasBannerImage: false,
        hasHeading: false,
        hasRichText: false,
        hidePriceFromCreators: false,
        hideTagsFromCreators: false,
      });
    },
  });

  const handleSave = useCallback((newSettings: CatalogSettingInput, showToast = false) => {
    // @typescript-eslint/no-unused-vars
    const { __typename, ...oldSettings } = catalog?.settings || {};
    if (showToast) {
      message.loading({
        key: 'updateCatalog',
        content: 'Updating catalog settings...',
      });
    }
    if (
      !isNil(newSettings.hasBannerImage)
      || !isNil(newSettings.hasHeading)
      || !isNil(newSettings.hasRichText)
      || !isNil(newSettings.hidePriceFromCreators)
      || !isNil(newSettings.hideTagsFromCreators)
    ) {
      setIsUpdating({
        hasBannerImage: !isNil(newSettings.hasBannerImage),
        hasHeading: !isNil(newSettings.hasHeading),
        hasRichText: !isNil(newSettings.hasRichText),
        hidePriceFromCreators: !isNil(newSettings.hidePriceFromCreators),
        hideTagsFromCreators: !isNil(newSettings.hideTagsFromCreators),
      });
    }
    updateCatalog({
      variables: {
        input: {
          id: Number(catalogId),
          name: catalog?.name,
          settings: {
            ...(oldSettings || {}),
            ...newSettings,
          },
        },
      },
    });
  }, [catalog?.name, catalog?.settings, catalogId, updateCatalog]);

  if (isCatalogLoading) {
    return <Skeleton />;
  }

  return (
    <Space direction="vertical" className={styles.Customization} size="large">
      <Space size="middle" className={styles.header} align="center">
        <Button icon={<ArrowLeftIcon />} type="text" onClick={handleBack} />
        <Title level={4} className={styles.title}>
          Collections
        </Title>
      </Space>
      <Space direction="vertical" size="middle">
        <Title level={5}>Additional Page Elements</Title>
        <Space direction="vertical">
          <SettingsCollapse
            title="Display Image Banner"
            enabled={catalog?.settings?.hasBannerImage}
            onChange={(value) => handleSave({ hasBannerImage: value })}
            isUpdating={isUpdating.hasBannerImage}
          >
            <Space direction="vertical">
              <UploadCollectionItemImage
                imageUrl={catalog?.settings?.bannerImage}
                onChange={(imageUrl) => handleSave({ bannerImage: imageUrl }, true)}
              />
              <Space direction="vertical" size={0}>
                <Text type="secondary">File Format: PNG, JPG, JPEG | Max Size: 10 MB</Text>
                <Text type="secondary">For best results, use an image with a 3:2 aspect ratio</Text>
              </Space>
            </Space>
          </SettingsCollapse>
          <SettingsCollapse
            title="Add a Heading"
            enabled={catalog?.settings?.hasHeading}
            onChange={(value) => handleSave({ hasHeading: value })}
            isUpdating={isUpdating.hasHeading}
          >
            <Space direction="vertical">
              <Text>Heading</Text>
              <Row wrap={false}>
                <Col flex={1}>
                  <Input
                    placeholder="Enter Heading"
                    value={heading}
                    onChange={(e) => setHeading(e.target.value)}
                    maxLength={120}
                    showCount
                  />
                </Col>
                {heading !== catalog?.settings?.heading && (
                <Col>
                  <Button icon={<CheckIcon />} type="link" onClick={() => handleSave({ heading }, true)} />
                  <Button
                    icon={<XmarkIcon />}
                    type="text"
                    danger
                    onClick={() => setHeading(catalog?.settings?.heading)}
                  />
                </Col>
                )}
              </Row>
            </Space>
          </SettingsCollapse>
          <SettingsCollapse
            title="Rich Text"
            enabled={catalog?.settings?.hasRichText}
            onChange={(value) => handleSave({ hasRichText: value })}
            isUpdating={isUpdating.hasRichText}
          >
            <RichTextEditor
              value={richText}
              onChange={(html) => setRichText(html)}
              toolbarOptions={
                [
                  'bold',
                  'italic',
                  'underline',
                  'list',
                  'bullet',
                  'link']
              }
              toolbarModules={
                [[
                  'bold',
                  'italic',
                  'underline',
                ],
                [{ list: 'ordered' }, { list: 'bullet' }], ['link']]
              }
              key={resetKey}
            />
            {richText !== catalog?.settings?.richText && (
              <Space>
                <Button icon={<CheckIcon />} type="link" onClick={() => handleSave({ richText }, true)} />
                <Button
                  icon={<XmarkIcon />}
                  type="text"
                  danger
                  onClick={() => {
                  setRichText(catalog?.settings?.richText);
                  setResetKey((prev) => prev + 1);
                }}
                />
              </Space>
            )}
          </SettingsCollapse>
        </Space>
      </Space>
      <div className={styles.divider} />
      <Space direction="vertical" size="middle">
        <Title level={5}>Product Info</Title>
        <Card>
          <Row align="middle" justify="space-between">
            <Text weight="semibold">Hide price from creators</Text>
            <Switch
              size="default"
              checked={catalog?.settings?.hidePriceFromCreators}
              onChange={(value) => handleSave({ hidePriceFromCreators: value })}
              loading={isUpdating.hidePriceFromCreators}
            />
          </Row>
        </Card>
        <Card>
          <Row align="middle" justify="space-between">
            <Text weight="semibold">Hide tags from creators</Text>
            <Switch
              size="default"
              checked={catalog?.settings?.hideTagsFromCreators}
              onChange={(value) => handleSave({ hideTagsFromCreators: value })}
              loading={isUpdating.hideTagsFromCreators}
            />
          </Row>
        </Card>
      </Space>
    </Space>
  );
};
