import React, { useEffect } from 'react';
import { useGetKlaviyoSyncStatus } from '@frontend/app/hooks';
import SyncProgressComponent from '../components/SyncProgressComponent';

const POLLING_INTERVAL = 5000;

interface IProps {
  syncJobId: number;
  onSyncStatusChange?: (isSyncing: boolean) => void;
}

const SyncProgressContainer: React.FC<IProps> = ({ syncJobId, onSyncStatusChange }) => {
  const {
 data: taskData, startPolling, stopPolling, loading,
} = useGetKlaviyoSyncStatus({
    variables: { id: syncJobId },
    skip: !syncJobId,
    fetchPolicy: 'network-only',
  });

  const progressPercentage = (taskData?.getKlaviyoSyncStatus.completeItems + taskData?.getKlaviyoSyncStatus.failedItems) / taskData?.getKlaviyoSyncStatus.totalItems * 100;
  useEffect(() => {
    if (loading || !taskData?.getKlaviyoSyncStatus) {
      return;
    }
    if (progressPercentage !== 100) {
      startPolling(POLLING_INTERVAL);
      onSyncStatusChange(true);
    } else if (progressPercentage === 100) {
      stopPolling();
      onSyncStatusChange(false);
    }
  }, [taskData, onSyncStatusChange, startPolling, stopPolling, progressPercentage, loading]);

  return (
    <SyncProgressComponent
      lastSynced={taskData?.getKlaviyoSyncStatus.createdDate}
      progressPercentage={progressPercentage}
      successCount={taskData?.getKlaviyoSyncStatus.completeItems}
      failureCount={taskData?.getKlaviyoSyncStatus.failedItems}
    />
  );
};

SyncProgressContainer.displayName = 'SyncProgressContainer';

export default SyncProgressContainer;
