import * as React from 'react';
import cx from 'classnames';
import {
  map, isEmpty, find, first, filter, size, isFunction,
} from 'lodash';
import {
 AngleDownIcon, CircleInfoIcon, ReplyAllIcon, ReplyIcon,
} from '@revfluence/fresh-icons/regular/esm';

import { UserGroupIcon, UpRightAndDownLeftFromCenterIcon } from '@revfluence/fresh-icons/regular/esm';
import {
 Select, Input, Space, Row, Col, Typography, Button, Modal,
 Dropdown,
 IMenuProps,
} from '@revfluence/fresh';
import { ClientFeature } from '@frontend/app/constants';
import { IMember, useClientFeatureEnabled } from '@frontend/app/hooks';
import { usePrevious } from '@frontend/utils/hooks';
import {
 IEditor,
 IEmailEditorState,
 IEmailEditorProps,
 EmailEditor,
} from '@frontend/app/components';
import { useResourceContext } from '@frontend/app/context';
import { ResourceType } from '@frontend/app/types/globalTypes';
import { AutocompleteMemberInput } from './AutocompleteMemberInput';
import { NoResourcesAlert } from './NoResourcesAlert';

const {
 useState, useEffect, useRef, useMemo, useCallback,
} = React;
const { Option } = Select;

import styles from './SendEmailForm.module.scss';

interface IProps extends IEmailEditorProps {
  resourceId?: number;

  // override to field
  toFieldString?: string;

  cCFieldString?: string;

  // sending with members
  members?: IMember[];
  // sending with member query
  memberCount?: number;
  sendWithMemberQuery?: boolean;

  // validMembers
  validMembers?: IMember[];

  // subject
  subject?: string;
  hideSubject?: boolean;
  subjectIsEditable?: boolean;

  replaceTemplateVariables?: boolean;

  disableRemoveRecipients?: boolean;

  // notify parent
  onResourceIdChange?(resourceId: number): void;
  onSubjectChange?(subject: string): void;
  onMembersChange?(members: IMember[]): void;
  onAdditionalCcChange?(members: IMember[]): void;
  onEditorStateChange?(editorState: IEmailEditorState): void;
  clearBackupPlans?(): void;
  onChangeReplyAll?(isReplyAll: boolean): void;
  // styles
  hasTopBorder?: boolean;
  hasFixedHeight?: boolean;
  expandHeader?: boolean;
  className?: string;

  enableMessageTemplate?: boolean;
  isReplyAll?: boolean;
  canReplyAll?: boolean;
  actionsDisabled?: boolean;
  previewVisible?: boolean;
  setPreviewVisible?: (value: boolean) => void;
  onClose?: () => void;
  // disable email input
  disableEmailInput?: boolean;
  enableExpandingEmailEditor?: boolean;
  toggleExpandEditor?: () => void;
  isHeaderForcedClose?: boolean;
}

/**
 * @type {React.FC}
 */
export const SendEmailForm: React.FC<IProps> = React.memo((props) => {
  const {
    toFieldString,
    cCFieldString,
    resourceId: initialResourceId,
    members: initialMembers,
    memberCount,
    sendWithMemberQuery,
    validMembers,
    subject: initialSubject,
    hideSubject,
    subjectIsEditable,
    hasTopBorder,
    hasFixedHeight,
    expandHeader,
    onResourceIdChange,
    onSubjectChange,
    onMembersChange,
    onAdditionalCcChange,
    onEditorStateChange,
    clearBackupPlans,
    onTemplateSelected,
    onChangeReplyAll,
    isReplyAll,
    canReplyAll,
    enableMessageTemplate,
    previewVisible,
    setPreviewVisible,
    enableExpandingEmailEditor,
    toggleExpandEditor,
    isHeaderForcedClose,
    ...editorProps
  } = props;
  const {
    activeEmailResources: resources,
    loading: loadingResources,
  } = useResourceContext();

  const [resourceId, setResourceId] = useState<number>(null);
  const previousResourceId = usePrevious(props.resourceId);
  const isNewSender = previousResourceId && previousResourceId != resourceId;
  const [members, setMembers] = useState<IMember[]>(initialMembers);
  const [additionalCc, setAdditionalCc] = useState<IMember[]>([]);
  const [subject, setSubject] = useState(initialSubject);
  const newRef = useRef<IEditor>(null);
  const editorRef = props.editorRef || newRef;
  const isInboxCcEnabled = useClientFeatureEnabled(ClientFeature.INBOX_CC);

  const composableResources = useMemo(() => (
    filter(resources, (resource) => resource.type !== ResourceType.IGDM)
  ), [resources]);

  const [headerExpanded, setHeaderExpanded] = useState(expandHeader || false);
  const [ccFieldExpanded, setCcFieldExpanded] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (previewVisible) {
      setModalIsOpen(false);
      setHeaderExpanded(false);
      setPreviewVisible(false);
    }
  }, [previewVisible, setPreviewVisible]);

  useEffect(() => {
    let newResourceId = null;
    if (initialResourceId) {
      const resource = find(composableResources, (r) => r.id === initialResourceId);

      newResourceId = resource?.id;
    } else if (!isEmpty(composableResources)) {
      newResourceId = first(composableResources).id;
    }

    if (newResourceId) {
      setResourceId(newResourceId);

      onResourceIdChange(newResourceId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [composableResources, setResourceId, initialResourceId]);

  useEffect(() => {
    if (!isEmpty(initialSubject)) {
      editorRef?.current?.focus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const infoRef = useRef<HTMLDivElement>();
  const handleBlur = (event) => {
    const containsChild = infoRef?.current.contains(event.relatedTarget);
    const menuItemClick = event.relatedTarget?.role === 'menuitem' || event.target?.role === 'menuitem';
    if (!expandHeader && !(containsChild || menuItemClick) && !modalIsOpen) {
      setHeaderExpanded(false);
    }
  };

  const expandedHeaderRecipientsRef = useRef<HTMLDivElement>();
  const [additionalMembers, setAdditionalMembers] = useState(0);

  /**
   * When there are more members that fit in a single line, in a non-expaded view we want to just display
   * single line of members and "+..." for any additional
   */
  useEffect(() => {
    if (!headerExpanded) {
      const headerWidth = expandedHeaderRecipientsRef.current?.offsetWidth;
      const expandIconWidth = 44;
      const padding = 8;
      const maxPlusIconWidth = 42;
      // Calculate remaining width to display members in a single line
      let membersWidth = headerWidth - expandIconWidth - padding - maxPlusIconWidth - padding;

      // Calculate number of members that fit in a single line
      // and set the additional members number
      if (validMembers) {
        let index;
        for (let i = 0; i < validMembers.length; i++) {
          // Based on number of characters in the name, approximate the member's container width
          const approxWidth = (size(validMembers[i].name) || size(validMembers[i].email) || 0) * 8.5;
          const isGreater = (membersWidth - approxWidth) > 0;
          if (isGreater) {
            membersWidth -= approxWidth;
          } else {
            index = i;
            i = validMembers.length + 1;
          }
        }

        if (index) {
          setAdditionalMembers(validMembers?.length - index);
        } else {
          setAdditionalMembers(0);
        }
      }
    }
  }, [
    expandedHeaderRecipientsRef,
    validMembers,
    headerExpanded,
    setAdditionalMembers,
  ]);

  /**
   * When there are more members than fit 1 line in a non-expanded view, we only display first number of members that fit
   * and "+..." at the end for the number of additional members
   */
  const slicedValidMembers = useMemo(() => {
    if (validMembers) {
      const startIdx = 0;
      const endIdx = additionalMembers ? validMembers.length - additionalMembers : validMembers.length;
      return validMembers.slice(startIdx, endIdx);
    }

    return [];
  }, [validMembers, additionalMembers]);

  const handleExpandEmailEditor = useCallback((e) => {
    e.stopPropagation();
    if (isFunction(toggleExpandEditor)) {
      toggleExpandEditor();
    }
  }, [toggleExpandEditor]);
  const replyIcon = isReplyAll ? <ReplyAllIcon /> : <ReplyIcon />;
  const replyMenu: IMenuProps['items'] = [
    {
      key: '1',
      label: 'Reply',
      icon: <ReplyIcon />,
      onClick: () => { onChangeReplyAll(false); },
    },
    {
      key: '2',
      label: 'Reply All',
      icon: <ReplyAllIcon />,
      onClick: () => { onChangeReplyAll(true); },
    },
  ];
  const replyAllSwitcher = (
    <div
      onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => { e.stopPropagation(); }}
    >
      <Dropdown
        menu={{ items: replyMenu }}
        trigger={['click']}
      >
        <Button type="text" onClick={(e) => e.preventDefault()}>
          {replyIcon}
          <AngleDownIcon />
        </Button>
      </Dropdown>
    </div>
  );
  const formContent = () => (
    <div className={cx(styles.SendEmailForm, props.className, {
      [styles.headerForcedClose]: isHeaderForcedClose,
      [styles.fullHeight]: !hasFixedHeight,
    })}
    >
      <div
        className={cx(styles.info, {
        [styles.noTopBorder]: !hasTopBorder,
        [styles.cursor]: !headerExpanded,
      })}
        ref={infoRef}
        tabIndex={1}
        onBlur={handleBlur}
        onClick={() => {
          if (!headerExpanded) {
            setHeaderExpanded(true);
          }
        }}
      >
        {headerExpanded && !isHeaderForcedClose ? (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Row justify="center" style={{ alignItems: 'center' }}>
              <Col flex="80px">From:</Col>
              <Col flex="1">
                {!isEmpty(composableResources) && (
                <Select
                  style={{ width: '100%' }}
                  value={resourceId}
                  onChange={(id) => {
                  setResourceId(id);
                  onResourceIdChange(id);
                }}
                  disabled={!resourceId}
                  placeholder="No accounts found."
                  loading={loadingResources}
                >
                  {map(composableResources, (resource) => (
                    <Option key={resource.id} value={resource.id}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {resource.authProvider.isShared && (<UserGroupIcon className={styles.sharedIcon} />)}
                        {resource.authProvider.userExternalDisplayId}
                      </div>
                    </Option>
                ))}
                </Select>
            )}
                {composableResources && composableResources.length === 0 && (
                <NoResourcesAlert className={styles.resourceSettingsButton} />
            )}
              </Col>
            </Row>
            {
              isNewSender && (
                <Row justify="center" style={{ alignItems: 'center' }}>
                  <Col flex="80px" />
                  <Col flex="1">
                    <span className={styles.newThread}>
                      <CircleInfoIcon />
                      {' '}
                      Replying with a new email address will create a new thread.
                    </span>
                  </Col>
                </Row>
              )
            }
            <Row justify="center" style={{ alignItems: 'center' }}>
              <Col flex="80px">To:</Col>
              <Col flex="1">
                {toFieldString && (
                  <span className={styles.memberCount}>
                    {toFieldString}
                  </span>
                )}
                {!toFieldString && (
                  <>
                    {sendWithMemberQuery && (
                      <span className={styles.memberCount}>
                        {memberCount}
                        {' '}
                        User
                        {memberCount > 1 ? 's' : ''}
                        {!ccFieldExpanded && isInboxCcEnabled && (
                          <span
                            className={styles.memberCcForMemberCount}
                            onClick={() => {
                              setCcFieldExpanded(true);
                            }}
                          >
                            Cc
                          </span>
                        )}
                      </span>
                    )}
                    {!sendWithMemberQuery && (
                      <>
                        <AutocompleteMemberInput
                          validMembers={validMembers}
                          members={validMembers}
                          disableInput={props.disableEmailInput}
                          clearBackupPlans={clearBackupPlans}
                          onChange={(members) => {
                            setMembers(members);
                            onMembersChange(members);
                          }}
                        />
                        {!ccFieldExpanded && isInboxCcEnabled && (
                          <div
                            className={styles.memberCc}
                            onClick={() => {
                              setCcFieldExpanded(true);
                            }}
                          >
                            Cc
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </Col>

              {canReplyAll
                && (
                <Col flex="25px">
                  {replyAllSwitcher}
                </Col>
)}
            </Row>
            {ccFieldExpanded && (
              <Row justify="center" style={{ alignItems: 'center' }}>
                <Col flex="80px">Cc:</Col>
                <Col flex="1">
                  {cCFieldString && (
                    <span className={styles.memberCount}>
                      {cCFieldString}
                    </span>
                  )}
                  {!cCFieldString && (
                    <AutocompleteMemberInput
                      validMembers={additionalCc}
                      placeholder="Select Cc recipients"
                      members={additionalCc}
                      disableInput={props.disableEmailInput}
                      clearBackupPlans={clearBackupPlans}
                      onChange={(additionalCc) => {
                        setAdditionalCc(additionalCc);
                        onAdditionalCcChange(additionalCc);
                      }}
                    />
                  )}
                </Col>
              </Row>
            )}
            {!hideSubject && !isHeaderForcedClose && (
              <Row justify="center" style={{ alignItems: 'center' }}>
                <Col flex="80px">Subject:</Col>
                <Col flex="1">
                  <Input
                    autoFocus={isEmpty(initialSubject) && members.length > 0}
                    placeholder="Enter email subject"
                    value={subject}
                    disabled={!isEmpty(initialSubject) && !subjectIsEditable}
                    onChange={(event) => {
                      const newSubject = event.target.value;

                      setSubject(newSubject);
                      onSubjectChange(newSubject);
                    }}
                  />
                </Col>
              </Row>
            )}
          </Space>
        ) : (
          <div ref={expandedHeaderRecipientsRef} style={{ width: '100%' }}>
            <Space className={styles.formSpace}>
              <Space style={{ flexFlow: 'wrap' }}>
                {validMembers?.length ? (
                  <>
                    {slicedValidMembers.map((member) => (
                      <div className={cx(styles.validMember)} key={member.id}>
                        <Typography.Text>
                          {member.name || member.email}
                        </Typography.Text>
                      </div>
                    ))}
                    {additionalMembers ? (
                      <div className={cx(styles.additionalMembers)} key="plus-members">
                        <Typography.Text>
                          +
                          {' '}
                          {additionalMembers}
                        </Typography.Text>
                      </div>
                    ) : null}
                  </>
                ) : toFieldString ? (
                  <div className={cx(styles.validMember)}>
                    <Typography.Text>
                      {toFieldString}
                    </Typography.Text>
                  </div>
                  ) : sendWithMemberQuery ? (
                    <div className={cx(styles.validMember)}>
                      <Typography.Text>
                        {memberCount}
                        {' '}
                        User
                        {memberCount > 1 ? 's' : ''}
                      </Typography.Text>
                    </div>
                  ) : (
                    <Typography.Text type="secondary">
                      Select recipients
                    </Typography.Text>
                  )}
              </Space>
              <div className={styles.headerButtons}>
                {canReplyAll && replyAllSwitcher}
                {enableExpandingEmailEditor && (
                <Button
                  type="text"
                  onClick={handleExpandEmailEditor}
                >
                  <UpRightAndDownLeftFromCenterIcon />
                </Button>
              )}
              </div>
            </Space>
          </div>
          )}
      </div>
      <EmailEditor
        {...editorProps}
        editorRef={editorRef}
        className={styles.editor}
        members={members}
        actionsDisabled={props.actionsDisabled || members.length === 0}
        hasFixedHeight={hasFixedHeight}
        onTemplateSelected={(template) => {
          setSubject(template.subject);
          onSubjectChange(template.subject);
          onTemplateSelected(template);
        }}
        onChange={(editorState) => {
          onEditorStateChange(editorState);
        }}
        onClose={props.onClose}
        onAttachmentsSelected={props.onAttachmentsSelected}
        attachments={props.attachments}
        onDeleteAttachment={props.onDeleteAttachment}
        enableMessageTemplate={enableMessageTemplate}
      />
    </div>
  );
  if (modalIsOpen) {
    return (
      <Modal
        visible={modalIsOpen}
        onCancel={() => {
          setModalIsOpen(false);
          setHeaderExpanded(false);
        }}
        width={840}
        footer={null}
        title="New Message"
        bodyStyle={{ padding: '0', height: 400 }}
      >
        {formContent()}
      </Modal>
    );
  } else {
    return formContent();
  }
});

SendEmailForm.defaultProps = {
  toFieldString: null,
  cCFieldString: null,

  members: [],
  memberCount: 0,
  sendWithMemberQuery: false,

  subject: '',
  subjectIsEditable: true,

  // notify parent
  onResourceIdChange: () => undefined,
  onSubjectChange: () => undefined,
  onMembersChange: () => undefined,
  onEditorStateChange: () => undefined,

  hasTopBorder: true,
  hasFixedHeight: false,
  className: null,
};

SendEmailForm.displayName = 'SendEmailForm';
