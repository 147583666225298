import React from 'react';
import { MainMetric, WidgetContainer } from '@frontend/app/refresh-components';
import { Pie, PieChart } from 'recharts';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartLegendContent,
} from '@/shadcn/components/ui/chart';
import { useGetBudgetDashboardSpendSummaryQuery } from '../hooks';

interface AverageSpendOnCreatorWidgetByProjectProps {
  projectId?: number;
  fiscalYear?: string;
  className?: string;
}

export default function AverageSpendOnCreatorWidget(props: AverageSpendOnCreatorWidgetByProjectProps) {
  const { projectId, fiscalYear, className } = props;
  const isParemetersAbsent = !projectId || !fiscalYear;
  const { data: budgetSummary, loading } = useGetBudgetDashboardSpendSummaryQuery({
    variables: {
      programId: projectId,
      fiscalYear,
    },
    skip: isParemetersAbsent,
  });

  const chartData = [
    { category: 'aboveAvg', percent: budgetSummary?.summary?.averageSpendData?.aboveAveragePercent || 0, fill: 'var(--color-aboveAvg)' },
    { category: 'belowAvg', percent: budgetSummary?.summary?.averageSpendData?.belowAveragePercent || 0, fill: 'var(--color-belowAvg)' },
  ];

  const chartConfig = {
    percent: {
      label: 'Percent',
    },
    aboveAvg: {
      label: 'Above Average',
      color: 'hsl(var(--chart-1))',
    },
    belowAvg: {
      label: 'Below Average',
      color: 'hsl(var(--chart-2))',
    },
  } as ChartConfig;

  const missingParametersContent = (
    <div className="w-full h-full flex justify-center items-center">
      <p>No data found</p>
    </div>
  );
  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );

  const mainContent = (
    <div className="flex flex-col">
      <MainMetric
        className="flex-1"
        value={budgetSummary?.summary?.averageSpendData?.averageSpentAmount || 0}
        size="medium"
        metricType="currency"
        subHeading="of Total Budget"
        icon={(
          <Badge variant="outline" className="text-green-700">
            {`${budgetSummary?.summary?.averageSpendData?.averageSpentAmount || 0}%`}
          </Badge>
        )}
      />
      <div>
        <ChartContainer config={chartConfig} className="mx-auto aspect-square max-h-[300px]">
          <PieChart>
            <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
            <Pie data={chartData} dataKey="percent" nameKey="category" innerRadius={40} />
            <ChartLegend content={<ChartLegendContent nameKey="category" />} className="flex-wrap gap-2" />
          </PieChart>
        </ChartContainer>
      </div>
    </div>
  );
  return (
    <WidgetContainer
      widgetTitle="Average Spend on Creators"
      className={`${className}`}
      bgColor="bg-white"
      textColor="text-gray-700"
    >
      {isParemetersAbsent ? missingParametersContent : loading ? loadingContent : mainContent}
    </WidgetContainer>
  );
}
