import React, { useMemo } from 'react';
import { WidgetContainer } from '@frontend/app/refresh-components';
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
} from 'recharts';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartLegendContent,
} from '@/shadcn/components/ui/chart';
import { useGetBudgetDashboardSpendSummaryQuery } from '../hooks';

interface BudgetMonthlySpendWidgetByProjectProps {
  projectId?: number;
  fiscalYear?: string;
  className?: string;
}

export default function BudgetMonthlySpendWidgetByProject(props: BudgetMonthlySpendWidgetByProjectProps) {
  const { projectId, fiscalYear, className } = props;
  const isParemetersAbsent = !projectId || !fiscalYear;
  const { data: budgetSummary, loading } = useGetBudgetDashboardSpendSummaryQuery({
    variables: {
      programId: projectId,
      fiscalYear,
    },
    skip: isParemetersAbsent,
  });

  const chartData = useMemo(() => {
    if (!loading && budgetSummary?.summary?.monthlySpendData?.length) {
      return budgetSummary?.summary?.monthlySpendData;
    }
    return null;
  }, [loading, budgetSummary]);

  const chartConfig = {
    briefs: {
      label: 'Spend in Briefs',
      color: 'hsl(var(--chart-1))',
    },
    commission: {
      label: 'Spend in Commission',
      color: 'hsl(var(--chart-2))',
    },
    others: {
      label: 'Others',
      color: 'hsl(var(--chart-3))',
    },
  } as ChartConfig;

  const missingParametersContent = (
    <div className="w-full h-full flex justify-center items-center">
      <p>No data found</p>
    </div>
  );
  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const mainContent = (
    <div className="flex flex-col h-[350px]">
      <ChartContainer config={chartConfig}>
        <BarChart data={chartData}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="month"
            tickLine={false}
            tickMargin={10}
            axisLine={false}
            tickFormatter={(value) => value.slice(0, 3)}
          />
          <YAxis tickLine={false} axisLine={false} tickMargin={10} width={40} tickFormatter={(value) => `${value}`} />
          <ChartTooltip content={<ChartTooltipContent hideLabel />} />
          <ChartLegend content={<ChartLegendContent />} />
          <Bar dataKey="briefs" strokeWidth={3} stackId="a" fill="var(--color-briefs)" radius={[0, 0, 4, 4]} />
          <Bar dataKey="commission" strokeWidth={3} stackId="a" fill="var(--color-commission)" radius={[0, 0, 0, 0]} />
          <Bar dataKey="others" strokeWidth={3} stackId="a" fill="var(--color-others)" radius={[4, 4, 0, 0]} />
        </BarChart>
      </ChartContainer>
    </div>
  );

  return (
    <WidgetContainer
      widgetTitle="Monthly Spend"
      className={`${className}`}
      bgColor="bg-white"
      textColor="text-gray-700"
    >
      {isParemetersAbsent || !chartData ? missingParametersContent : loading ? loadingContent : mainContent}
    </WidgetContainer>
  );
}
