/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IToastRefHandles,
  Toast,
} from '@components';
import { useResizeDetector } from 'react-resize-detector';

import {
  Button,
} from 'antd';
import cx from 'classnames';
import {
  chunk,
  endsWith,
  get,
  isEmpty,
  isFunction,
  isNil,
  isUndefined,
  keyBy,
  keys,
  map,
  reduce,
  reject,
  some,
  find,
  isArray,
  filter,
} from 'lodash';
import moment from 'moment';
import * as React from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import {
  Row,
  Space,
  Alert,
  Typography,
  Divider,
  Modal,
} from '@revfluence/fresh';
import { MagnifyingGlassIcon } from '@revfluence/fresh-icons/regular/esm';

import { logger } from '@common';
import {
  Drawer,
} from '@frontend/app/components';
import {
  IMembersTableSearchQuery,
  MembersTable,
  OWNED_BY_ME_FILTER_VALUE,
  LAST_MENTION_DATE_7DAYS_FILTER_VALUE,
} from '@frontend/app/components/MembersTableOld/MembersTable';
import {
  IWorkflowActionParameters,
} from '@frontend/app/containers/Application/ApplicationContainer';
import {
  ApplicationDrawer,
} from '@frontend/app/containers/Application/ApplicationDrawer';
import {
  ICounts,
  TGetTaskBySpecUriAndId,
  useCustomCTA,
  useCustomTaskNotice,
  useOverviewPage,
  useWorkItemsTableColumnConfig,
} from '@frontend/app/containers/Projects/hooks';
import { WorkflowTask } from '@frontend/applications/AffiliatesApp/utils/workflowUtils';
import { THandleTaskSelected } from '@frontend/app/containers/Projects/ProjectsPage/ProjectsPage';
import {
  TCondition,
  TProject,
  TTask,
  TWorkItem,
  TWorklet,
} from '@frontend/app/containers/Projects/types';
import {
  useGetApplicationsByIds,
  useGetAllCompletedMembersQuery,
  useMarkWorkItemsViewedMutation,
  IThread,
  useClientFeatureEnabled,
  useGetContentGuidelineTemplatesForProject,
  CommunitySwitcherProvider,
  useGetWorkItemsQuery,
  useGetTermsName,
  usePredefinedSegmentsForProgram,
  useDefaultSegmentsForProgram,
} from '@frontend/app/hooks';
import {
  MemberSearchQuery_members as IMember,
} from '@frontend/app/queries/types/MemberSearchQuery';
import { MemberListContextProvider } from '@frontend/app/context/MemberListContext';
import { TERMS_APP_ID } from '@frontend/app/constants/applicationIds';
import { FetchContextProvider } from '@frontend/utils';
import { useMessagingContext } from '@frontend/hooks';
import { GetAllTasksQuery_tasks as ITask } from '@frontend/app/queries/types/GetAllTasksQuery';

import {
  hasError, hasNotification, isTransient, parseToITaskUI,
} from '@frontend/app/utils/task';
import { MessagingProvider } from '@frontend/app/containers/MessagingApp/context/MessagingAppContext';
import { MessageList } from '@frontend/app/containers/MessagingApp/MessageList';
import {
  QuickFilters,
} from '@frontend/app/components/FilterList/QuickFilters';
import { ClientFeature } from '@frontend/app/constants';
import { getWorkletNameBySpecURI } from '@frontend/app/utils/worklet';
import { useFetchMembers } from '@frontend/app/components/MembersTableOld/hooks/useFetchMembers';
import { SelectedField } from '@frontend/app/queries';
import {
  ColumnKey,
  ProjectsPageState,
  AllInProgressStageTitle,
  Task,
} from '@frontend/app/containers/Projects/constants';
import {
  PredefinedSegmentsQuery_segments as IPredefinedSegment,
} from '@frontend/app/queries/types/PredefinedSegmentsQuery';
import { useApolloClient } from '@frontend/applications/AffiliatesApp/hooks';
import { GET_OFFERS_BY_SEARCH_QUERY } from '@frontend/applications/AffiliatesApp/queries';
import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import { useRefreshSelectedMembers } from '@frontend/app/hooks';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useGetMemberIdsByInternalStatus } from '@frontend/app/hooks/contentReviews/useGetMemberIdsByInternalStatus';
import { InternalContentReviewStatus } from '@frontend/app/types/globalTypes';
import { useGetCreatorsOrderCatalogInfo } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetCreatorsOrderCatalogInfo';
import { useApolloClient as usePfaApolloClient } from '@frontend/applications/ProductFulfillmentApp/context';
import { useAuth } from '@frontend/context/authContext';
import { ModalType } from '../AddMembersToProgramModal/AddMembersToCollectionModal';
import { TableHeader } from '../TableHeader/TableHeader';

import styles from './WorkItemsList.scss';
import { NewMemberDetailContainer } from '../../../MemberDetail/NewMemberDetailContainer';
import { WorkItemItemBox } from './WorkItemItemBox';
import { ShopifyReauthenticateModal } from './ShopifyReauthenticateModal';
import { ContentReviewFilters } from '../GroupContentReviewPage/ContentReviewWorkItemFilters';
import { WorkletSpecKey } from '../../utils';

const { Text, Title } = Typography;
const {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} = React;

interface IProps {
  applicationId?: string;
  className?: string;
  counts: ICounts;
  onSelectWorkItems?: (workItemIds: string[]) => void;
  pageState?: ProjectsPageState;
  project?: TProject;
  refetchCounts: () => Promise<void>;
  task?: TTask;
  getTaskBySpecUriAndId: TGetTaskBySpecUriAndId;
  onTaskSelected: THandleTaskSelected;
  worklets?: TWorklet[];
  conditions?: TCondition[];
  openAddToCollectionModal(modalType: ModalType): void;
  onSelectMemberIds?: (memberIds: number[]) => void;
  workletSpecUri?: string;
  tasks: TTask[];
  showSelectOffer?: (value:boolean) => void;
  migrateToGraphQL?: boolean;
}

const WORKITEM_VIEWED_BATCHSIZE = 200;
const PFA_COLUMN_PREFIX = 'pfa__';
// store them as strings as that is how it's currently passed around in workItems
const TASKS_REQUIRING_EMAIL = [
  Task.SendTermsTask.toString(),
];
const CONTENT_STAGES = {
  CONTENT: 'Content',
  TRACK_POSTS: 'Track Posts',
  REVIEW_CONTENT: 'Review Content',
};

export const WorkItemsList: React.FC<IProps> = React.memo(({
  className,
  counts,
  onSelectWorkItems,
  pageState,
  project,
  task,
  getTaskBySpecUriAndId,
  onTaskSelected,
  refetchCounts,
  worklets,
  conditions,
  openAddToCollectionModal,
  onSelectMemberIds,
  workletSpecUri,
  tasks,
  showSelectOffer,
  migrateToGraphQL,
}) => {
  const toastRef = useRef<IToastRefHandles>();

  const { showErrorMessage } = useMessagingContext();
  const [displayedRowCount, setDisplayedRowCount] = useState(0);
  const [selectedQuickFilter, setSelectedQuickFilter] = useState<string>('');
  const [offers, setOffers] = useState<GetOfferById_offer[]>([]);
  const { height, ref: tableContainerRef } = useResizeDetector();
  const contentGuidelinesEnabled = useClientFeatureEnabled(ClientFeature.CONTENT_GUIDELINES);
  const isNewWorkletMenuEnabled = useClientFeatureEnabled(ClientFeature.WORKET_MENU);
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);
  const isPFAColumnsEnabled = useClientFeatureEnabled(ClientFeature.PROJECT_PFA_COLUMNS);
  const isBudgetAllocationEnabled = useClientFeatureEnabled(ClientFeature.BUDGET_ALLOCATION);
  const [dynamicQueryFields, setDynamicQueryFields] = useState<Set<SelectedField>>(new Set<SelectedField>());
  const { renderArchivedNotice } = useOverviewPage(project);
  const staApolloClient = useApolloClient();
  const [selectedMembers, setSelectedMembers] = useState<IMember[]>();

  // Block for fetching saved and default columns
  const {
    loading: isLoadingPredefinedProgramSegments,
    data: { segments = [] } = {},
    refetch: refetchSegments,
  } = usePredefinedSegmentsForProgram({
    programId: project?.id,
  }, { skip: !isPFAColumnsEnabled });

  const {
    loading: isLoadingReviews,
    memberIds: filteredGCRMemberIds,
    refetch: refetchFilteredGCRMemberIds,
} = useGetMemberIdsByInternalStatus({
    variables: {
      programId: project?.id,
      statuses: [],
    },
    skip: !project?.id || task?.workletSpecUri !== 'ReviewContentWorkletSpecification',
  });

  const {
    loading: isLoadingDefaultProgramSegments,
    data: { segments: defaultSegments = [] } = {},
  } = useDefaultSegmentsForProgram({
    programId: project?.id,
  }, { skip: !isPFAColumnsEnabled, fetchPolicy: 'no-cache' });

  const handleRefetchSegments = useCallback(() => (projectId) => {
    refetchSegments({ programId: projectId });
  }, [refetchSegments]);

  const savedColumns = useMemo(() => {
    if (isLoadingPredefinedProgramSegments) return [];
    if (task) {
      return find(segments, (segment) => segment.id === `${task.taskId}+${task.workletSpecKey}_${project?.id}`);
    }
    if (workletSpecUri) {
      return find(segments, (segment) => segment.id === `In Stage_${workletSpecUri}_${project?.id}`);
    }
    return find(
      segments,
      (segment) => segment.id.split('_')[0] === AllInProgressStageTitle,
    );
  }, [
    task,
    workletSpecUri,
    segments,
    isLoadingPredefinedProgramSegments,
    project?.id,
  ]);

  const defaultSavedColumns = useMemo(() => {
    if (isLoadingDefaultProgramSegments) return [];
    if (task) {
      return find(defaultSegments, (segment) => segment.id === `${task.taskId}+${task.workletSpecKey}_${project?.id}`);
    }
    if (workletSpecUri) {
      return find(defaultSegments, (segment) => segment.id === `In Stage_${workletSpecUri}_${project?.id}`);
    }
    return find(
      defaultSegments,
      (segment) => segment.id.split('_')[0] === AllInProgressStageTitle,
    );
  }, [
    task,
    workletSpecUri,
    defaultSegments,
    isLoadingDefaultProgramSegments,
    project?.id,
  ]);

  useEffect(() => {
    if (!isPFAColumnsEnabled || isLoadingPredefinedProgramSegments) return;

    // Does not include the memberSchema fields as they are in members table and always fetched
    const dbColumns = (savedColumns as IPredefinedSegment)?.columns?.dbColumns || [];
    const projectColumns = (savedColumns as IPredefinedSegment)?.columns?.projectColumns || [];
    const allColumns = [...dbColumns, ...projectColumns];

    const updatedDynamicQueryFields = new Set<SelectedField>();
    // Change this logic to have suffix for aggregation fields in the future(order summary, program order summary, etc.)
    if (find(allColumns, (column) => column?.startsWith(PFA_COLUMN_PREFIX))) {
      updatedDynamicQueryFields.add(SelectedField.ORDER);
    }
    setDynamicQueryFields(updatedDynamicQueryFields);
  }, [
    isPFAColumnsEnabled,
    isLoadingPredefinedProgramSegments,
    savedColumns,
    setDynamicQueryFields,
  ]);

  const {
    data: otherProjectGuidelinesData,
    loading: otherProjectGuidelinesLoading,
  } = useGetContentGuidelineTemplatesForProject({
    skip: !contentGuidelinesEnabled || task?.taskId !== WorkflowTask.SEND_TERMS_TASK || !project?.id,
    variables: {
      programId: project?.id,
    },
    fetchPolicy: 'no-cache',
  });

  const {
    data: {
      workItems,
    } = {},
    loading: isWorkItemsLoading,
    refetch: refetchWorkItems,
  } = useGetWorkItemsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      specKey: project?.specKey,
      specUri: workletSpecUri,
      taskId: task?.taskId,
    },
    skip: !project?.specKey,
  });

  const { token } = useAuth();
  const pfaApolloClient = usePfaApolloClient(token);
  const { creatorsOrderCatalogInfo } = useGetCreatorsOrderCatalogInfo({
    variables: {
      orderIds: workItems?.map((workItem) => workItem.data[Task.SendOrderRequestV2]?.orderId),
    },
    skip: !workItems?.length
    || ![
      Task.WaitingForOrderRequestTaskV2,
      Task.ReviewOrderRequestTaskV2,
      Task.ProcessingCreatorOrderTaskV2,
      Task.CreatorOrderInTransitTaskV2,
    ].includes(task?.taskId as Task)
    || !pfaApolloClient,
    client: pfaApolloClient,
  });

  // Main identifier when the page changes
  const pageId = useMemo(
    () => (
      pageState === ProjectsPageState.Task
        ? `${task?.workletSpecUri}/${task?.taskId}`
        : pageState
    ),
    [pageState, task],
  );
  const isCompletedPage = pageState === ProjectsPageState.Completed;

  const [markWorkItemsViewed] = useMarkWorkItemsViewedMutation();
  useEffect(
    () => {
      if (pageState === ProjectsPageState.AllInProgress || isWorkItemsLoading) {
        return;
      }
      if (some(workItems, { viewed: false })) {
        const unViewedItems = reject(workItems, 'viewed');
        const workItemViews = map(unViewedItems, (workItem) => ({
          workItemId: workItem.id,
          workletSpecURI: workItem.specURI,
          taskId: workItem.taskId,
        }));

        Promise.allSettled(
          map(
            chunk(workItemViews, WORKITEM_VIEWED_BATCHSIZE),
            async (batchedWorkItemViews) => {
              await markWorkItemsViewed({
                variables: { workItemViews: batchedWorkItemViews, specKey: project?.specKey },
              });
            },
          ),
        ).then(
          () => refetchCounts(),
        ).catch(logger.error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workItems, isWorkItemsLoading],
  );

  useEffect(() => {
    if (migrateToGraphQL && project) {
      staApolloClient.query({
        query: GET_OFFERS_BY_SEARCH_QUERY,
        variables: {
          query: {
            programId: project.id,
          },
        },
        fetchPolicy: 'no-cache',
      }).then((response) => {
        if (response.data?.offers?.length) {
          setOffers(response.data?.offers);
        }
      }).catch((error) => {
        logger.error(error.message);
      });
    }
  }, [project, staApolloClient, migrateToGraphQL, task?.taskId]);

  /**
   * Router
   */
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  useEffect(
    () => {
      if (isFunction(onSelectMemberIds)) {
        onSelectMemberIds(map(selectedMembers, (member) => member.id));
      }
    },
    [onSelectMemberIds, selectedMembers],
  );
  const workItemByMemberId = useMemo(
    (): { [id: number]: TWorkItem } => (
      keyBy<TWorkItem>(workItems, (workItem) => workItem.data.memberId)
    ),
    [workItems],
  );
  const selectedWorkItems = useMemo(
    (): TWorkItem[] => reduce(
      selectedMembers,
      (result, member) => {
        const workItem = workItemByMemberId[member.id];
        if (workItem) {
          result.push(workItem);
        }
        return result;
      },
      [],
    ),
    [selectedMembers, workItemByMemberId],
  );
  const selectedWorkItemIds = useMemo(
    () => map(selectedWorkItems, ({ id }) => id),
    [selectedWorkItems],
  );

  useEffect(
    () => {
      if (isFunction(onSelectWorkItems)) {
        onSelectWorkItems(selectedWorkItemIds);
      }
    },
    [onSelectWorkItems, selectedWorkItemIds],
  );

  const resetSelection = useCallback(() => {
    setSelectedMembers([]);
  }, [setSelectedMembers]);

  /**
   * Completed members
   */
  const {
    data: {
      members: completedMembers = undefined,
    } = {},
    loading: isFetchingCompletedMembers,
    refetch: refetchCompletedMembers,
  } = useGetAllCompletedMembersQuery({
    variables: {
      specKey: project?.specKey,
    },
    skip: !isCompletedPage || !project?.specKey,
  });

  /**
   * Application
   */
  const [appWorkflowParameters, setAppWorkflowParamters] = useState<IWorkflowActionParameters>();
  const [deepLinkParameters, setDeepLinkParameters] = useState<string>();

  const applicationId = useMemo(() => task?.taskMetaData?.serviceId, [task]);

  const {
    data: {
      applications = null,
    } = {},
    loading: isApplicationsLoading,
    refetch: refetchApplications,
  } = useGetApplicationsByIds([applicationId], {
    skip: isNil(applicationId),
  });

  /**
   * Refetch all data
   */
  const refetchData = useCallback(async () => {
    resetSelection();
    if (project?.specKey) {
      if (isCompletedPage) {
        await refetchCompletedMembers({
          specKey: project.specKey,
        });
      } else {
        await refetchWorkItems();
        await refetchCounts();
      }
    }
    if (applicationId) {
      await refetchApplications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageId,
    workletSpecUri,
    project,
    isCompletedPage,
    applicationId,
  ]);

  useEffect(
    () => {
      if (!isWorkItemsLoading) {
        refetchData();
      }
    },
    [
      refetchData,
      isWorkItemsLoading,
    ],
  );

  const currentStageTitle = useMemo(() => {
    switch (pageState) {
      case ProjectsPageState.AllInProgress:
        return 'All In Progress';
      case ProjectsPageState.Completed:
        return 'Completed';
      case ProjectsPageState.Worklet:
        return getWorkletNameBySpecURI(workletSpecUri, worklets);
      case ProjectsPageState.Task:
        if (isNewWorkletMenuEnabled) {
          return getWorkletNameBySpecURI(workletSpecUri, worklets);
        }
        return task?.taskName;
    }
  }, [isNewWorkletMenuEnabled, pageState, task?.taskName, workletSpecUri, worklets]);

  /**
   * Member search query based on the data
   */
  const memberIds = useMemo(
    () => {
      if (isWorkItemsLoading || !pageId) {
        return [];
      }
      switch (pageId) {
        case ProjectsPageState.AllInProgress:
          return map(workItems, (workItem) => parseInt(workItem.data.memberId, 10));
        case ProjectsPageState.Worklet:
          return map(workItems, (workItem) => parseInt(workItem.data.memberId, 10));
        case ProjectsPageState.Completed:
          return map(completedMembers, (member) => parseInt(member.data.memberId, 10));
        default:
          return reduce(
            workItems,
            (result, workItem) => {
              if (task && task.workletSpecUri === workItem.specURI && task.taskId === workItem.taskId) {
                result.push(parseInt(workItem.data.memberId, 10));
              }
              return result;
            },
            [],
          );
      }
    },
    [completedMembers, isWorkItemsLoading, pageId, task, workItems],
  );

  const membersSearchQuery = useMemo(
    (): IMembersTableSearchQuery => (
      !isEmpty(memberIds) && !isUndefined(isPFAColumnsEnabled) && project?.id
        ? {
          query: {
            includeMemberIds: memberIds,
            joinOnProgramId: project.id,
          },
          includeLastMessage: true,
          includeTags: true,
          includeCommunities: true,
          includePrograms: true,
          includeProgramMemberships: true,
          take: memberIds.length,
          skip: 0,
        }
        : undefined
    ),
    [memberIds, isPFAColumnsEnabled, project?.id],
  );

  const {
    fetchMembers,
    isLoading: isFetchLoading,
    members,
  } = useFetchMembers({ searchQuery: membersSearchQuery }, dynamicQueryFields);

  const { refreshSelectedMembers } = useRefreshSelectedMembers({
    selectedMembers,
    members,
    updateSelectedMembers: setSelectedMembers,
  });

  /**
   * Handlers
   */
  const handleOpenApplication = useCallback((workItem) => {
    if (isApplicationsLoading || !applications) {
      logger.debug('Applications have not loaded yet');
      return;
    }
    if (!task) {
      logger.error('Cannot open application without task');
      return;
    }
    if (!applicationId) {
      logger.error('Cannot open application without task with no service ID');
      return;
    }
    if (isEmpty(applications)) {
      logger.error('There are no applications available');
      return;
    }

    const isBulkAction = !workItem;
    const actionDeepLink = isBulkAction ? task?.taskMetaData?.bulkCTADeepLink : task?.taskMetaData?.singleCTADeepLink;
    try {
      setDeepLinkParameters(JSON.parse(actionDeepLink));
    } catch {
      logger.error(`Unable to parse deeplink parameters for task: ${task.taskName}. Link: ${actionDeepLink}`);
      setDeepLinkParameters(null);
    }

    const workItems = !isEmpty(workItem)
      ? [workItem]
      : selectedWorkItems;
    setAppWorkflowParamters({
      memberIds: map(workItems, (workItem) => workItem.data.memberId),
      programId: project.id,
      workItems,
      taskId: task.taskId,
      workletSpecUri: task.workletSpecUri,
      programName: project.title,
    });
    const membersForAction = !isEmpty(workItem)
      ? [find(members, { id: workItem.data.memberId })]
      : selectedMembers;
    if (TASKS_REQUIRING_EMAIL.includes(task.taskId) && isArray(membersForAction) && !isEmpty(membersForAction)) {
      const membersWithoutEmail = filter(membersForAction, (member) => !member.email);
      if (membersWithoutEmail.length === membersForAction.length) {
        Modal.confirm({
          title: members.length === 1 ? 'Cannot send brief to member' : 'Cannot send brief to members',
          icon: <ExclamationCircleOutlined />,
          content: membersForAction.length === 1
            ? 'The member you selected does not have an email address. Add their email to send a brief.'
            : `${membersForAction.length}/${membersForAction.length} members you selected do not have email addresses. Add their emails to send them a brief.`,
          okText: 'Okay',
          cancelButtonProps: {
            style: {
              display: 'none',
            },
          },
        });
        return;
      } else if (membersWithoutEmail.length > 0) {
        Modal.confirm({
          title: 'Some members are missing email',
          icon: <ExclamationCircleOutlined />,
          content: `${membersWithoutEmail.length}/${membersForAction.length} members you selected do not have email addresses. These members will not be sent a brief.`,
          okText: 'Continue',
          cancelText: 'Cancel',
          onOk: () => {
            history.push({
              ...location,
              pathname: `${match.url}/app/${applicationId}`,
            });
          },
        });
        return;
      }
      history.push({
        ...location,
        pathname: `${match.url}/app/${applicationId}`,
      });
    } else {
      history.push({
        ...location,
        pathname: `${match.url}/app/${applicationId}`,
      });
    }
  }, [isApplicationsLoading, applications, task, project, selectedWorkItems, history, location, match, applicationId, members, selectedMembers]);

  const customCTAAction = useCustomCTA(project?.id, task);

  const onClickSingleCTA = useCallback(async (workItem: TWorkItem) => {
    if (isFunction(customCTAAction)) {
      try {
        await customCTAAction([workItem]);
      } catch (error) {
        logger.error('Error calling custom action', { error });
        showErrorMessage('There was an error when triggering the CTA. Please reach out to support.');
      }
      await refetchData();
    } else {
      handleOpenApplication(workItem);
    }
  }, [customCTAAction, showErrorMessage, handleOpenApplication, refetchData]);

  const handleSelectMembers = useCallback(
    (members) => {
      setSelectedMembers(members);
    },
    [setSelectedMembers],
  );

  useEffect(() => {
    refreshSelectedMembers();
  }, [refreshSelectedMembers]);

  const handleCheckIn = () => {
    toastRef.current?.showMessage({
      type: 'success',
      content: 'Successfully marked as done',
    });
  };

  const handleRowClicked = useCallback((memberId: IMember['id']) => {
    history.push(`${match.url}/member/${memberId}`);
  }, [
    match,
    history,
  ]);

  const handleLastMessageClicked = useCallback(
    (threadId: IThread['id']) => {
      history.push(`${match.url}/threads/${threadId}`);
    },
    [match, history],
  );

  /**
   * Table column config
   */
  const {
    visibleColumnIds,
  } = useWorkItemsTableColumnConfig({
    pageState,
    showInlineCTA: !isEmpty(task?.taskMetaData?.singleCTAText),
    task,
  });

  const workItemsFiltersConfig = useMemo(() => {
    const workItemFilters = [{
      label: 'Owned by Me',
      filter: OWNED_BY_ME_FILTER_VALUE,
    }];

    if (currentStageTitle === CONTENT_STAGES.CONTENT
      || currentStageTitle === CONTENT_STAGES.TRACK_POSTS
      || task?.workletSpecUri === 'TrackPostsWorkletSpecification'
      || task?.workletSpecUri === 'ReviewContentWorkletSpecification') {
      workItemFilters.push({
        label: 'Posts tracked < 7 days',
        filter: LAST_MENTION_DATE_7DAYS_FILTER_VALUE,
      });
    }

    if (
        (currentStageTitle === CONTENT_STAGES.REVIEW_CONTENT || currentStageTitle === CONTENT_STAGES.CONTENT)
        && task?.taskId === 'review_content_task' && project?.gcrEnabled
      ) {
      workItemFilters.push(...ContentReviewFilters);
    }
    return workItemFilters;
  }, [currentStageTitle, task, project]);

  const cellActionsByColumn = useMemo(() => ({
    name: handleRowClicked,
    lastMessage: handleLastMessageClicked,
    cta: onClickSingleCTA,
    task: onTaskSelected,
  }), [
    handleRowClicked,
    handleLastMessageClicked,
    onTaskSelected,
    onClickSingleCTA,
  ]);

  /**
   * Table data
   */
  const mergedTaskDataForWorkItem = (workItem) => {
    const taskDataContainers = [workItem?.data, workItem?.dataUpdated];
    return reduce(taskDataContainers, (mergedData, taskData) => {
      if (taskData) {
        return {
          ...mergedData,
          ...reduce(keys(taskData), (mergedContainerData, key) => {
            if (endsWith(key, '_data')) {
              return {
                ...mergedContainerData,
                ...taskData[key],
              };
            }
            return mergedContainerData;
          }, {}),
        };
      } else {
        return mergedData;
      }
    }, {});
  };

  const workItemsData = useMemo(
    () => {
      if (!isFunction(getTaskBySpecUriAndId)) {
        return undefined;
      }
      const orderCatalogInfoByOrderId = keyBy(creatorsOrderCatalogInfo, 'orderId');
      return map(workItems, (workItem) => {
        const taskData = mergedTaskDataForWorkItem(workItem);
        return {
          ...taskData,
          viewed: workItem.viewed,
          [ColumnKey.ID]: workItem.data.memberId,
          [ColumnKey.CTA]: {
            taskMetaData: task?.taskMetaData,
            workItem,
          },
          [ColumnKey.DateAdded]: workItem?.startTime
            ? moment(workItem.startTime).format('MM/DD/YYYY')
            : undefined,
          [ColumnKey.DaysInStage]: workItem?.startTime
            ? moment().diff(moment(workItem.startTime), 'days')
            : undefined,
          [ColumnKey.TaskName]: getTaskBySpecUriAndId(workItem?.specURI, workItem?.taskId),
          [ColumnKey.WorkletTaskName]: getTaskBySpecUriAndId(workItem?.specURI, workItem?.taskId),
          [ColumnKey.WorkletName]: getTaskBySpecUriAndId(workItem?.specURI, workItem?.taskId),
          ...(task?.workletSpecKey === WorkletSpecKey.PFACreatorProductSelectionV2 ? {
            [ColumnKey.SentCatalog]: {
              id: orderCatalogInfoByOrderId[taskData?.orderId]?.brandCatalogId,
              name: orderCatalogInfoByOrderId[taskData?.orderId]?.brandCatalogName,
            },
            [ColumnKey.SentSelectionRule]: {
              id: orderCatalogInfoByOrderId[taskData?.orderId]?.selectionRuleId,
              name: orderCatalogInfoByOrderId[taskData?.orderId]?.selectionRuleName,
            },
          } : {}),
          _raw: workItem,
          _bodyRowClassName: cx(
            styles.bodyRow,
            { [styles.newWorkItem]: !workItem.viewed && pageState !== ProjectsPageState.AllInProgress },
          ),
        };
      });
    },
    [getTaskBySpecUriAndId, creatorsOrderCatalogInfo, workItems, task?.taskMetaData, task?.workletSpecKey, pageState],
  );

  const completedMembersData = useMemo(
    () => map(completedMembers, (completedMember) => ({
      ...completedMember,
      ...get(completedMember, 'data', {}),
      [ColumnKey.ID]: completedMember.data.memberId,
      [ColumnKey.DateCompleted]: completedMember?.dateCompleted
        ? moment(completedMember.dateCompleted).format('MM/DD/YYYY')
        : undefined,
      _raw: completedMember,
      _bodyRowClassName: styles.bodyRow,
    })),
    [completedMembers],
  );
  const data = isCompletedPage ? completedMembersData : workItemsData;

  const handleFetchMembers = useCallback(() => {
    fetchMembers(membersSearchQuery);
  }, [membersSearchQuery, fetchMembers]);

  const { pluralTermsName } = useGetTermsName();

  /**
   * Loading
   */
  const isLoading = useMemo(() =>
    isApplicationsLoading
    || isFetchingCompletedMembers
    || isWorkItemsLoading
    || isFetchLoading
    || isLoadingPredefinedProgramSegments
    || isLoadingDefaultProgramSegments
    || isLoadingReviews,
    [
      isApplicationsLoading,
      isFetchingCompletedMembers,
      isWorkItemsLoading,
      isFetchLoading,
      isLoadingPredefinedProgramSegments,
      isLoadingDefaultProgramSegments,
      isLoadingReviews,
    ]);

  let renderedGuidelines: React.ReactElement;
  let renderedGuidelinesAlert: React.ReactElement;
  if (
    contentGuidelinesEnabled
    && task?.taskId === WorkflowTask.SEND_TERMS_TASK
    && !otherProjectGuidelinesLoading
  ) {
    if (!isEmpty(otherProjectGuidelinesData?.guidelines?.contentGuidelines)) {
      renderedGuidelines = (
        <Button
          type="ghost"
          onClick={() => history.push({
            ...location,
            pathname: `/settings/${TERMS_APP_ID}`,
            search: `?projectId=${project.id}`,
          })}
        >
          Manage Content Guidelines
        </Button>
      );
    } else {
      renderedGuidelinesAlert = (
        <div
          style={{ margin: '10px' }}
        >
          <Alert
            message={`No content guidelines for this project. Set up content guidelines to quickly send ${pluralTermsName} to members.`}
            type="warning"
            action={(
              <Space>
                <Button
                  size="small"
                  type="ghost"
                  onClick={() => history.push({
                    ...location,
                    pathname: `/settings/${TERMS_APP_ID}`,
                    search: `?projectId=${project.id}`,
                  })}
                >
                  Manage Content Guidelines
                </Button>
              </Space>
            )}
          />
        </div>
      );
    }
  }

  /**
   * Render
   */
  const renderTitle = () => (
    <div className={styles.title}>
      <Space
        direction="vertical"
        size={[32, 32]}
        className={styles.spacer}
      >
        {isArchiveProjectEnabled && renderArchivedNotice}
        <Row
          justify="space-between"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO: Fix in Node upgrade typing bash!
          align="baseline"
        >
          <h1>{currentStageTitle}</h1>
          {renderedGuidelines}
        </Row>
      </Space>
    </div>
  );

  const renderEmptyPlaceholder = (searchText?: string) => {
    if (isLoading || isUndefined(workItems)) return;

    if (!isEmpty(data) && searchText) {
      // return this empty state when user is searching members from search box
      return (
        <div className={styles.empty}>
          <MagnifyingGlassIcon className={styles.searchIcon} />
          <Title level={5}>No members found</Title>
          <Text type="secondary">
            Try adjusting your search. Members can be searched by any of the available columns.
          </Text>
        </div>
      );
    }

    if (isEmpty(data)) {
      // return this empty state when stage has no members
      return (
        <div className={styles.empty}>
          Add people to the project
          <br />
          <a onClick={() => openAddToCollectionModal(ModalType.AddFromGroup)}>from your groups</a>
          &nbsp;or&nbsp;
          <a onClick={() => openAddToCollectionModal(ModalType.AddFromProject)}>from other projects</a>
          .
        </div>
      );
    }

    return <></>;
  };

  const onWorkItemBoxClick = useCallback((workItemBoxTask: ITask, taskId: string) => {
    if (!workItemBoxTask || task?.taskId === taskId) return;
    resetSelection();
    onTaskSelected(workItemBoxTask.workletSpecUri, taskId);
  }, [resetSelection, onTaskSelected, task]);

  const renderWorkItemBoxes = () => {
    if (!isNewWorkletMenuEnabled) return null;

    if (isNil(workletSpecUri) || isNil(counts)) return null;
    if (pageState !== ProjectsPageState.Task && pageState !== ProjectsPageState.Worklet) return null;

    if (workletSpecUri.includes('CustomWorklet-')) return null;
    const selectedTasks = tasks ? tasks.filter((t) => t.workletSpecUri === workletSpecUri) : [];
    const countsPerWorklet = counts?.[workletSpecUri];
    let total = 0;
    if (!isNil(countsPerWorklet)) {
      const keysPerWorklet = keys(countsPerWorklet) ?? [];
      total = reduce(keysPerWorklet.map((key) => countsPerWorklet[key].total), function (sum, n) {
        return sum + n;
      }, 0);
    }
    return (
      <div className={styles.workItemBoxContainer}>
        <WorkItemItemBox
          count={total}
          onClick={() => onWorkItemBoxClick(task, undefined)}
          key={`${workletSpecUri}-In Stage`}
          name="In Stage"
          isActive={pageState === ProjectsPageState.Worklet}
          hasError={false}
          hasNotification={false}
          isTransient={false}
          taskUI={undefined}
        />
        {
          selectedTasks.map((t) => (
            <WorkItemItemBox
              count={counts?.[t.workletSpecUri]?.[t.taskId]?.total}
              onClick={() => onWorkItemBoxClick(t, t.taskId)}
              key={t.taskName}
              name={t.taskName}
              isActive={t.taskId === task?.taskId}
              hasError={hasError(t, counts?.[t.workletSpecUri]?.[t.taskId]?.total)}
              hasNotification={hasNotification(t, counts?.[t.workletSpecUri]?.[t.taskId]?.total)}
              isTransient={isTransient(t)}
              taskUI={parseToITaskUI(t)}
            />
          ))
        }
      </div>
    );
  };

  const count = useMemo(
    () => {
      if (!isEmpty(selectedMembers)) {
        return selectedMembers.length;
      }
      switch (pageState) {
        case ProjectsPageState.AllInProgress:
          return get(counts, 'all_in_progress', 0);
        case ProjectsPageState.Task:
          if (isNil(task)) {
            return 0;
          }
          const taskTotal = counts?.[task.workletSpecUri]?.[task.taskId]?.total;
          return taskTotal || 0;
        case ProjectsPageState.Worklet:
          if (isNil(counts) || isNil(workletSpecUri)) return 0;

          const countsPerWorklet = counts?.[workletSpecUri];
          if (isNil(countsPerWorklet)) return 0;

          const keysPerWorklet = keys(countsPerWorklet) ?? [];
          return reduce(keysPerWorklet.map((key) => countsPerWorklet[key].total), function (sum, n) {
            return sum + n;
          }, 0);
        case ProjectsPageState.Completed:
          return get(counts, 'completed', 0);
      }
    },
    [counts, pageState, selectedMembers, task, workletSpecUri],
  );

  const handleBack = useCallback(() => {
    history.push({
      ...location,
      pathname: match.url,
    });
  }, [match.url, history, location]);

  const renderHeaderActions = () => (
    <TableHeader
      buttons={{
        applications: false,
        columns: false,
        compose: true,
        ctaBulkAction: true,
        cta: true,
        refresh: true,
        tags: true,
        delete: false,
      }}
      disabledButtons={{
        applications: isLoading,
        compose: isLoading,
        cta: isLoading,
        refresh: isLoading,
        tags: isLoading,
      }}
      count={selectedMembers?.length ? count : displayedRowCount}
      countSuffix={selectedMembers?.length ? 'selected' : undefined}
      onCheckIn={handleCheckIn}
      onOpenApplication={handleOpenApplication}
      openAddToCollectionModal={openAddToCollectionModal}
      pageState={pageState}
      project={project}
      refetchData={refetchData}
      selectedMemberIds={map(selectedMembers, (member) => member.id)}
      selectedMembers={selectedMembers}
      selectedWorkItems={selectedWorkItems}
      selectedWorkItemIds={selectedWorkItemIds}
      task={task}
      worklets={worklets}
      conditions={conditions}
      showSelectOffer={showSelectOffer}
      migrateToGraphQL={migrateToGraphQL}
      offers={offers}
    />
  );

  useEffect(() => {
    setSelectedQuickFilter('');
  }, [currentStageTitle, task?.taskId]);

  const handleSelectQuickFilter = (newQuickFilter: string) => {
    resetSelection();
    if (newQuickFilter === selectedQuickFilter) {
      setSelectedQuickFilter('');
    } else {
      setSelectedQuickFilter(newQuickFilter);
    }
  };
  useEffect(() => {
  if ([InternalContentReviewStatus.SOFT_APPROVED].includes(selectedQuickFilter as InternalContentReviewStatus)) {
    refetchFilteredGCRMemberIds({
      programId: project?.id,
      statuses: [InternalContentReviewStatus.SOFT_APPROVED, InternalContentReviewStatus.SOFT_REJECTED],
    });
  }
  if ([InternalContentReviewStatus.SUBMITTED].includes(selectedQuickFilter as InternalContentReviewStatus)) {
    refetchFilteredGCRMemberIds({
      programId: project?.id,
      statuses: [selectedQuickFilter as InternalContentReviewStatus],
    });
  }
}, [selectedQuickFilter, refetchFilteredGCRMemberIds, project?.id]);

  const filteredMembers = useMemo(() => {
    if (isLoadingReviews || task?.taskId !== 'review_content_task') {
      return members;
    }
    if ([InternalContentReviewStatus.SOFT_APPROVED, InternalContentReviewStatus.SUBMITTED].includes(selectedQuickFilter as InternalContentReviewStatus)) {
      return members?.filter((member) => (filteredGCRMemberIds.includes(member.id)));
    } else {
      return members;
    }
  }, [
    members,
    filteredGCRMemberIds,
    selectedQuickFilter,
    isLoadingReviews,
    task?.taskId,
  ]);
  const customTaskNotice = useCustomTaskNotice(project?.id, task, memberIds, project?.title);
  if (offers && offers.length > 0) {
    const [firstOffer] = offers || [];
    const [firstPromo] = firstOffer?.promos || [];
    const affiliates = firstPromo?.affiliates;
    if (affiliates && affiliates.length > 0) {
      data.forEach((member) => {
        const memberId = member.id;
        const affiliate = affiliates.find((a) => a.affiliate.memberId === memberId);
        if (affiliate) {
          member.promoCode = affiliate.externalDiscountCode;
        }
      });
    }
  }
  const renderTable = () => (
    <MemberListContextProvider programId={project?.id}>
      <div ref={tableContainerRef} className={styles.tableWrapper}>
        <MembersTable
          className={cx(
            styles.membersTable,
            { [styles.loading]: isLoading },
          )}
          project={project}
          projectSpecKey={project?.specKey}
          data={data}
          onSelectedDataChange={handleSelectMembers}
          refetchCounts={refetchCounts}
          selectedMembers={selectedMembers}
          containerHeight={height}
          cellActionsByColumn={cellActionsByColumn}
          renderEmptyPlaceholder={renderEmptyPlaceholder}
          renderHeaderActions={renderHeaderActions}
          searchQuery={membersSearchQuery}
          visibleColumnIds={visibleColumnIds}
          worklets={worklets}
          task={task}
          counts={counts}
          config={{
            allowSearch: true,
            pageSize: 200,
          }}
          setDisplayedTotalRowCount={setDisplayedRowCount}
          selectedQuickFilter={selectedQuickFilter}
          fetchMembers={fetchMembers}
          members={filteredMembers}
          pageId={pageId}
          workletSpecUri={workletSpecUri}
          savedColumns={savedColumns as IPredefinedSegment}
          defaultSavedColumns={defaultSavedColumns as IPredefinedSegment}
          isLoading={isLoading}
          isLoadingPredefinedProgramSegments={isLoadingPredefinedProgramSegments}
          onRefetchSegments={handleRefetchSegments}
        />
      </div>
    </MemberListContextProvider>
  );

  return (
    <div
      className={cx(
        styles.ListDrawer,
        className,
        { [styles.loading]: isLoading },
      )}
    >
      {/* TODO: only show drawer when member details isn't open */}
      <Drawer
        className={styles.drawer}
        expanded
        hideToggle
      >
        {renderTitle()}
        {renderWorkItemBoxes()}
        <Space
          style={{ marginTop: '0.5rem', padding: '0 2rem' }}
        >
          <QuickFilters
            onSelect={handleSelectQuickFilter}
            selectedFilter={selectedQuickFilter}
            hideFiltersCounts
            quickFiltersOptions={workItemsFiltersConfig}
          />
        </Space>
        <Divider className={styles.divider} />
        {renderedGuidelinesAlert}
        <div className={styles.customTaskNotice}>
          {customTaskNotice}
        </div>
        {renderTable()}
      </Drawer>

      <section className={styles.content}>
        <Switch>
          {/* TODO: figure out why match.path doesn't work */}
          <Route path="/projects/:projectId/:workletSpecUri/:taskId/app/:appId">
            {applications && appWorkflowParameters && (
              <ApplicationDrawer
                application={applications[0]}
                deepLinkParameters={deepLinkParameters}
                memberId={null}
                workflowActionParameters={appWorkflowParameters}
                onApplicationClose={async () => {
                  refetchData();
                }}
                paymentAppPaddingEnabled={isBudgetAllocationEnabled && appWorkflowParameters?.taskId === Task.SendPaymentTask}
              />
            )}
          </Route>
          <Route
            path={[
              '/projects/:projectId/:workletSpecUri/:taskId/member/:memberId',
              '/projects/:projectId/:workletSpecUri/member/:memberId',
            ]}
            render={(routeProps) => (
              <Drawer
                className={cx(styles.drawer, styles.memberDetailsDrawer)}
                expanded
                hideToggle
              >
                {project && (
                  <FetchContextProvider>
                    <NewMemberDetailContainer
                      isInSentTermsStage={applicationId === TERMS_APP_ID}
                      memberId={parseInt(routeProps.match.params.memberId, 10)}
                      disabledApplicationIds={[applicationId]}
                      projectId={project.id}
                      refetchMembers={handleFetchMembers}
                      sendTermsAction={() => handleOpenApplication(workItemByMemberId[routeProps.match.params.memberId])}
                      onBackButtonClicked={handleBack}
                      isDeliverablesHidden={false}
                    />
                  </FetchContextProvider>
                )}
              </Drawer>
            )}
          />
          <Route
            path={[
              '/projects/:projectId/:workletSpecUri/:taskId/threads/:threadId',
              '/projects/:projectId/:workletSpecUri/threads/:threadId',
            ]}
            render={(routeProps) => (
              <Drawer
                className={cx(styles.drawer, styles.memberDetailsDrawer)}
                expanded
                hideToggle
              >
                <CommunitySwitcherProvider useQueryParams={false}>
                  <MessagingProvider>
                    <MessageList
                      threadId={routeProps.match.params.threadId}
                      hideAdditionalActions
                      onBackButtonClicked={() => {
                        history.goBack();
                      }}
                      fetchMembers={handleFetchMembers}
                    />
                  </MessagingProvider>
                </CommunitySwitcherProvider>
              </Drawer>
            )}
          />
        </Switch>
      </section>
      <Toast ref={toastRef} />
      <ShopifyReauthenticateModal />
    </div>
  );
});
