import * as React from 'react';
import {
  filter,
  map,
  some,
  toString,
  endsWith,
  toLower,
  isEmpty,
} from 'lodash';
import { useHistory } from 'react-router-dom';
import Helmet from 'react-helmet';

import { OverlaySpinner } from '@components';
import { EventName } from '@common';

import { ClientFeature, TABLE_PAGE_SIZE } from '@frontend/app/constants';
import { MemberApplicantOperation } from '@frontend/app/types/globalTypes';
import { ProgramMembershipStatusType } from '@frontend/app/types/MemberSearch';
import { useEventContext } from '@frontend/app/context/EventContext';
import {
  useAllSegmentId,
  CommunitySwitcherProvider,
  useCommunitiesQuery,
  useCommunitySwitcherContext,
  useClientFeatureEnabled,
} from '@frontend/app/hooks';

import { useRefreshSelectedMembers } from '@frontend/app/hooks';
import { SourcingGroupId, sourcingGroups } from '@frontend/app/constants/sourcingGroups';
import { segmentFilterCount } from '@frontend/app/utils/segments';
import {
  MemberTable,
  IMemberTableRefHandles,
  IFilter,
} from '@frontend/app/components';
import { useOrderedMemberColumns } from '@frontend/app/hooks/memberList';
import { renderMemberNameCell } from '@frontend/app/components/MemberTable/CustomCells/MemberNameCell';
import { defaultRenderCell } from '@frontend/app/components/MemberTable/utils/defaultRenderCell';
import { MemberSearchQuery_members as IMember } from '@frontend/app/queries/types/MemberSearchQuery';
import { IColumn } from '@frontend/app/types/Columns';

import {
  MemberListContextProvider,
  useMemberListContext,
} from '@frontend/app/context/MemberListContext';
import { IgdmInviteBanner } from '@frontend/utils';
import { GetCommunitiesQuery_communities } from '@frontend/app/queries/types/GetCommunitiesQuery';
import { ALL_CONTACTS_IMAGE_URL } from '@frontend/app/constants/imageUrls';
import { AppHeader } from '@frontend/app/refresh-components/AppHeader';
import { MemberFilterList } from './MemberFilterList';
import { SegmentFolderList } from './SegmentFolders/SegmentFolderList';
import { PageHeader } from './PageHeader/PageHeader';

import { MemberPageContextProvider } from './context/MemberPageContext';
import { CSVUploadContextProvider } from './context/CSVUploadContext';

import { useMemberPageContext, useRefetchSegments } from './hooks';
import { ListHeader } from './ListHeader/ListHeader';
import { MemberDrawer } from './MemberDrawer';

import {
  CommunityEmptyState,
  ECommerceEmptyState,
  SocialListeningEmptyState,
} from './EmptyListStates';

import { useMemberApplicantOperationMutation } from './hooks/useMemberApplicantOperationMutation';
import { useMemberSearchMemberApplicantOperationMutation } from './hooks/useMemberSearchMemberApplicantOperationMutation';

import styles from './MemberPage.scss';
import { LastSynced } from './PageHeader/LastSynced/LastSynced';
import { CsvDropdown } from './PageHeader/CsvDropdown/CsvDropdown';
import { SettingsButton } from './PageHeader/SettingsButton/SettingsButton';
import { AddMemberButton } from './PageHeader/AddMemberButton/AddMemberButton';

const {
  useMemo, useEffect, useState, useCallback, useRef,
} = React;

interface IProps {
  isContact?: boolean;
}

const MemberPage: React.FunctionComponent<IProps> = React.memo((props: IProps) => {
  const {
    searchQuery,
    columns: columnsContext,
    columnVisibility,
    segment,
    filters,
    segmentFilters,
    updateFilters,
    updateSortDirection,
    updateSearchText,
  } = useMemberListContext();
  const {
    communityId,
    sourcingGroupId,
    programId: selectedProgramId,
  } = useMemberPageContext();

  const memberTableRef = useRef<IMemberTableRefHandles>();

  const [matchingMembers, setMatchingMembers] = useState<number>();
  const [members, setMembers] = useState<IMember[]>();
  const [selectedMembers, setSelectedMembers] = useState<IMember[]>();
  const { refreshSelectedMembers } = useRefreshSelectedMembers({
    selectedMembers,
    members,
    updateSelectedMembers: setSelectedMembers,
  });
  const [selectedCommunity, setSelectedCommunity] = useState<GetCommunitiesQuery_communities>();
  const refreshUi = useClientFeatureEnabled(ClientFeature.REFRESH_UI);
  const isMemberDetailPage = useMemo(() => {
    const pathSegments = location.pathname.split('/');
    return pathSegments.includes('member');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  const { refetchSegments } = useRefetchSegments({
    isContact: props.isContact,
  });

  useEffect(() => {
    refreshSelectedMembers();
  }, [refreshSelectedMembers]);

  const columns = useOrderedMemberColumns(columnsContext, segment);

  const handleSortChange = (column: string, sortDir: 'ASC' | 'DESC') => {
    updateSortDirection({ columnKey: column, order: sortDir });
  };

  const { data: { communities = undefined } = {} } = useCommunitiesQuery();

  const [memberApplicantOperation, {
    loading: isMakingApplicantOperation,
  }] = useMemberApplicantOperationMutation(communityId);

  const handleFiltersChange = useCallback((updatedFilters: IFilter[]) => {
    setSelectedMembers([]);
    updateFilters(updatedFilters);
  }, [updateFilters]);

  const handleMemberApplicantOperation = useCallback(async (memberId: number, operation: MemberApplicantOperation) => {
    await memberApplicantOperation({
      variables: {
        id: memberId,
        programId: selectedProgramId,
        operation,
      },
    });
  }, [selectedProgramId, memberApplicantOperation]);

  const [memberSearchMemberApplicantOperation, {
    loading: isMakingBulkApplicantOperation,
  }] = useMemberSearchMemberApplicantOperationMutation(communityId);

  const handleMemberSearchMemberApplicantOperation = async (operation: MemberApplicantOperation) => {
    if (!confirm(`Are you sure you want to ${toLower(operation)} all?`)) {
      return;
    }

    await memberSearchMemberApplicantOperation({
      variables: {
        operation,
        query: searchQuery,
        programId: selectedProgramId,
      },
    });
  };

  const loading = (
    isMakingApplicantOperation
    || isMakingBulkApplicantOperation
  );

  const isApplicantList = searchQuery && searchQuery.programStatus === ProgramMembershipStatusType.NEW;

  const segmentType = useMemo(() => {
    const predefSegmentNames = ['program', 'requirements', 'important', 'all', 'applicants', 'overdue_requirements'];
    if (segment && predefSegmentNames.includes(segment.title?.toLowerCase())) {
      return segment.title.toLowerCase();
    }
    return 'custom';
  }, [segment]);

  const addEvent = useEventContext();

  useEffect(() => {
    if (communities && segment) {
      const group = filter(communities, (community) => community.id === communityId);
      setSelectedCommunity(group[0]);
      const groupName = (() => {
        if (sourcingGroupId) {
          return sourcingGroupId;
        } else if (group[0]) {
          return group[0].title;
        }
        return 'all people';
      })();

      const groupType = (() => {
        if (sourcingGroupId) {
          return 'sourcing';
        } else if (group[0]) {
          return 'user created';
        }
        return 'all people';
      })();

      addEvent(EventName.OpenSegment, {
        member_count: segment.memberCount,
        filter_count: segmentFilterCount(segment.filter),
        segment_type: segmentType,
        segment_name: segment.title.toLowerCase(),
        group_name: groupName.toLowerCase(),
        group_type: groupType.toLowerCase(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communities, communityId, sourcingGroupId, segment]);

  useEffect(() => {
    if (communities) {
      const group = filter(communities, (community) => community.id === communityId);

      const groupName = (() => {
        if (sourcingGroupId) {
          return sourcingGroupId;
        } else if (group[0]) {
          return group[0].title;
        }
        return 'all people';
      })();

      const groupType = (() => {
        if (sourcingGroupId) {
          return 'sourcing';
        } else if (group[0]) {
          return 'user created';
        }
        return 'all people';
      })();

      addEvent(EventName.ViewGroupInMemberList, {
        groupName: groupName.toLowerCase(),
        groupType: groupType.toLowerCase(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communities, communityId, sourcingGroupId]);

  const isPredefinedSegment = segment && segment.__typename === 'PredefinedSegment';

  const allSegmentId = useAllSegmentId(communityId, sourcingGroupId);

  const renderEmptyState = useMemo(() => {
    const segmentID = toString(segment?.id);
    const isSourcingGroupSegment = some(sourcingGroups, (group) => endsWith(segmentID, `_${group.id}`));

    if (segment?.id !== allSegmentId && !isSourcingGroupSegment) {
      return;
    }

    if (sourcingGroupId === SourcingGroupId.SOCIAL_LISTENING) {
      const SocialListeningEmptyStateComponent = () => (
        <SocialListeningEmptyState />
      );
      SocialListeningEmptyStateComponent.displayName = 'SocialListeningEmptyStateComponent';
      return SocialListeningEmptyStateComponent;
    } else if (sourcingGroupId) {
      const ECommerceEmptyStateComponent = () => (
        <ECommerceEmptyState />
      );
      ECommerceEmptyStateComponent.displayName = 'ECommerceEmptyStateComponent';
      return ECommerceEmptyStateComponent;
    } else {
      const CommunityEmptyStateComponent = () => (
        <CommunityEmptyState />
      );
      CommunityEmptyStateComponent.displayName = 'CommunityEmptyStateComponent';
      return CommunityEmptyStateComponent;
    }
  }, [sourcingGroupId, allSegmentId, segment]);

  const refetchData = useCallback(() => {
    refetchSegments();
    return memberTableRef.current?.refetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberTableRef]);

  const history = useHistory();
  const onClickName = (id: number) => {
    history.push(`/member_table/member/${id}${history.location.search}`);
  };

  const customRenderCell = useCallback((column: IColumn) => {
    switch (column.field) {
      case 'name': {
        return {
          ...column,
          grow: true,
          width: 300,
          minWidth: 200,
          maxWidth: 400,
          render: (data, context) => renderMemberNameCell(data, context, isApplicantList ? handleMemberApplicantOperation : null, onClickName),
          lockPosition: true,
        };
      }

      default:
        return defaultRenderCell(column);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApplicantList, handleMemberApplicantOperation]);

  const onDeleteMembers = async () => {
    await refetchData();
  };

  const selectedMemberIds = useMemo(() =>
    map(selectedMembers, (member) => member.id),
    [selectedMembers]);

  return (
    <CSVUploadContextProvider onComplete={refetchData}>
      {!isEmpty(searchQuery) && (
        <div className={styles.MemberPage}>
          {(refreshUi && !isMemberDetailPage) && (
            <AppHeader
              title="All Contacts"
              subTitle={selectedCommunity?.title || 'All'}
              imgIconUrl={selectedCommunity?.splashImageUrl || ALL_CONTACTS_IMAGE_URL}
              actions={[
                sourcingGroupId && (
                  <LastSynced
                    sourcingGroupId={sourcingGroupId}
                  />
                ),
                <CsvDropdown
                  segmentType={segmentType}
                  memberCount={matchingMembers}
                  selectedMemberIds={selectedMemberIds}
                  refreshUi={refreshUi}
                  key={2}
                />,
                <SettingsButton refreshUi={refreshUi} key={1} />,
                !isApplicantList && <AddMemberButton refreshUi={refreshUi} />,
              ]}
            />
          )}
          <SegmentFolderList
            isContact={props.isContact}
            communityId={communityId}
            sourcingGroupId={sourcingGroupId}
            selectedSegmentIdentifier={segment && segment.id}
            className={styles.folders}
            setSelectedMembers={setSelectedMembers}
          />
          <div className={styles.right}>
            <IgdmInviteBanner
              paddings={[16, 20]}
              ctaSource="memberPage"
            />
            {!refreshUi && (
              <PageHeader
                segmentType={segmentType}
                memberQueryCount={matchingMembers}
                selectedMemberIds={selectedMemberIds}
              />
            )}
            <MemberFilterList
              communityId={communityId}
              sourcingGroupId={sourcingGroupId}
              filters={filters}
              isApplicantList={isApplicantList}
              isPredefinedSegment={isPredefinedSegment}
              onChangeFilters={handleFiltersChange}
              segment={segment}
              segmentFilters={segmentFilters}
            />
            <div className={styles.memberListDivider} />
            <MemberTable
              ref={memberTableRef}
              key={segment?.id}
              className={styles.memberList}
              columns={columns}
              columnVisibility={columnVisibility}
              renderEmptyState={renderEmptyState}
              renderCell={customRenderCell}
              searchQuery={searchQuery}
              initialSelectedMemberIds={selectedMemberIds}
              onSortDirChange={handleSortChange}
              onSearchTextChange={updateSearchText}
              onChangeMatchingMembers={setMatchingMembers}
              selectedMembers={selectedMembers}
              onSelectedMembersChange={setSelectedMembers}
              onMembersUpdated={setMembers}
              pageSize={TABLE_PAGE_SIZE}
              segmentId={segment?.id}
              segmentType={isPredefinedSegment ? 'predefined' : 'custom'}
              showMembersChangedNotice
              enableColumnReordering
              showSelectAllCheckbox
              showCheckboxColumn
              allowSearch
              headerActions={(
                <ListHeader
                  matchingMembers={matchingMembers}
                  selectedMembers={selectedMembers}
                  isApplicantList={isApplicantList}
                  onClickApproveAll={handleMemberSearchMemberApplicantOperation.bind(this, MemberApplicantOperation.Approve)}
                  onClickRejectAll={handleMemberSearchMemberApplicantOperation.bind(this, MemberApplicantOperation.Reject)}
                  onDelete={onDeleteMembers}
                  segmentType={segmentType}
                />
              )}
            />
            {loading && <OverlaySpinner />}
            <MemberDrawer />
          </div>
        </div>
      )}
    </CSVUploadContextProvider>
  );
});

MemberPage.displayName = 'MemberPage';

const MemberPageWrapper = () => {
  const { selectedCommunityId, selectedSourcingId } = useCommunitySwitcherContext();

  const pageTitle = useMemo(() => {
    const title = 'Members';
    if (!selectedSourcingId) return title;

    const sourcingGroup = filter(sourcingGroups, (group) => group.id === selectedSourcingId)[0];
    return sourcingGroup ? `${title} | ${sourcingGroup.title}` : title;
  }, [selectedSourcingId]);

  // we only want members with isContact=true when no sourcing group selected
  const isContact = useMemo(
    () => (!selectedSourcingId ? true : undefined),
    [selectedSourcingId],
  );

  return (
    <MemberListContextProvider
      communityId={selectedCommunityId}
      sourcingGroupId={selectedSourcingId}
      isContact={isContact}
    >
      <Helmet title={pageTitle} />
      <MemberPageContextProvider>
        <MemberPage isContact={isContact} />
      </MemberPageContextProvider>
    </MemberListContextProvider>
  );
};

const MemberPageMemoized = React.memo(() => (
  <CommunitySwitcherProvider>
    <MemberPageWrapper />
  </CommunitySwitcherProvider>
));

MemberPageMemoized.displayName = 'MemberPageMemoized';

export default MemberPageMemoized;
