import React, { useMemo } from 'react';
import { SecondaryMetric, WidgetContainer, ProgressBarWithLegends } from '@frontend/app/refresh-components';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { WalletIcon } from '@revfluence/fresh-icons/solid/esm';
import { useGetBudgetDashboardBudgetTabSummaryQuery } from '../hooks';

interface TotalBudgetWidgetProps {
  budgetId?: number;
  fiscalYear?: string;
  className?: string;
}

export default function TotalBudgetWidget(props: TotalBudgetWidgetProps) {
  const { budgetId, fiscalYear, className } = props;
  const isParemetersAbsent = !budgetId || !fiscalYear;
  const { data: budgetSummary, loading } = useGetBudgetDashboardBudgetTabSummaryQuery({
    variables: {
      budgetId,
      fiscalYear,
    },
    skip: isParemetersAbsent,
  });

  const legend = useMemo(() => [
    { label: 'Used', color: 'hsl(var(--chart-2))', percent: budgetSummary?.summary?.totalBudget?.usedPercent || 0 },
    { label: 'Remaining', color: '#dadada', percent: budgetSummary?.summary?.totalBudget?.availablePercent || 0 },
  ], [budgetSummary]);

  const missingParametersContent = (
    <div className="w-full h-full flex justify-center items-center">
      <p>No data found</p>
    </div>
  );
  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const mainContent = (
    <div className="flex flex-col justify-around h-full">
      <div className="flex justify-between">
        <SecondaryMetric
          value={budgetSummary?.summary?.totalBudget?.totalAmount || 0}
          size="large"
          metricType="currency"
        />
        <SecondaryMetric
          value={budgetSummary?.summary?.totalBudget?.availableAmount || 0}
          size="small"
          metricType="currency"
          heading="Budget Remaining"
        />
      </div>
      <div className="mt-3">
        <ProgressBarWithLegends edge="square" legend={legend} height={15} />
      </div>
    </div>
  );
  return (
    <WidgetContainer
      widgetTitle="Total Budget"
      className={`${className}`}
      bgColor="bg-[#003F5B]"
      widgetIcon={<WalletIcon className="text-white" fontSize={20} />}
    >
      {isParemetersAbsent ? missingParametersContent : loading ? loadingContent : mainContent}
    </WidgetContainer>
  );
}
