/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_11cf6_157 {
  display: flex;
}

._justify-content-space-between_11cf6_161 {
  justify-content: space-between;
}

._tabular-nums_11cf6_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_11cf6_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ManageOfferDrawer_11cf6_178 .ant-drawer-body {
  padding: 0;
}
._ManageOfferDrawer_11cf6_178 ._header_11cf6_181 {
  position: absolute;
  left: 1.5rem;
  display: flex;
  align-items: center;
}
._ManageOfferDrawer_11cf6_178 ._offerDetails_11cf6_187 {
  border-bottom: 0.0625rem solid #f0f0f0;
  padding: 1rem 1.5rem;
}
._ManageOfferDrawer_11cf6_178 ._offerDetails_11cf6_187 .ant-avatar {
  margin-right: 0.5rem;
}
._ManageOfferDrawer_11cf6_178 ._offerDetails_11cf6_187 .ant-tag {
  border-radius: 0.375rem;
}
._ManageOfferDrawer_11cf6_178 ._offerDetails_11cf6_187 ._offerName_11cf6_197 {
  margin-right: 0.5rem;
  margin-bottom: 0;
}
._ManageOfferDrawer_11cf6_178 ._offerDetails_11cf6_187 ._img_11cf6_201 {
  object-fit: cover;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-right: 0.5rem;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferwrapper_11cf6_209 {
  padding: 1.5rem;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferwrapper_11cf6_209 ._manageOfferButtons_11cf6_212 {
  margin-top: 1.125rem;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferwrapper_11cf6_209 ._manageOfferButtons_11cf6_212 .ant-btn {
  height: auto;
  width: 100%;
  border: none;
  text-align: unset;
  padding: 0px;
  font-weight: initial;
  margin-bottom: 0.5rem;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferwrapper_11cf6_209 ._manageOfferButtons_11cf6_212 ._buttonsAction_11cf6_224 {
  padding: 1rem;
  border: 0.0625rem solid rgb(240, 240, 240);
  border-radius: 0.375rem;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferwrapper_11cf6_209 ._manageOfferButtons_11cf6_212 ._buttonsAction_11cf6_224 .ant-avatar {
  background-color: #006462;
  vertical-align: middle;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferwrapper_11cf6_209 ._manageOfferButtons_11cf6_212 ._buttonsAction_11cf6_224 ._buttonTitle_11cf6_233 {
  margin-left: 0.75rem;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferwrapper_11cf6_209 ._manageOfferButtons_11cf6_212 ._buttonsAction_11cf6_224 ._buttonTitle_11cf6_233 .ant-typography {
  margin-right: 0.5rem;
  margin-bottom: 0;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferwrapper_11cf6_209 ._manageOfferButtons_11cf6_212 .ant-alert {
  text-wrap: wrap;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferwrapper_11cf6_209 ._img_11cf6_201 {
  object-fit: cover;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-right: 0.5rem;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferwrapper_11cf6_209 ._mb0_11cf6_251 {
  margin-bottom: 0;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferContainer_11cf6_254 {
  padding: 1.5rem;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferContainer_11cf6_254 .ant-typography {
  margin-right: 0.5rem;
  margin-bottom: 0;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferContainer_11cf6_254 .ant-select {
  width: 100%;
  margin-bottom: 0.5rem;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferContainer_11cf6_254 .ant-collapse {
  margin-top: 2rem;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferContainer_11cf6_254 ._boldText_11cf6_268 {
  font-size: 1rem;
  font-weight: 600;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferContainer_11cf6_254 ._memberCount_11cf6_272 {
  color: #505256;
  font-weight: 400;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferContainer_11cf6_254 ._ManageOfferAction_11cf6_276 {
  padding: 1rem;
  border: 0.0625rem solid rgb(240, 240, 240);
  border-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferContainer_11cf6_254 ._customInputBox_11cf6_284 {
  border-radius: 0.375rem;
  border: 0.0625rem solid rgb(217, 217, 217);
  background: rgb(255, 255, 255);
}
._ManageOfferDrawer_11cf6_178 ._manageOfferContainer_11cf6_254 ._customInputBox_11cf6_284 input {
  text-transform: uppercase;
  border: 0.0625rem solid rgb(217, 217, 217);
}
._ManageOfferDrawer_11cf6_178 ._manageOfferContainer_11cf6_254 ._customInputBox_11cf6_284 ._tooltipError_11cf6_293 {
  position: absolute;
  right: 1.75rem;
  top: 1.3125rem;
  color: #F1515F;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferContainer_11cf6_254 ._customInputBoxError_11cf6_299 {
  color: #F1515F;
  font-size: 0.75rem;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferContainer_11cf6_254 ._memberCount_11cf6_272 {
  margin-top: 1rem;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferContainer_11cf6_254 ._activeDateContainer_11cf6_306 {
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferContainer_11cf6_254 ._activeDateContainer_11cf6_306 ._card_11cf6_311 {
  width: 100%;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferContainer_11cf6_254 ._activeDateContainer_11cf6_306 ._dateHeader_11cf6_314 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferContainer_11cf6_254 ._activeDateContainer_11cf6_306 ._dateContainer_11cf6_320 {
  width: 100%;
}
._ManageOfferDrawer_11cf6_178 ._manageOfferContainer_11cf6_254 ._activeDateContainer_11cf6_306 ._dateInput_11cf6_323 {
  border-color: none;
  width: 100%;
}
._ManageOfferDrawer_11cf6_178 ._noticeContainer_11cf6_327 {
  margin-bottom: 12px;
}
._ManageOfferDrawer_11cf6_178 ._noticeContainer_11cf6_327 .ant-typography {
  color: #F1515F;
}
._ManageOfferDrawer_11cf6_178 ._noticeContainer_11cf6_327 ._iconWrapper_11cf6_333 span {
  color: #F1515F;
  width: 100%;
  font-size: 21px;
}

._defaultLabel_11cf6_339 {
  background-color: #C9F2F2;
  color: #1F1211;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 400;
}

._collapseBg_11cf6_347 {
  background-color: white;
}
._collapseBg_11cf6_347 .ant-collapse-content-box {
  background-color: white;
  padding: 8px;
}