import * as React from 'react';
import {
  isNil, isEmpty, map, find, toNumber, sortBy, every, isEqual, toPairs, values,
} from 'lodash';

import { LoadSpinner } from '@components';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useAuth } from '@frontend/context/authContext';
import { useMessagingContext } from '@frontend/hooks';

import { SendMessageInput } from '@frontend/app/types/globalTypes';
import { Alert, message } from '@revfluence/fresh';

import { ApolloError } from '@apollo/client';

import { useGetSelectionCriteriaById } from '@frontend/app/containers/Settings/ProductFulfillment/Catalogs/hooks/useGetSelectionCriteriaById';
import {
  IOrderById,
  IProduct,
  IResource,
  useApproveOrderRequest,
  useGetOrderById,
  useGetProductsV3,
  useGetResources,
  useGetShopifyProjectConfig,
  useGetVariantsByIds,
  useRejectOrderRequest,
} from '../../hooks';
import { useProductFulfillmentContext } from '../../context';
import {
  OrderRequestReview as _OrderRequestReview,
} from '../components/OrderRequestReview/OrderRequestReview';
import { ICurrentRequest, ISelectedVariant, IOrderRequestStatus } from '../components/OrderRequestReview/utils';

import getInitialEmailState from '../utils/initialEmailStateOrderRequestReview';
import { getOrderIdFromWorkItem, getAddressString, Address } from '../../utils';
import { GetProductDetailsQuery_productDetail } from '../../queries/types/GetProductDetailsQuery';
import { ProjectConfigType } from '../../types/globalTypes';

const {
  useCallback, useState, useEffect, useMemo,
} = React;

const getOrderRequestVariantIds = (order: IOrderById | null): number[] => {
  if (!order) {
    return [];
  }
  const lineItems = order.creatorOrderRequest?.line_items;
  if (isEmpty(lineItems)) {
    return [];
  }

  return map(lineItems, (lineItem) => lineItem.variant_id);
};

const getLineItemsFromCurrentRequest = (currentRequest: ICurrentRequest): {
  variant_id: number;
  quantity: number;
}[] => {
  const currentRequestLineItems = [];
  for (const [variantId, item] of toPairs(currentRequest)) {
    if (!item.removed) {
      currentRequestLineItems.push({
        variant_id: toNumber(variantId),
        quantity: item.quantity,
      });
    }
  }
  return currentRequestLineItems;
};

interface ErrorCode {
  extensions: {
    code: string;
  }
}

export const OrderRequestReview: React.FC = () => {
  const { user, clientInfo } = useAuth();
  const { closeModal, workflowActionParameters } = useApplication();
  const { showSuccessMessage, showErrorMessage } = useMessagingContext();

  const {
    allMembers,
    workItems,
  } = useProductFulfillmentContext();

  const workItem = workItems[0];
  const orderId = getOrderIdFromWorkItem(workItem);

  // Product Search
  const [showProductSearch, setShowProductSearch] = React.useState<boolean>(false);
  const [selectedResource, setSelectedResource] = useState<IResource>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedVariants, setSelectedVariants] = useState<ISelectedVariant[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);

  const { resources, loading: isLoadingResources, error: loadingResourcesError } = useGetResources();

  // TODO-CPS: allow user to select their resource
  useEffect(() => {
    setSelectedResource((prevSelectedResource) => {
      if (!isNil(resources) && !isEmpty(resources) && isNil(prevSelectedResource)) {
        return resources[0];
      }
      return prevSelectedResource;
    });
  }, [resources]);

  const [messageApi, contextHolder] = message.useMessage();

  // TODO-CPS: allow for fetching more
  const {
    productCollection, loading: isLoadingProducts, refetch: refetchProducts, error: productSearchGqlError, fetchMore,
  } = useGetProductsV3({
    variables: {
      resourceId: selectedResource?.id,
      query: '',
    },
    skip: !selectedResource?.id || !showProductSearch,
  });

  const fetchMoreProducts = useCallback(() => {
    if (productCollection?.pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          resourceId: selectedResource?.id,
          query: searchValue,
          cursor: productCollection.pageInfo.cursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => ({
          productCollection: {
            ...fetchMoreResult.productCollection,
            products: [
              ...prev.productCollection.products,
              ...fetchMoreResult.productCollection.products,
            ],
          },
        }),
      });
    }
  }, [fetchMore, productCollection?.pageInfo?.cursor, productCollection?.pageInfo?.hasNextPage, searchValue, selectedResource?.id]);

  // For some reason graphqlError.message is typed as string, but at execution time is actually an object
  const hasRateLimitError = (
    productSearchGqlError?.graphQLErrors[0]?.message as unknown as ErrorCode
  )?.extensions?.code === 'THROTTLING';

  useEffect(() => {
    if (productSearchGqlError?.message && !hasRateLimitError) {
      messageApi.error(
        productSearchGqlError.message,
        10,
      );
    }
  }, [productSearchGqlError?.message, messageApi, hasRateLimitError]);

  useEffect(() => {
    if (selectedResource && showProductSearch) {
      refetchProducts({
        resourceId: selectedResource?.id,
        query: searchValue,
      });
      setSelectedVariants([]);
    }
  }, [selectedResource, refetchProducts, searchValue, showProductSearch, setSelectedVariants]);

  const {
    projectConfig, loading: isLoadingProjectConfig, error: projectConfigError,
  } = useGetShopifyProjectConfig({
    variables: {
      projectId: workflowActionParameters.programId,
      type: workflowActionParameters.workletSpecUri === 'SendBrandProductCatalogWorkletSpecification'
      ? ProjectConfigType.ProductCatalog
      : ProjectConfigType.Shopify,
    },
    skip: !workflowActionParameters.programId,
  });

  const {
    order, loading: isLoadingOrder, error: getOrderError,
  } = useGetOrderById({
    variables: {
      id: orderId,
    },
  });

  const { criteria } = useGetSelectionCriteriaById({
    variables: {
      id: order?.artifacts?.selectionRuleId,
    },
    skip: !order?.artifacts?.selectionRuleId || workflowActionParameters.workletSpecUri !== 'SendBrandProductCatalogWorkletSpecification',
  });

  const {
    variants, loading: isLoadingVariants, error: getVariantsError, refetch: refetchVariants,
  } = useGetVariantsByIds({
    variables: {
      resourceId: selectedResource?.id,
      variantIds: getOrderRequestVariantIds(order),
    },
    skip: !selectedResource || isEmpty(getOrderRequestVariantIds(order)),
  });

  useEffect(() => {
    if (getVariantsError?.message) {
      messageApi.error(
        getVariantsError.message,
        10,
      );
    }
  }, [getVariantsError?.message, messageApi]);

  useEffect(() => {
    if (selectedResource && !isEmpty(order)) {
      refetchVariants({
        resourceId: selectedResource?.id,
        variantIds: getOrderRequestVariantIds(order),
      });
    }
  }, [selectedResource, refetchVariants, order]);

  // Methods to handle updating the current creator request state
  const [currentRequest, setCurrentRequest] = useState<ICurrentRequest>({});

  const {
    addBillingAddress,
    addAspireShippingLine,
    bypassShopifyInventory,
  } = useProductFulfillmentContext();

  useEffect(() => {
    if (!isEqual(productCollection.products, products)) {
      console.log('Setting products');
      setProducts(productCollection.products);
    }
  },
    [productCollection, products, setProducts]);

  const onGetMoreVariants = (productData: GetProductDetailsQuery_productDetail) => {
    setProducts(products.map((product: GetProductDetailsQuery_productDetail) => {
      if (product.id === productData.id) {
        return productData;
      }
      return product;
    }));
  };

  const toggleRemovedVariantInCurrentRequest = useCallback((variantId: number) => {
    setCurrentRequest((prevState) => {
      if (!prevState?.[variantId]) {
        console.log(`${variantId} does not exist in current request`);
        return prevState;
      }

      return {
        ...prevState,
        [variantId]: {
          ...prevState[variantId],
          removed: !prevState[variantId].removed,
        },
      };
    });
  }, []);

  const adjustVariantQuantityInCurrentRequest = useCallback((variantId: number, quantity: number) => {
    if (!currentRequest?.[variantId]) {
      console.log(`${variantId} does not exist in current request`);
      return;
    }

    setCurrentRequest({
      ...currentRequest,
      [variantId]: {
        ...currentRequest[variantId],
        quantity,
      },
    });
  }, [currentRequest, setCurrentRequest]);

  const addSelectedVariantsToCurrentRequest = useCallback(() => {
    if (isLoadingProducts) {
      return;
    }

    const currentRequestToAdd: ICurrentRequest = {};

    for (const selectedVariant of selectedVariants) {
      const productShopifyInfo = find(
        products,
        (product) => product.id === selectedVariant.productId,
      );
      if (!productShopifyInfo) {
        console.log(`Unable to get Shopify info for product (${selectedVariant.productId}).`);
        continue;
      }

      const variantShopifyInfo = find(
        productShopifyInfo.variants,
        (variant) => variant.id === selectedVariant.variantId,
      );
      if (!variantShopifyInfo) {
        console.log(`Unable to get Shopify info for variant (${selectedVariant.variantId}).`);
        continue;
      }

      if (currentRequest?.[selectedVariant.variantId]) {
        console.log(`${selectedVariant.variantId} already exists in current request`);
        continue;
      }

      currentRequestToAdd[selectedVariant.variantId] = {
        removed: false,
        variantTitle: variantShopifyInfo.title,
        productId: selectedVariant.productId,
        productTitle: productShopifyInfo.title,
        price: variantShopifyInfo.price,
        inventory: variantShopifyInfo.sellableOnlineQuantity,
        quantity: 1,
      };
    }

    setCurrentRequest({
      ...currentRequest,
      ...currentRequestToAdd,
    });
    setShowProductSearch(false);
    setSelectedVariants([]);
  }, [currentRequest, selectedVariants, isLoadingProducts, products, setCurrentRequest]);

  const isCurrentRequestInitialized = useMemo(() => !isEmpty(currentRequest), [currentRequest]);

  useEffect(() => {
    if (isCurrentRequestInitialized || isLoadingVariants || getVariantsError || isLoadingOrder || getOrderError) {
      return;
    }

    const creatorOrderRequestLineItems = order.creatorOrderRequest?.line_items;
    if (!creatorOrderRequestLineItems || creatorOrderRequestLineItems.length === 0) {
      return;
    }

    const currentRequestTemp: ICurrentRequest = {};

    for (const lineItem of creatorOrderRequestLineItems) {
      const variantShopifyInfo = find(variants, (variant) => variant.id === lineItem.variant_id);
      if (!variantShopifyInfo) {
        // TODO-CPS: Show an error?
        console.log(`Unable to get Shopify info for variant (${lineItem.variant_id}) in creator order request.`);
        continue;
      }

      currentRequestTemp[variantShopifyInfo.id] = {
        removed: false,
        variantTitle: variantShopifyInfo.title,
        productId: variantShopifyInfo.product_id,
        productTitle: variantShopifyInfo.product_title,
        price: variantShopifyInfo.price,
        inventory: variantShopifyInfo.sellableOnlineQuantity,
        quantity: lineItem.quantity,
      };
    }
    setCurrentRequest(currentRequestTemp);
  }, [
    isCurrentRequestInitialized,
    isLoadingVariants,
    getVariantsError,
    isLoadingOrder,
    getOrderError,
    order,
    variants,
  ]);

  // Order Request Email
  const [orderRequestStatus, setOrderRequestStatus] = useState<IOrderRequestStatus>(undefined);
  const orderHasUpdates = useMemo(() => {
    if (isEmpty(currentRequest) || isLoadingOrder || getOrderError) {
      return false;
    }

    const currentRequestLineItems = getLineItemsFromCurrentRequest(currentRequest);
    const sortedCurrentRequestLineItems = sortBy(currentRequestLineItems, 'variant_id');
    const orderRequestLineItems = sortBy(order.creatorOrderRequest?.line_items || [], 'variant_id');

    return (
      sortedCurrentRequestLineItems.length !== orderRequestLineItems.length
      || every(sortedCurrentRequestLineItems, (lineItem, i) => isEqual(lineItem, orderRequestLineItems[i]))
    );
  }, [currentRequest, isLoadingOrder, getOrderError, order]);

  const { approveOrderRequest, loading, error } = useApproveOrderRequest({
    onError(error: ApolloError) {
      showErrorMessage(error.message.replace('GraphQL error: ', ''));
    },
  });

  const { rejectOrderRequest, loading: isRejectLoading } = useRejectOrderRequest({
    onError(error: ApolloError) {
      showErrorMessage(error.message.replace('GraphQL error: ', ''));
    },
  });

  const [currentRequestItems, currentRequestMarketValue] = useMemo(() => {
    let numItems = 0;
    let marketValue = 0;

    for (const item of values(currentRequest)) {
      if (item.removed) {
        continue;
      }
      numItems += item.quantity;
      marketValue += toNumber(item.price) * item.quantity;
    }

    return [numItems, marketValue];
  }, [currentRequest]);

  const getInitialEmailStateCallback = useCallback(
    () => getInitialEmailState(user.name, clientInfo.name, orderRequestStatus, orderHasUpdates, currentRequestItems),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderRequestStatus, orderHasUpdates, user, clientInfo],
  );

  const customSend = useCallback(async (messageInput: SendMessageInput | null, newOrderRequestStatus?: IOrderRequestStatus) => {
    let messageParams;
    if (messageInput) {
      messageParams = {
        message: messageInput.message,
        membersSearchQuery: messageInput.membersSearchQuery, // the type of memberData is not specified in the original code
        subject: messageInput.subject,
        resourceId: messageInput.resourceId,
        attachments: messageInput.attachments, // the type of attachments is not specified in the original code
        type: messageInput.type.toLowerCase(),
      };
    }

    if (orderRequestStatus === 'accept' || newOrderRequestStatus === 'accept') {
      const currentRequestLineItems = getLineItemsFromCurrentRequest(currentRequest);
      const newCreatorOrderRequest = {
        ...order.creatorOrderRequest,
        ...(addBillingAddress && { billing_address: order.creatorOrderRequest?.shipping_address }),
        ...(addAspireShippingLine && {
          shipping_lines:
            [{ custom: true, price: 0, title: 'Aspire Placeholder Shipping' }],
        }),
        ...(bypassShopifyInventory && { inventory_behaviour: 'bypass' }),
        line_items: currentRequestLineItems,
      };
      const successMessage = messageParams ? 'Order request accepted and email sent to creator.' : 'Order request accepted.';
      try {
        await approveOrderRequest({
          variables: {
            orderId: order.id,
            creatorOrderRequest: newCreatorOrderRequest,
            workItemId: workItem.id,
            ...(messageParams) && { messageParams },
          },
          onCompleted: () => {
            showSuccessMessage(successMessage);
            closeModal();
          },
        });
      } catch (err) {
        throw new Error((error || err).message.replace('GraphQL error: ', ''));
      }
    } else if (orderRequestStatus === 'reject' || newOrderRequestStatus === 'reject') {
      const successMessage = messageParams ? 'Order request rejected and email sent to creator.' : 'Order request rejected.';
      await rejectOrderRequest({
        variables: {
          orderId: order.id,
          workItemId: workItem.id,
          ...(messageParams) && { messageParams },
        },
        onCompleted: () => {
          showSuccessMessage(successMessage);
          closeModal();
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderRequestStatus, currentRequest]);

  if (isLoadingResources || isLoadingOrder || isLoadingVariants || isLoadingProjectConfig || loading || isRejectLoading) {
    return (
      <>
        {contextHolder}
        <LoadSpinner />
      </>
);
  }
  const productSearchError = productSearchGqlError && !hasRateLimitError;
  if (loadingResourcesError || getOrderError || productSearchError || getVariantsError || projectConfigError) {
    // TODO-CPS: Add better error screen
    return (
      <>
        {contextHolder}
        There was an error, please try again.
      </>
);
  }
  const getNotice = () => {
    if (hasRateLimitError) {
      return (
        <Alert
          banner
          message="We’ve encountered a temporary limitation while accessing Shopify for you. Please wait a moment and try again."
        />
      );
    }
    if (isEmpty(currentRequest)) {
      return (
        <Alert
          banner
          message="Products have not been selected, please move this member back to send order request"
        />
      );
    }
    return null;
  };

  return (
    <>
      {contextHolder}
      <_OrderRequestReview
        shippingAddress={getAddressString(order.creatorOrderRequest.shipping_address as Address)}
        customSend={customSend}
        getInitialEmailState={getInitialEmailStateCallback}
        disabled={isEmpty(currentRequest)}
        notice={getNotice()}
        member={allMembers[0]}
        projectConfig={
          criteria ? {
            ...projectConfig,
            priceMax: criteria.maxPrice,
            quantityMax: criteria.maxQuantity,
          } : projectConfig
        }
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isLoadingProducts={isLoadingProducts}
        products={products}
        fetchMoreProducts={fetchMoreProducts}
        currentRequest={currentRequest}
        currentRequestItems={currentRequestItems}
        currentRequestMarketValue={currentRequestMarketValue}
        selectedVariants={selectedVariants}
        setSelectedVariants={setSelectedVariants}
        showProductSearch={showProductSearch}
        setShowProductSearch={setShowProductSearch}
        addSelectedVariantsToCurrentRequest={addSelectedVariantsToCurrentRequest}
        toggleRemovedVariantInCurrentRequest={toggleRemovedVariantInCurrentRequest}
        adjustVariantQuantityInCurrentRequest={adjustVariantQuantityInCurrentRequest}
        orderRequestStatus={orderRequestStatus}
        setOrderRequestStatus={setOrderRequestStatus}
        onGetMoreVariants={onGetMoreVariants}
        resourceId={selectedResource?.id}
      />
    </>
  );
};
