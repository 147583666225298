import React, { useState } from 'react';
import { Input, Table, Typography } from '@revfluence/fresh';
import { validateDeepLink } from '@frontend/applications/AffiliatesApp/components/MembersWizard/utils/validateDeepLinkUrls';
import { TriangleExclamationIcon } from '@revfluence/fresh-icons/regular';

interface FormViewProps {
  deepLinkUrl: string;
  handleInputChange: (value: string) => void;
  validateDeepLinkParams: { offerLink: string, isCreatorDeepLinkAllowed: boolean, domains: string[] };
}

const { Text } = Typography;

const FormView: React.FC<FormViewProps> = ({ deepLinkUrl, handleInputChange, validateDeepLinkParams }) => {
  const [linkValidation, setLinkValidation] = useState<{ error: string | null; warning: string | null }>({
    error: null,
    warning: null,
  });

  const handleValidateDeepLink = (value: string) => {
    const validation = validateDeepLink(value, validateDeepLinkParams.offerLink, validateDeepLinkParams.isCreatorDeepLinkAllowed, validateDeepLinkParams.domains);
    setLinkValidation({
      error: validation.error,
      warning: validation.warning,
    });
  };

  const columns = [
    {
      title: 'Label',
      dataIndex: 'defaultDeepLink',
      key: 'defaultDeepLink',
      width: '50%',
      render: () => <Input value="Default Deep Link" disabled className="bg-gray-200 text-gray-500 w-full" />,
    },
    {
      title: 'DeepLink URL',
      dataIndex: 'deepLinkUrl',
      key: 'deepLinkUrl',
      width: '50%',
      render: () => (
        <>
          <Input
            value={deepLinkUrl || ''}
            placeholder="Enter DeepLink URL"
            onChange={(e) => {
                handleInputChange(e.target.value);
                handleValidateDeepLink(e.target.value);
              }}
            status={linkValidation.error ? 'error' : linkValidation.warning ? 'warning' : ''}
            suffix={linkValidation.error ? <TriangleExclamationIcon type="danger" /> : linkValidation.warning ? <TriangleExclamationIcon type="warning" /> : <></>}
            className="border-gray-300 rounded-md w-full"
          />
          {linkValidation.error && (
          <Text type="danger" className="text-red-500">
            {linkValidation.error}
          </Text>
            )}
          {!linkValidation.error && linkValidation.warning && (
          <Text type="warning" className="text-yellow-500">
            {linkValidation.warning}
          </Text>
            )}
        </>
        ),
    },
  ];

  return (
    <>
      <Table dataSource={[{ key: '1' }]} columns={columns} pagination={false} className="bg-white" />
    </>
  );
};

export default FormView;
