import React, { useEffect } from 'react';
import { Typography, Row, Col } from 'antd';
import TableView from './Sections/TableView';
import { TMember } from '../../../MembersWizard/types';
import styles from '../../ManageOffer.scss';
import FormView from './Sections/FormView';

interface IProps {
  deepLinkUrl: string;
  setDeepLinkUrl: (bulkDeepLinkUrl: string) => void;
  selectedOfferMember: (TMember & { status: string })[];
  isFormViewVisible: boolean;
  validateDeepLinkParams: { offerLink: string, isCreatorDeepLinkAllowed: boolean, domains: string[] };
  setFormViewVisible: (isFormViewVisible: boolean) => void;
}

enum AFFILIATE_STATUS {
  ACTIVE = 'active',
}

const { Title, Text } = Typography;

const ManagePrimaryDeepLink: React.FC<IProps> = ({
  deepLinkUrl,
  setDeepLinkUrl,
  selectedOfferMember = [],
  isFormViewVisible,
  validateDeepLinkParams,
  setFormViewVisible,
}) => {
  const selectedActiveMembers = selectedOfferMember.filter((selectedMember) => selectedMember.status.toLowerCase() === AFFILIATE_STATUS.ACTIVE);

  useEffect(() => {
    setFormViewVisible(false);
    setDeepLinkUrl('');
  }, [setFormViewVisible, setDeepLinkUrl]);

  const handleInputChange = (value: string) => {
    setDeepLinkUrl(value);
  };

  return (
    <div className={styles.manageOfferContainer}>
      <Title level={5}>Edit Primary Deep Links</Title>
      <Text>You can update the deep links for affiliates without changing any data.</Text>
      <Row justify="space-between" align="middle" style={{ marginTop: '16px' }}>
        <Col>
          <Text strong>
            {selectedActiveMembers?.length ?? 0}
            {' '}
            Members Selected
          </Text>
        </Col>
      </Row>
      {!isFormViewVisible ? (
        <TableView selectedMembers={selectedActiveMembers} />
      ) : (
        <FormView deepLinkUrl={deepLinkUrl} handleInputChange={handleInputChange} validateDeepLinkParams={validateDeepLinkParams} />
      )}
    </div>
  );
};

export default ManagePrimaryDeepLink;
