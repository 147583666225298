import React, { useMemo } from 'react';
import { MainMetric, WidgetContainer } from '@frontend/app/refresh-components';
import { Bar, BarChart, XAxis } from 'recharts';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import { CoinIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartLegendContent,
} from '@/shadcn/components/ui/chart';
import { useGetBudgetDashboardBudgetTabSummaryQuery } from '../hooks';

const chartConfig = {
  project: {
    label: 'In Master budget',
    color: 'hsl(var(--chart-3))',
  },
  account: {
    label: 'In Allocated Budget',
    color: 'hsl(var(--chart-4))',
  },
} as ChartConfig;

interface TotalSpentWidgetProps {
  budgetId?: number;
  fiscalYear?: string;
  className?: string;
}

export default function TotalSpentWidget(props: TotalSpentWidgetProps) {
  const { budgetId, fiscalYear, className } = props;
  const isParemetersAbsent = !budgetId || !fiscalYear;
  const { data: budgetSummary, loading } = useGetBudgetDashboardBudgetTabSummaryQuery({
    variables: {
      budgetId,
      fiscalYear,
    },
    skip: isParemetersAbsent,
  });

  const chartData = useMemo(() => [
    { source: 'In Budget', value: budgetSummary?.summary?.spentBudget?.spentInSelf || 0, fill: 'var(--color-project)' },
    { source: 'In Allocated Budget', value: budgetSummary?.summary?.spentBudget?.spentInAllocated || 0, fill: 'var(--color-account)' },
  ], [budgetSummary]);

  const missingParametersContent = (
    <div className="w-full h-full flex justify-center items-center">
      <p>No data found</p>
    </div>
  );
  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const mainContent = (
    <div className="flex flex-col">
      <MainMetric
        className="flex-1"
        value={budgetSummary?.summary?.spentBudget?.spentAmount || 0}
        size="medium"
        metricType="currency"
        subHeading="of Total Budget"
        icon={<Badge variant="outline" className="text-green-700">{`${budgetSummary?.summary?.spentBudget?.spentPercent || 0}%`}</Badge>}
      />
      <ChartContainer config={chartConfig} className="h-[150px] w-full">
        <BarChart data={chartData} margin={{ top: 0, left: 0 }}>
          <XAxis dataKey="source" tickLine={false} tickMargin={10} axisLine={false} />
          <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
          <Bar dataKey="value" strokeWidth={1} radius={4} barSize={35} />
          <ChartLegend content={<ChartLegendContent />} />
        </BarChart>
      </ChartContainer>
    </div>
  );

  return (
    <WidgetContainer
      widgetTitle="Total Spent"
      className={`${className}`}
      bgColor="bg-[#D1A34F]"
      widgetIcon={<CoinIcon className="text-white" fontSize={20} />}
    >
      {isParemetersAbsent ? missingParametersContent : loading ? loadingContent : mainContent}
    </WidgetContainer>
  );
}
