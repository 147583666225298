/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AFFILIATE_STATUS {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}

export enum CLIENT_CONNECTION_STATUS {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
}

export enum CONVERSION_ACTION {
  ACCEPT = "ACCEPT",
  REJECT = "REJECT",
}

export enum OFFER_CONVERSION_TYPE {
  CONVERSIONS = "CONVERSIONS",
  DOWNLOADS = "DOWNLOADS",
  EMAIL_SUBMITS = "EMAIL_SUBMITS",
  LEAD_GENERATIONS = "LEAD_GENERATIONS",
}

export enum OFFER_LOG_NAME {
  ADD_PAYOUT = "ADD_PAYOUT",
  CODE_PREFIX = "CODE_PREFIX",
  CODE_SUFFIX = "CODE_SUFFIX",
  DEFAULT_PAYOUT = "DEFAULT_PAYOUT",
  DISABLED_CODE_USAGE_COUNT = "DISABLED_CODE_USAGE_COUNT",
  DISABLED_PURCHASE_RESTRICTIONS = "DISABLED_PURCHASE_RESTRICTIONS",
  ENABLED_CODE_USAGE_COUNT = "ENABLED_CODE_USAGE_COUNT",
  LABEL_UPDATE_PAYOUT = "LABEL_UPDATE_PAYOUT",
  LIMIT_NEW_CUSTOMER = "LIMIT_NEW_CUSTOMER",
  LIMIT_ONE_PER_CUSTOMER = "LIMIT_ONE_PER_CUSTOMER",
  OFFER_ALLOW_MULTIPLE_CONVERSIONS = "OFFER_ALLOW_MULTIPLE_CONVERSIONS",
  OFFER_CONVERSION_TRACKING = "OFFER_CONVERSION_TRACKING",
  OFFER_CONVERSION_TYPE = "OFFER_CONVERSION_TYPE",
  OFFER_DESCRIPTION = "OFFER_DESCRIPTION",
  OFFER_EXPIRATION_DATE = "OFFER_EXPIRATION_DATE",
  OFFER_IMAGE = "OFFER_IMAGE",
  OFFER_MIGRATED_TO_MULTIPLE_STORE = "OFFER_MIGRATED_TO_MULTIPLE_STORE",
  OFFER_MIGRATED_TO_SINGLE_STORE = "OFFER_MIGRATED_TO_SINGLE_STORE",
  OFFER_NAME = "OFFER_NAME",
  OFFER_PAYOUT_VALUE = "OFFER_PAYOUT_VALUE",
  OFFER_PERCENT_PAYOUT = "OFFER_PERCENT_PAYOUT",
  OFFER_PROMO_GROUP_CODE = "OFFER_PROMO_GROUP_CODE",
  OFFER_PROMO_ISUNGROUPED = "OFFER_PROMO_ISUNGROUPED",
  OFFER_PROMO_NEW_FLOW = "OFFER_PROMO_NEW_FLOW",
  OFFER_STATUS = "OFFER_STATUS",
  OFFER_URL_TRACKING = "OFFER_URL_TRACKING",
  PREFIX_TYPE = "PREFIX_TYPE",
  PROMO_DISCOUNT_VALUE = "PROMO_DISCOUNT_VALUE",
  PROMO_END_DATE = "PROMO_END_DATE",
  PROMO_START_DATE = "PROMO_START_DATE",
  PURCHASE_RESTRICTION_AMOUNT = "PURCHASE_RESTRICTION_AMOUNT",
  PURCHASE_RESTRICTION_QUANTITY = "PURCHASE_RESTRICTION_QUANTITY",
  PURCHASE_TYPE = "PURCHASE_TYPE",
  REMOVE_PAYOUT = "REMOVE_PAYOUT",
  STORE_UPDATED = "STORE_UPDATED",
  VALUE_UPDATE_PAYOUT = "VALUE_UPDATE_PAYOUT",
}

export enum OFFER_PAYOUT_ACTION {
  ADD = "ADD",
  NOUPDATE = "NOUPDATE",
  REMOVE = "REMOVE",
  UPDATE = "UPDATE",
}

export enum OFFER_PAYOUT_TYPE {
  CLICK = "CLICK",
  CONVERSION = "CONVERSION",
  CONVERSION_AND_SALE = "CONVERSION_AND_SALE",
  SALE = "SALE",
  THOUSAND_IMPRESSIONS = "THOUSAND_IMPRESSIONS",
}

export enum OFFER_PAYOUT_TYPE_PROMO {
  CONVERSION = "CONVERSION",
  CONVERSION_AND_SALE = "CONVERSION_AND_SALE",
  SALE = "SALE",
}

export enum OFFER_PRICE_RULE_TYPE {
  AMOUNT = "AMOUNT",
  PERCENTAGE = "PERCENTAGE",
}

export enum OFFER_PROMO_CODE_STATUS {
  DELETED = "DELETED",
  EXPIRED = "EXPIRED",
  FAILED_DELETION = "FAILED_DELETION",
  FAILED_DUPLICATE = "FAILED_DUPLICATE",
  FAILED_MIGRATION = "FAILED_MIGRATION",
  FAILED_OTHER = "FAILED_OTHER",
  FAILED_TOO_LONG = "FAILED_TOO_LONG",
  PENDING = "PENDING",
  PENDING_DELETION = "PENDING_DELETION",
  PENDING_UPDATE = "PENDING_UPDATE",
  SUCCESS = "SUCCESS",
}

export enum OFFER_PROMO_PREFIX_TYPE {
  FIRST_INITIAL_LAST_NAME = "FIRST_INITIAL_LAST_NAME",
  FIRST_NAME_LAST_INITIAL = "FIRST_NAME_LAST_INITIAL",
  FULL_NAME = "FULL_NAME",
  IG_USERNAME = "IG_USERNAME",
}

export enum OFFER_PROMO_USAGE_LIMIT_RULE {
  MINIMUM_ITEM_AMOUNT = "MINIMUM_ITEM_AMOUNT",
  MINIMUM_PURCHASE_AMOUNT = "MINIMUM_PURCHASE_AMOUNT",
  NONE = "NONE",
}

export enum OFFER_SOURCE {
  SHOPIFY = "SHOPIFY",
  TUNE = "TUNE",
}

export enum OFFER_STATUS {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  PAUSED = "PAUSED",
}

export enum OFFER_TRACKING_TYPE {
  HTTPS_IFRAME_PIXEL = "HTTPS_IFRAME_PIXEL",
  HTTPS_IMAGE_PIXEL = "HTTPS_IMAGE_PIXEL",
  JAVASCRIPT_POSTBACK = "JAVASCRIPT_POSTBACK",
  SERVER_POSTBACK_AFFILIATE_ID = "SERVER_POSTBACK_AFFILIATE_ID",
  SERVER_POSTBACK_TRANSACTION_ID = "SERVER_POSTBACK_TRANSACTION_ID",
}

export enum OFFER_TYPE {
  LINK = "LINK",
  PROMO_CODE = "PROMO_CODE",
}

export enum PAYMENT_STATUS {
  CANCELED = "CANCELED",
  PAID = "PAID",
  PENDING = "PENDING",
}

export enum SEND_STA_TASK_ID {
  send_link_and_promo_task = "send_link_and_promo_task",
  send_link_task = "send_link_task",
  send_promo_task = "send_promo_task",
}

export enum SHOPIFY_SYNC_ACTION {
  ADD = "ADD",
  NOUPDATE = "NOUPDATE",
  REMOVE = "REMOVE",
  UPDATE = "UPDATE",
}

export enum STATS_OFFER_TYPE {
  ALL_OFFERS = "ALL_OFFERS",
  ARCHIVE_OFFER = "ARCHIVE_OFFER",
  LINK_OFFER = "LINK_OFFER",
  PROMO_OFFER = "PROMO_OFFER",
}

export enum UPSERT_PROMO_STA_TASK_ID {
  fix_error_promo_task = "fix_error_promo_task",
  generate_promo_task = "generate_promo_task",
}

export interface AdvertiserInput {
  name: string;
}

export interface AffiliateOfferLinkForceCheckIn {
  memberId: number;
  workItemId: string;
}

export interface AffiliateOfferLinkInput {
  deepLink?: string | null;
  memberId: number;
  payoutId?: number | null;
  workItemId?: string | null;
}

export interface AffiliateOfferPromoDeleteInput {
  id: number;
}

export interface AffiliateOfferPromoForceCheckIn {
  memberId: number;
  workItemId: string;
  workflowTask: UPSERT_PROMO_STA_TASK_ID;
}

export interface AffiliateOfferPromoInput {
  desiredPromoCode: string;
  endDate?: any | null;
  memberId: number;
  payoutId?: number | null;
  startDate?: any | null;
  workItemId?: string | null;
  workflowTask?: UPSERT_PROMO_STA_TASK_ID | null;
}

export interface ArchiveOfferInput {
  offerId: number;
  userInfo: UserInfoInput;
}

export interface ClientShopifyConnectionInput {
  connectedClientId: string;
  isConnected?: boolean | null;
  isPrimary: boolean;
  label: string;
  masterClientId: string;
}

export interface CreateLinkInput {
  allowMultipleConversions?: boolean | null;
  conversionTrackingType: OFFER_TRACKING_TYPE;
  conversionType: OFFER_CONVERSION_TYPE;
  creatorDeeplink?: boolean | null;
  defaultPayoutId?: number | null;
  domains?: string[] | null;
  flatPayout?: number | null;
  isThirdPartyIntegration?: boolean | null;
  payoutType: OFFER_PAYOUT_TYPE;
  payouts: PayoutInput[];
  percentPayout?: number | null;
  status: OFFER_STATUS;
  thirdPartyTracking?: string | null;
  url: string;
  utmFields?: any | null;
}

export interface CreateOfferInput {
  description?: string | null;
  expirationDate: any;
  imageUrl?: string | null;
  isNewFlow?: boolean | null;
  isReadOnly?: boolean | null;
  links: CreateLinkInput[];
  name: string;
  programId?: number | null;
  promos: CreatePromoInput[];
}

export interface CreatePriceRuleInput {
  advertiserId: number;
  priceRuleAmount?: number | null;
  priceRuleType?: OFFER_PRICE_RULE_TYPE | null;
}

export interface CreatePromoInput {
  clientsForSync?: CreatePriceRuleInput[] | null;
  codePrefix?: string | null;
  codeSuffix: string;
  defaultPayoutId?: number | null;
  discountCodeGId?: string | null;
  discountCombination?: DiscountCombinationInput | null;
  endDate?: any | null;
  flatPayout?: number | null;
  isOneTime?: boolean | null;
  isSubscription?: boolean | null;
  isUngrouped?: boolean | null;
  multiShopifyEnabled: boolean;
  name: string;
  payoutType?: OFFER_PAYOUT_TYPE_PROMO | null;
  payouts: PayoutInput[];
  percentPayout?: number | null;
  prefixType?: OFFER_PROMO_PREFIX_TYPE | null;
  priceRuleAmount?: number | null;
  priceRuleType?: OFFER_PRICE_RULE_TYPE | null;
  productCollections?: ShopifyProductCollectionInput[] | null;
  recurringCycleLimit?: number | null;
  source: OFFER_SOURCE;
  specialLimitNewCustomersOnly?: boolean | null;
  specialLimitOnePerSale: boolean;
  specialLimitProductCollections?: boolean | null;
  specialLimitUsageCapAmount?: number | null;
  specialLimitUsageCapEnabled: boolean;
  startDate?: any | null;
  status: OFFER_STATUS;
  usageLimitAmount?: number | null;
  usageLimitRule?: OFFER_PROMO_USAGE_LIMIT_RULE | null;
}

export interface DiscountCombinationInput {
  orderDiscounts?: boolean | null;
  productDiscounts?: boolean | null;
  shippingDiscounts?: boolean | null;
}

export interface ManageBulkDeeplinksInput {
  defaultDeeplink: UpdateAffiliateOfferDeeplinkBulkInput;
}

export interface OfferSearchQuery {
  clientId?: string | null;
  expired?: boolean | null;
  programId?: number | null;
  type?: OFFER_TYPE | null;
}

export interface PaymentGroupInput {
  aspirex_application: string;
  client_id: string;
  created_ts: number;
  end_date?: any | null;
  id: number;
  name: string;
  start_date?: any | null;
}

export interface PaymentInput {
  amount_intended_for_publisher: number;
  aspirex_application: string;
  canceled: boolean;
  client_id: string;
  created_ts: number;
  currency_code: string;
  currency_xrate: number;
  date_transferred_ts?: number | null;
  has_paid_publisher: boolean;
  id: number;
  metadata: any;
  payee_name: string;
  payment_group_id: number;
  paypal: string;
}

export interface PayoutInput {
  flatPayout?: number | null;
  id?: number | null;
  isDefault: boolean;
  label: string;
  payoutAction?: OFFER_PAYOUT_ACTION | null;
  payoutType: OFFER_PAYOUT_TYPE;
  percentPayout?: number | null;
}

export interface ShopifyProductCollectionInput {
  id: number;
  title: string;
}

export interface UpdateAffiliateOfferDeeplinkBulkInput {
  affiliateOfferLinkIds: number[];
  deepLinkUrl?: string | null;
}

export interface UpdateAffiliatePayoutInput {
  id: number;
  newPayoutId?: number | null;
  oldPayoutId?: number | null;
}

export interface UpdateClientForSync {
  action?: SHOPIFY_SYNC_ACTION | null;
  advertiserId: number;
  priceRuleAmount?: number | null;
  priceRuleType?: OFFER_PRICE_RULE_TYPE | null;
}

export interface UpdateLinkInput {
  allowMultipleConversions?: boolean | null;
  conversionTrackingType?: OFFER_TRACKING_TYPE | null;
  conversionType?: OFFER_CONVERSION_TYPE | null;
  creatorDeeplink?: boolean | null;
  domains?: string[] | null;
  flatPayout?: number | null;
  id?: number | null;
  isThirdPartyIntegration?: boolean | null;
  payoutType?: OFFER_PAYOUT_TYPE | null;
  payouts: PayoutInput[];
  percentPayout?: number | null;
  status?: OFFER_STATUS | null;
  thirdPartyTracking?: string | null;
  url?: string | null;
  utmFields?: any | null;
}

export interface UpdateOfferInput {
  description?: string | null;
  expirationDate?: any | null;
  imageUrl?: string | null;
  isReadOnly?: boolean | null;
  links?: UpdateLinkInput[] | null;
  migrateToNewFlow?: boolean | null;
  name?: string | null;
  programId?: number | null;
  promos?: UpdatePromoInput[] | null;
  showSendConfirmationScreen?: boolean | null;
  userInfo?: UserInfoInput | null;
}

export interface UpdatePromoInput {
  clientsForSync?: CreatePriceRuleInput[] | null;
  codePrefix?: string | null;
  codeSuffix: string;
  defaultPayoutId?: number | null;
  discountCodeGId?: string | null;
  discountCombination?: DiscountCombinationInput | null;
  endDate?: any | null;
  flatPayout?: number | null;
  id?: number | null;
  isOneTime?: boolean | null;
  isSubscription?: boolean | null;
  isUngrouped?: boolean | null;
  multiShopifyEnabled: boolean;
  name: string;
  payoutType?: OFFER_PAYOUT_TYPE_PROMO | null;
  payouts: PayoutInput[];
  percentPayout?: number | null;
  prefixType?: OFFER_PROMO_PREFIX_TYPE | null;
  priceRuleAmount?: number | null;
  priceRuleType?: OFFER_PRICE_RULE_TYPE | null;
  productCollections?: ShopifyProductCollectionInput[] | null;
  recurringCycleLimit?: number | null;
  source: OFFER_SOURCE;
  specialLimitNewCustomersOnly?: boolean | null;
  specialLimitOnePerSale: boolean;
  specialLimitProductCollections?: boolean | null;
  specialLimitUsageCapAmount?: number | null;
  specialLimitUsageCapEnabled: boolean;
  startDate?: any | null;
  status: OFFER_STATUS;
  updatedClientsForSync?: UpdateClientForSync[] | null;
  usageLimitAmount?: number | null;
  usageLimitRule?: OFFER_PROMO_USAGE_LIMIT_RULE | null;
}

export interface UserInfoInput {
  clientId: string;
  email: string;
  name: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
