import React from 'react';
import {
 Collapse, Checkbox, Typography, Table,
} from '@revfluence/fresh';
import { TMember } from '@frontend/applications/AffiliatesApp/components/MembersWizard/types';
import styles from '../../../ManageOffer.scss';

const { Panel } = Collapse;
const { Text } = Typography;

interface TableViewProps {
  selectedMembers?: readonly TMember[];
}

const TableView: React.FC<TableViewProps> = ({ selectedMembers }) => {
  const columns = [
    {
      title: 'Members',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  return (
    <Collapse
      defaultActiveKey={['0']}
      expandIconPosition="left"
      className={styles.collapseBg}
    >
      <Panel
        header={(
          <div className="flex justify-between items-center">
            <Checkbox checked>
              <Text className={styles.defaultLabel}>
                Default Deep Link
              </Text>
            </Checkbox>
            <Text>
              {selectedMembers?.length ?? 0}
              {' '}
              Members
            </Text>
          </div>
        )}
        key="1"
        className={styles.collapseBg}
      >
        <Table
          dataSource={selectedMembers}
          columns={columns}
          pagination={false}
          rowKey={(record) => record.id.toString()}
          bordered={false}
        />
      </Panel>
    </Collapse>
  );
};

export default TableView;
