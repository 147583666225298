import gql from 'graphql-tag';

export const GET_BRAND_CATALOGS_QUERY = gql`
  query GetBrandCatalogsQuery {
    getBrandCatalogs {
      id
      type
      status
      name
    }
  }
`;

export const GET_BRAND_CATALOG_BY_ID_QUERY = gql`
  query GetBrandCatalogByIdQuery($id: Int!) {
    getBrandCatalogById(id: $id) {
      id
      type
      status
      name
      description
      settings {
        isFeatureProductsEnable
        hasBannerImage
        hasHeading
        hasRichText
        bannerImage
        heading
        richText
        hidePriceFromCreators
        hideTagsFromCreators
      }
    }
  }
`;

export const CREATE_BRAND_CATALOG_MUTATION = gql`
  mutation CreateBrandCatalogMutation($input: CreateBrandCatalogInput!) {
    createBrandCatalog(input: $input) {
      id
      type
      status
      name
      settings {
        isFeatureProductsEnable
      }
    }
  }
`;

export const UPDATE_BRAND_CATALOG_MUTATION = gql`
  mutation UpdateBrandCatalogMutation($input: UpdateBrandCatalogInput!) {
    updateBrandCatalog(input: $input) {
      id
      type
      status
      name
      settings {
        isFeatureProductsEnable
      }
    }
  }
`;

export const GET_BRAND_CATALOG_STATS_QUERY = gql`
  query GetBrandCatalogStatsQuery($id: Int!) {
    getStatsByCatalogId(id: $id) {
      collectionCount
      productCount
      variantCount
    }
  }
`;

export const GET_ALL_CATALOG_STATS_QUERY = gql`
  query GetStatsForAllCatalogs {
    getStatsForAllCatalogs {
      collectionCount
      productCount
      variantCount
      brandCatalogId
    }
  }
`;

export const GET_CREATORS_ORDER_CATALOG_INFO_QUERY = gql`
  query GetCreatorsOrderCatalogInfoQuery($orderIds: [String!]!) {
    getCreatorsOrderCatalogInfo(orderIds: $orderIds) {
      orderId
      brandCatalogId
      selectionRuleId
      brandCatalogName
      selectionRuleName
    }
  }
`;
