import 'css-chunk:src/applications/AffiliatesApp/components/ManageOffer/ManageOffer.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_11cf6_157",
  "justify-content-space-between": "_justify-content-space-between_11cf6_161",
  "tabular-nums": "_tabular-nums_11cf6_165",
  "ManageOfferDrawer": "_ManageOfferDrawer_11cf6_178",
  "header": "_header_11cf6_181",
  "offerDetails": "_offerDetails_11cf6_187",
  "offerName": "_offerName_11cf6_197",
  "img": "_img_11cf6_201",
  "manageOfferwrapper": "_manageOfferwrapper_11cf6_209",
  "manageOfferButtons": "_manageOfferButtons_11cf6_212",
  "buttonsAction": "_buttonsAction_11cf6_224",
  "buttonTitle": "_buttonTitle_11cf6_233",
  "mb0": "_mb0_11cf6_251",
  "manageOfferContainer": "_manageOfferContainer_11cf6_254",
  "boldText": "_boldText_11cf6_268",
  "memberCount": "_memberCount_11cf6_272",
  "ManageOfferAction": "_ManageOfferAction_11cf6_276",
  "customInputBox": "_customInputBox_11cf6_284",
  "tooltipError": "_tooltipError_11cf6_293",
  "customInputBoxError": "_customInputBoxError_11cf6_299",
  "activeDateContainer": "_activeDateContainer_11cf6_306",
  "card": "_card_11cf6_311",
  "dateHeader": "_dateHeader_11cf6_314",
  "dateContainer": "_dateContainer_11cf6_320",
  "dateInput": "_dateInput_11cf6_323",
  "noticeContainer": "_noticeContainer_11cf6_327",
  "iconWrapper": "_iconWrapper_11cf6_333",
  "defaultLabel": "_defaultLabel_11cf6_339",
  "collapseBg": "_collapseBg_11cf6_347",
  "show": "_show_11cf6_1"
};