import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { WidgetContainer, DataTable } from '@frontend/app/refresh-components';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { cn } from '@/shadcn/lib/utils';
import { useGetBudgetDashboardSpendSummaryQuery } from '../hooks';

const { useState, useEffect } = React;
type RowItem = {
  id: string;
  name: string;
  creatorsCount: number;
  totalSpend: number;
  averageSpend: number;
};
interface SpendByCreatorSegmentWidgetByProjectProps {
  projectId?: number;
  fiscalYear?: string;
  className?: string;
}

export default function SpendByCreatorSegmentWidget(props: SpendByCreatorSegmentWidgetByProjectProps) {
  const { projectId, fiscalYear, className } = props;
  const isParemetersAbsent = !projectId || !fiscalYear;
  const [data, setData] = useState<RowItem[]>([]);

  const { data: budgetSummary, loading } = useGetBudgetDashboardSpendSummaryQuery({
    variables: {
      programId: projectId,
      fiscalYear,
    },
    skip: isParemetersAbsent,
  });

  useEffect(() => {
    if (!loading && budgetSummary?.summary?.memberSegmentSpendList?.length) {
      const segments: RowItem[] = budgetSummary?.summary?.memberSegmentSpendList.map((segment, index) => ({
        id: index.toString(),
        averageSpend: segment.averageSpentAmount as number,
        creatorsCount: segment.totalMembers as number,
        name: segment.memberSegmentName as string,
        totalSpend: segment.spentAmount as number,
      }));
      setData(segments);
    }
  }, [budgetSummary, loading]);

  const columns: ColumnDef<RowItem>[] = [
    {
      accessorKey: 'name',
      header: () => <div className="font-semibold text-[#1F1F21]">Name</div>,
    },
    {
      accessorKey: 'creatorsCount',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Number of Creators</div>,
      cell: ({ row }) => {
        const creatorsCount = parseFloat(row.getValue('creatorsCount'));
        return <div className="text-right font-medium">{creatorsCount}</div>;
      },
    },
    {
      accessorKey: 'totalSpend',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Total Spend</div>,
      cell: ({ row }) => {
        const totalSpend = parseFloat(row.getValue('totalSpend'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(totalSpend);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
    {
      accessorKey: 'averageSpend',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Avg Spend</div>,
      cell: ({ row }) => {
        const averageSpend = parseFloat(row.getValue('averageSpend'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(averageSpend);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
  ];

  const missingParametersContent = (
    <div className="w-full h-full flex justify-center items-center">
      <p>No data found</p>
    </div>
  );
  const loadingContent = (
    <WidgetContainer
      widgetTitle="Spend by Creator Segment"
      className={cn(className, '')}
      bgColor="bg-white"
      textColor="text-gray-700"
    >
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </WidgetContainer>
  );
  const mainContent = <DataTable columns={columns} data={data} />;

  return (
    <WidgetContainer
      widgetTitle="Spend by Creator Segment"
      className={cn(className, '')}
      bgColor="bg-white"
      textColor="text-gray-700"
    >
      {isParemetersAbsent ? missingParametersContent : loading ? loadingContent : mainContent}
    </WidgetContainer>
  );
}
