import * as React from 'react';
import { useState } from 'react';
import { find } from 'lodash';
import cx from 'classnames';

import { ResourceType } from '@frontend/app/types/globalTypes';
import { LoadSpinner } from '@components';
import { useGetResources, useGetRunningKlaviyoSync } from '@frontend/app/hooks';
import { Typography } from '@revfluence/fresh';
import styles from '@frontend/applications/GoogleDriveApp/pages/GdriveSettingsPage.scss';
import KlaviyoSettingsPageEmpty from './KlaviyoSettingsPageEmpty';
import KlaviyoSyncContainer from '../container/KlaviyoSyncContainer';
import SyncProgressContainer from '../container/SyncProgressContainer';

const { useMemo } = React;

const KlaviyoSettingsPage: React.FC = React.memo(() => {
  const [isSyncing, setIsSyncing] = useState(false);
  const [taskTrackerId, setTaskTrackerId] = React.useState<number>();
  console.log(taskTrackerId);
  const type = ResourceType.KLAVIYO;
  const { resources, loading, refetch } = useGetResources({
    fetchPolicy: 'no-cache',
  });

  useGetRunningKlaviyoSync({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getRunningKlaviyoSync.id > 0) {
          setTaskTrackerId(data.getRunningKlaviyoSync.id);
      }
  },
});
  const klaviyoResource = useMemo(() => find(resources, (r) => !r.authProvider.userRevokedAccess && r.type === type), [
    resources,
    type,
  ]);

  return (
    <>
      <Typography.Title level={3}>Klaviyo</Typography.Title>
      <Typography.Text className={cx(styles.text)}>Sync data on members that are in Projects and Groups to Klaviyo.</Typography.Text>
      <div>
        {loading && !klaviyoResource && <LoadSpinner />}
        {!loading && !klaviyoResource && <KlaviyoSettingsPageEmpty type={type} />}
        {klaviyoResource && (
        <KlaviyoSyncContainer
          refetchResources={refetch}
          activeAccount={klaviyoResource}
          onSyncCompleted={setTaskTrackerId}
          onSyncStart={() => setIsSyncing(true)}
          inProgress={isSyncing}
        />
)}
        {klaviyoResource && taskTrackerId && (
          <SyncProgressContainer
            syncJobId={taskTrackerId}
            onSyncStatusChange={setIsSyncing}
          />
        )}
      </div>
    </>
  );
});

KlaviyoSettingsPage.displayName = 'KlaviyoSettingsPage';

export default KlaviyoSettingsPage;
