import React from 'react';
import { cn } from '@/shadcn/lib/utils';

export const H3 = React.forwardRef<HTMLHeadingElement, React.ComponentPropsWithoutRef<'h4'>>(
  ({ className, children, ...props }, ref) => (
    <h3 ref={ref} className={cn('scroll-m-20 text-2xl font-semibold tracking-tight', className)} {...props}>
      {children}
    </h3>
  ),
);

H3.displayName = 'H3';
