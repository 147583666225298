import React from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from '@/shadcn/lib/utils';

// Variants for H4
const h4Variants = cva('scroll-m-20 tracking-tight', {
  variants: {
    size: {
      medium: 'text-lg font-semibold leading-normal',
      regular: 'text-base font-normal leading-relaxed',
      small: 'text-sm font-light leading-tight',
    },
  },
  defaultVariants: {
    size: 'regular',
  },
});

export const H4 = React.forwardRef<HTMLHeadingElement, VariantProps<typeof h4Variants> & React.ComponentPropsWithoutRef<'h4'>>(
  ({
    className, size, children, ...props
  }, ref) => (
    <h4 ref={ref} className={cn(h4Variants({ size }), className)} {...props}>
      {children}
    </h4>
  ),
);

H4.displayName = 'H4';
