import React from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from '@/shadcn/lib/utils';

// variants for paragraph
const paragraphVariants = cva('leading-7 mb-0 [&:not(:first-child)]:mt-6', {
  variants: {
    size: {
      default: 'text-sm font-normal leading-6',
      medium: 'text-base font-medium leading-7',
      small: 'text-xs font-light leading-5',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

export const P = React.forwardRef<HTMLParagraphElement, VariantProps<typeof paragraphVariants> & React.ComponentPropsWithoutRef<'p'>>(
  ({ className, size, ...props }, ref) => (
    <p ref={ref} className={cn(paragraphVariants({ size }), className)} {...props} />
  ),
);

P.displayName = 'P';
