import React, { useState, useMemo } from 'react';
import { WidgetContainer } from '@frontend/app/refresh-components';
import {
  Area,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
} from 'recharts';
import { ToggleGroup, ToggleGroupItem } from '@frontend/shadcn/components/ui/toggle-group';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { cn } from '@/shadcn/lib/utils';

import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from '@/shadcn/components/ui/chart';
import { useGetBudgetDashboardSpendSummaryQuery } from '../hooks';

const chartConfig = {
    spent: {
        label: 'Spent',
        color: '#00A5A6',
    },
    stroke: {
        color: '#b1eaea',
    },
} satisfies ChartConfig;

interface SpendActivityWidgetProps {
  budgetId?: number;
  fiscalYear?: string;
  className?: string;
}

export default function SpendActivityWidget(props: SpendActivityWidgetProps) {
  const { budgetId, fiscalYear, className } = props;
  const isParemetersAbsent = !budgetId || !fiscalYear;
  const [timeline, setTimeline] = useState<string>('Month');
  const { data: budgetSummary, loading } = useGetBudgetDashboardSpendSummaryQuery({
    variables: {
      budgetId,
      fiscalYear,
    },
    skip: isParemetersAbsent,
  });

  const chartData = useMemo(() => {
    if (!loading && budgetSummary?.summary?.spendActivityChartData) {
      return budgetSummary.summary.spendActivityChartData;
    }
    return null;
  }, [loading, budgetSummary]);

  const widgetActions = (
    <div className="flex items-center gap-2">
      <ToggleGroup
        className="border border-gray-300 rounded-lg overflow-hidden inline-flex"
        type="single"
        value={timeline}
        onValueChange={(value) => setTimeline(value || 'Month')}
      >
        <ToggleGroupItem value="Week">W</ToggleGroupItem>
        <ToggleGroupItem value="Month">M</ToggleGroupItem>
        <ToggleGroupItem value="Quarter">Q</ToggleGroupItem>
      </ToggleGroup>
    </div>
    );
  const dataKey = timeline === 'Month' ? 'month' : timeline === 'Week' ? 'week' : 'quarter';

  const missingParametersContent = (
    <div className="w-full h-full flex justify-center items-center">
      <p>No data found</p>
    </div>
  );
  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const mainContent = chartData ? (
    <ChartContainer config={chartConfig} className="h-[420px] w-full">
      <AreaChart data={chartData[dataKey]}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey={dataKey}
          tickLine={false}
          axisLine={false}
          tickMargin={8}
          tickFormatter={(value) => value.slice(0, 3)}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          tickMargin={8}
          tickFormatter={(value) => `$${value / 1000}k`}
        />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent indicator="dot" hideLabel />}
        />
        <Area
          dot={{
                          fill: 'var(--color-spent)',
                      }}
          dataKey="spent"
          type="linear"
          fill="var(--color-stroke)"
          fillOpacity={0.4}
          stroke="var(--color-spent)"
        />
      </AreaChart>
    </ChartContainer>
  ) : null;

  return (
    <WidgetContainer
      widgetTitle="Spend Activity"
      className={cn(className, '')}
      bgColor="bg-white"
      textColor="text-gray-700"
      widgetActions={widgetActions}
    >
      {isParemetersAbsent || !chartData ? missingParametersContent : loading ? loadingContent : mainContent}
    </WidgetContainer>
  );
}
