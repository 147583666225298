import React, { useMemo } from 'react';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from '@frontend/shadcn/components/ui/sheet';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Separator } from '@frontend/shadcn/components/ui/separator';
import { Timeline } from '@frontend/app/refresh-components';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@frontend/shadcn/components/ui/select';
import { Card } from '@frontend/shadcn/components/ui/card';
import { Label } from '@frontend/shadcn/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@frontend/shadcn/components/ui/radio-group';

import { useCommunitiesQuery, useProgramsQuery } from '@frontend/app/hooks';
import { Input } from '@frontend/shadcn/components/ui/input';
import { useGetBudgetAccounts } from '@frontend/app/hooks/budgetAllocation/useGetBudgetAccounts';
import { useGetBudgetPeriodDetails } from '@frontend/app/hooks/budgetAllocation/useGetBudgetPeriodDetails';
import { v4 as uuidv4 } from 'uuid';
import { PaymentStatus } from '@frontend/applications/PaymentsApp/constants';
import { BulkAssignBudgetDataInput } from '@frontend/app/types/globalTypes';
import { useAssignBudgetToBulkPayments } from '@frontend/app/hooks/budgetAllocation/useAssignBudgetToBulkPayments';
import { fetchPaymentById } from '@frontend/applications/PaymentsApp/fetchPaymentById';
import { useAuth } from '@frontend/context/authContext';
import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { updatePaymentData } from '@frontend/applications/PaymentsApp/savePayment';
import { logger } from '@common';
import { MoneyBillsSimpleIcon, PencilIcon } from '@revfluence/fresh-icons/regular/esm';
import { CircleCheckIcon, WalletIcon } from '@revfluence/fresh-icons/solid/esm';
import { Payment } from './PaymentHistoryTab';

const { useState } = React;
interface EditPaymentsDrawerProps {
  payments: Payment[];
  selectedPaymentIds: number[];
  isEditPaymentDrawerOpen: boolean;
  onCloseDrawer: () => void;
}

const EditPaymentsDrawer: React.FC<EditPaymentsDrawerProps> = (props) => {
  const { clientInfo } = useAuth();
  const {
    selectedPaymentIds,
    payments,
    isEditPaymentDrawerOpen,
    onCloseDrawer,
  } = props;
  const isBulkReassign = selectedPaymentIds.length > 1;
  const [editPayments, setEditPayments] = useState({
    isActive: false,
    budgetSource: 'project',
    programId: null,
    groupId: null,
    budgetId: null,
    periodKey: null,
  });
  const [bulkReassignBudget] = useAssignBudgetToBulkPayments();
  const { data: clientPrograms, loading: isProgramsLoading } = useProgramsQuery();
  const { loading: isCommunityLoading, data: communityData } = useCommunitiesQuery();
  const { budgetAccounts, loading: isBudgetsLoading } = useGetBudgetAccounts({
    variables: {},
  });
  const { loading: isLoadingBudgetPeriod, periodDetails } = useGetBudgetPeriodDetails({
    variables: {
      budgetAccountId: editPayments.budgetSource === 'others' ? editPayments?.budgetId : null,
      programId: editPayments.budgetSource === 'project' ? editPayments?.programId : null,
    },
  });
  const programs = useMemo(() => {
    if (!isProgramsLoading && clientPrograms?.programs?.length) {
      return clientPrograms.programs.map((program) => ({
        id: program.id,
        programName: program.title,
      }));
    } else {
      return [];
    }
  }, [clientPrograms, isProgramsLoading]);

  const groups = useMemo(() => {
    if (!isCommunityLoading && communityData?.communities?.length) {
      return communityData.communities.map((community) => ({
        id: community.id,
        groupName: community.title,
      }));
    } else {
      return [];
    }
  }, [isCommunityLoading, communityData]);

  const budgets = useMemo(() => {
    if (!isBudgetsLoading && budgetAccounts?.length) {
      return budgetAccounts.map((budget) => ({
        id: budget.id,
        budgetName: budget.name,
      }));
    } else {
      return [];
    }
  }, [isBudgetsLoading, budgetAccounts]);

  const financialPeriods = useMemo(() => {
    if (!isLoadingBudgetPeriod && periodDetails?.length) {
      const fiscalPeriod = [];
      periodDetails.forEach((period) => {
        fiscalPeriod.push({
          key: uuidv4(),
          id: period.fiscalYearPeriodDefinitionId,
          label: period.fiscalYearLabel,
          granularityLabelForPayload: 'FY',
        });
        if (period.quarterDistributions?.length) {
          period.quarterDistributions.forEach((quarter) => {
            fiscalPeriod.push({
              key: uuidv4(),
              id: quarter.quarterPeriodDefinitionId,
              label: quarter.quarterLabel,
              granularityLabelForPayload: quarter.quarterKey,
            });
          });
        }
      });

      return fiscalPeriod;
    } else {
      return [];
    }
  }, [isLoadingBudgetPeriod, periodDetails]);

  const onClickEditToggle = () => {
    setEditPayments((prevState) => ({
      ...prevState,
      isActive: !prevState.isActive,
    }));
  };

  const onChangeEditFields = (fieldName: string, value: string | number) => {
    setEditPayments((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const formatDate = (date) => date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  const onSubmit = async () => {
    try {
      for (const paymentId of selectedPaymentIds) {
        try {
          const payment = await fetchPaymentById(paymentId, clientInfo.id);
          const selectedProgram = programs.find((program) => program.id === editPayments?.programId);
          const selectedGroup = groups.find((group) => group.id === editPayments?.groupId);

          const url = `${backendServerApiEndpoint()}/payment`;
          const updatePayload = {
            program_ids: selectedProgram ? [selectedProgram.id] : payment.program_ids,
            program_names: selectedProgram ? [selectedProgram.programName] : payment.program_names,
            community_ids: selectedGroup ? [selectedGroup.id] : payment.community_ids,
            activation_ids: payment.activation_ids,
            activation_names: payment.activation_names,
            assigned: payment.assigned,
            client_id: payment.client_id,
          };
          await updatePaymentData(`${url}/${payment.id}`, updatePayload);
        } catch (err) {
          logger.error({ message: err });
        }
      }

      const selectedPeriod = financialPeriods.find((period) => period.key === editPayments.periodKey);
      const splitPayment = [
        {
          budgetAccountId: editPayments.budgetId,
          fiscalGranularityLabel: selectedPeriod.granularityLabelForPayload,
          budgetPeriodDefinitionId: selectedPeriod.id,
        },
      ];
      const bulkAssignBudgetData: BulkAssignBudgetDataInput = {
        paymentIds: selectedPaymentIds,
        ...(editPayments.budgetSource === 'others'
          ? {
              accountsPaymentBudgetInput: {
                splitInfo: splitPayment,
              },
            }
          : {
              projectPaymentBudgetInput: {
                programSplitFiscalInfo: {
                  budgetPeriodDefinitionId: selectedPeriod.id,
                  fiscalGranularityLabel: selectedPeriod.granularityLabelForPayload.split(' ')[0],
                },
                overflow: [],
              },
            }),
      };

      await bulkReassignBudget({
        variables: { bulkAssignBudgetData },
        onError(error) {
          logger.error({ message: error });
        },
      });
    } catch (err) {
      logger.error({ message: err });
    } finally {
      onCloseDrawer();
    }
  };
  const statusMapping = {
    [PaymentStatus.PENDING]: {
      label: 'Pending Info',
      textColor: '#D48806',
      bgColor: '#FFFBE6',
    },
    [PaymentStatus.PAID]: {
      label: 'Paid',
      textColor: '#389E0D',
      bgColor: '#F6FFED',
    },
    [PaymentStatus.CANCELED]: {
      label: 'Canceled',
      textColor: '#CF1322',
      bgColor: '#FFF1F0',
    },
    [PaymentStatus.PROCESSING]: {
      label: 'Processing',
      textColor: '#096DD9',
      bgColor: '#E6F7FF',
    },
  };

  const totalPaymentSum = useMemo(
    () =>
      selectedPaymentIds.reduce((sum, paymentId) => {
        const payment = payments.find((p) => p.id === paymentId);
        return payment ? sum + payment.amountPaid : sum;
      }, 0),
    [selectedPaymentIds, payments],
  );

  if (!selectedPaymentIds.length) {
    return <div>Loading...</div>;
  }
  const paymentToUpdate = payments.find((payment) => payment.id === selectedPaymentIds[0]);
  const status = statusMapping[paymentToUpdate.paymentStatus];
  const topMainContent = isBulkReassign ? (
    <section className="flex justify-between mt-2">
      <div className="flex flex-col flex-1">
        <div className="flex gap-2 text-gray-400 items-center font-semibold">
          <MoneyBillsSimpleIcon className="text-gray-400" />
          Payment
        </div>
        <div className="flex gap-1 font-bold items-end">
          <span className="text-2xl">$</span>
          <span className="text-3xl">{totalPaymentSum.toFixed(2)}</span>
          <span className="text-2xl">USD</span>
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex gap-2 text-gray-400 items-center font-semibold"># Number of Payments</div>
        <div className="flex gap-1 font-bold items-end">
          <span className="text-3xl">{selectedPaymentIds.length}</span>
        </div>
      </div>
    </section>
  ) : (
    <section className="flex flex-col gap-1 mt-2">
      <div className="flex justify-between items-center">
        <div className="flex gap-2 text-gray-400 items-center font-semibold">
          <MoneyBillsSimpleIcon className="text-gray-400" />
          Payment
        </div>
        <Badge
          className="rounded-md font-normal"
          style={{ color: status.textColor, backgroundColor: status.bgColor }}
          variant="secondary"
        >
          {status.label}
        </Badge>
      </div>
      <div className="flex gap-1 font-bold items-end">
        <span className="text-2xl">$</span>
        <span className="text-3xl">{totalPaymentSum.toFixed(2)}</span>
        <span className="text-2xl">USD</span>
      </div>
    </section>
  );

  return (
    <Sheet open={isEditPaymentDrawerOpen} onOpenChange={onCloseDrawer}>
      <SheetContent side="right" className="min-w-[572px] flex flex-col h-full  ">
        <SheetHeader>
          <SheetTitle className="text-center text-xl font-semibold m-0">
            {isBulkReassign ? 'Bulk Assign Payments' : 'Payment Overview'}
          </SheetTitle>
        </SheetHeader>
        {/* Main Content */}
        <div className="flex flex-col gap-4 flex-grow overflow-y-auto">
          <Separator />
          {topMainContent}
          <Separator />
          {!isBulkReassign && (
            <section>
              <div className="flex flex-row justify-between gap-2">
                <div className="flex flex-col justify-start w-1/3">
                  <span className="text-gray-400">Member</span>
                  <span className="truncate overflow-hidden whitespace-nowrap">{paymentToUpdate.member}</span>
                </div>
                <div className="flex flex-col justify-start w-1/3">
                  <span className="text-gray-400">Email</span>
                  <span className="truncate overflow-hidden whitespace-nowrap">{paymentToUpdate.email}</span>
                </div>
                <div className="flex flex-col justify-start w-1/3">
                  <span className="text-gray-400">PayPal Address</span>
                  <span className="truncate overflow-hidden whitespace-nowrap">{paymentToUpdate.email}</span>
                </div>
              </div>
            </section>
          )}
          {!isBulkReassign && (
            <section>
              <p className="m-0 text-sm font-semibold">Payment Timeline</p>
              <Separator className="my-2" />
              <Timeline
                items={[
                  {
                    id: 1,
                    title: 'Payment Succeeded',
                    description: paymentToUpdate?.paymentTransferDate
                      ? `${formatDate(paymentToUpdate.paymentTransferDate)}`
                      : 'Processing',
                    icon: (
                      <CircleCheckIcon
                        className="w-5 h-5 bg-white"
                        color={paymentToUpdate?.paymentTransferDate ? '#389E0D' : '#BFBFBF'}
                      />
                    ),
                  },
                  {
                    id: 2,
                    title: 'Payment Initiated',
                    description: `${formatDate(paymentToUpdate.paymentDate)}`,
                    icon: <WalletIcon className="w-5 h-5 bg-white" color="#389E0D" />,
                  },
                ]}
              />
            </section>
          )}
          <section>
            <div className="flex justify-between">
              <p className="m-0 text-sm font-semibold">Connections</p>
              <div
                className="flex items-center gap-1 hover:cursor-pointer text-blue-600"
                onClick={() => onClickEditToggle()}
              >
                <PencilIcon className="h-3 w-3" />
                Edit
              </div>
            </div>
            <Separator className="my-2" />
            <div className="flex flex-col gap-2">
              <div className="flex justify-between">
                <span className="text-gray-400">Project</span>
                {editPayments.isActive ? (
                  <div className="bg-white rounded-lg overflow-hidden">
                    <Select
                      value={editPayments?.programId?.toString()}
                      onValueChange={(value) => onChangeEditFields('programId', parseInt(value, 10))}
                    >
                      <SelectTrigger className="w-[150px] h-8 text-sm">
                        <SelectValue placeholder="Select Project" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {programs.map((program) => (
                            <SelectItem key={program.id} value={program.id.toString()}>
                              {program.programName}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                ) : (
                  <span>{paymentToUpdate.project}</span>
                )}
              </div>
              <div className="flex justify-between">
                <span className="text-gray-400">Group</span>
                {editPayments.isActive ? (
                  <div className="bg-white rounded-lg overflow-hidden">
                    <Select
                      value={editPayments?.groupId?.toString()}
                      onValueChange={(value) => onChangeEditFields('groupId', parseInt(value, 10))}
                    >
                      <SelectTrigger className="w-[150px] h-8 text-sm">
                        <SelectValue placeholder="Select Group" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {groups.map((group) => (
                            <SelectItem key={group.id} value={group.id.toString()}>
                              {group.groupName}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                ) : (
                  <span>-</span>
                )}
              </div>
              <div className="flex justify-between">
                <span className="text-gray-400">Assigned to Budget</span>
                {editPayments.isActive ? (
                  <RadioGroup
                    value={editPayments?.budgetSource}
                    onValueChange={(value) => onChangeEditFields('budgetSource', value)}
                    className="flex flex-row space-x-4"
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="project" id="project" />
                      <Label htmlFor="project">Project’s Budget</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="others" id="others" />
                      <Label htmlFor="others">Others</Label>
                    </div>
                  </RadioGroup>
                ) : (
                  <div className="max-w-[40%]">
                    <div className="flex items-center gap-1">
                      {paymentToUpdate.budgetAccounts.map((budget, index) => (
                        <Badge
                          key={index}
                          className="rounded-md font-normal"
                          style={{ backgroundColor: '#F5F5F5' }}
                          variant="secondary"
                        >
                          {budget}
                        </Badge>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              {editPayments.isActive && (
                <div className="flex justify-between">
                  <Card className="rounded-md w-full p-3 shadow-none flex flex-col gap-2">
                    <div className="flex justify-between">
                      <span className="text-gray-400">Budget Account</span>
                      {editPayments?.budgetSource === 'project' ? (
                        <div className="bg-white rounded-lg overflow-hidden">
                          <Select disabled>
                            <SelectTrigger className="w-[150px] h-8 text-sm">
                              <SelectValue placeholder="Project’s Total Budget" />
                            </SelectTrigger>
                          </Select>
                        </div>
                      ) : (
                        <div className="bg-white rounded-lg overflow-hidden">
                          <Select
                            value={editPayments?.budgetId?.toString()}
                            onValueChange={(value) => onChangeEditFields('budgetId', parseInt(value, 10))}
                          >
                            <SelectTrigger className="w-[150px] h-8 text-sm">
                              <SelectValue placeholder="Select Budget" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                {budgets.map((budget) => (
                                  <SelectItem key={budget.id} value={budget.id.toString()}>
                                    {budget.budgetName}
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>
                      )}
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-400">Fiscal Year Period</span>
                      <div className="bg-white rounded-lg overflow-hidden">
                        <Select
                          value={editPayments?.periodKey}
                          onValueChange={(value) => onChangeEditFields('periodKey', value)}
                        >
                          <SelectTrigger className="w-[150px] h-8 text-sm">
                            <SelectValue placeholder="Select Fiscal Period" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {financialPeriods.map((period) => (
                                <SelectItem key={period.key} value={period.key}>
                                  {period.label}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-400">Total Amount</span>
                      <div className="bg-white rounded-lg overflow-hidden">
                        <Input type="number" className="w-[150px]" disabled value={paymentToUpdate.amountPaid} />
                      </div>
                    </div>
                  </Card>
                </div>
              )}
            </div>
          </section>
          {!isBulkReassign && (
            <section>
              <p className="m-0 text-sm font-semibold">Payment Details</p>
              <Separator className="my-2" />
              <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                  <span className="text-gray-400">Amount</span>
                  <span>{`$${paymentToUpdate.amountPaid} USD`}</span>
                </div>
                {/* Will enable when data is ready from api */}
                {/* <div className="flex justify-between">
                    <span className="text-gray-400">Processed By</span>
                    <span>Albert Jones</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-400">Payment Method</span>
                    <span>VISA (2342)</span>
                  </div> */}
                <div className="flex justify-between">
                  <span className="text-gray-400">Payment ID</span>
                  <span>{paymentToUpdate.id}</span>
                </div>
              </div>
            </section>
          )}
        </div>

        {editPayments.isActive && (
          <SheetFooter>
            <div className="w-full flex gap-8 justify-center">
              <Button variant="outline" onClick={() => onCloseDrawer()} className="rounded-lg">
                Cancel
              </Button>
              <Button variant="outline" onClick={onSubmit} className="bg-blue-500 hover:bg-blue-600 text-white rounded-lg">
                Save
              </Button>
            </div>

          </SheetFooter>
        )}
      </SheetContent>
    </Sheet>
  );
};

export default EditPaymentsDrawer;
